// a library to wrap and simplify api calls
import apisauce from "apisauce";

// our "constructor"
export const create = (baseURL = process.env.REACT_APP_OPENSEA_API_URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY,
        },
        // 10 second timeout...
        timeout: 10000,
    });



    const getAsset = (data) => {
        let includeOrders=true;
        return api.get(
            `${process.env.REACT_APP_OPENSEA_API_URL}/asset/${data.contractAddress}/${data.tokenId}/?include_orders=${includeOrders}`
        );
    };

    const getProjectAssets = (data) => {
        let includeOrders=true;
        return api.get(
            `${process.env.REACT_APP_OPENSEA_API_URL}/assets`,
            data
        );
    };

    const getProjectStats = (data) => {
        return api.get(
            `${process.env.REACT_APP_OPENSEA_API_URL}/collection/${data.slug}/stats`,
            data
        );
    }


    //
    //
    // fetch('https://api.opensea.io/api/v1/assets?order_direction=desc&limit=20&include_orders=false', options)
    //   .then(response => response.json())
    //   .then(response => console.log(response))
    //   .catch(err => console.error(err));



    // return functions for interface
    return {
        getAsset,
        getProjectAssets,
        getProjectStats,
    };
};

// Creates a singleton
export const client_opensea = create();
