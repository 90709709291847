
//
// (              )              (
// )\ )        ( /(   *   )      )\ )
// (()/(   (    )\())` )  /( (   (()/(
// /(_))  )\  ((_)\  ( )(_)))\   /(_))
// (_))__ ((___ __((_)(_(_())((_) (_))
// | \ __ \/ |  __ |_)
// |_/ __ /\ |  __ | \


import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ProvideWallet } from "./hooks/useWallet";
import { ProvideAuth } from "./hooks/useAuth";
import { ProvideCore } from "./hooks/useCore";
import { ProvideSocial } from "./hooks/useSocial";
import { ProvideMarket } from "./hooks/useMarket";
import { ProvideDex } from "./hooks/useDex";
// import { ProvideExchange } from "./hooks/useExchange";
import { ProvideCollection } from "./hooks/useCollection";
import { CookiesProvider } from 'react-cookie';

import io from 'socket.io-client';
import SocketProvider from './providers/SocketProvider';
import ReactTooltip from 'react-tooltip';

import './index.css';
import { Website } from './pages/Website';
import { ModalCore } from "./components/Modal/ModalCore";

import Div100vh from 'react-div-100vh';


export default function WebsiteBase() {

    return (
        <SocketProvider client={io} uri={process.env.REACT_APP_URL_SOCKET}>
            <ProvideWallet>
                <ProvideDex>
                    <ProvideSocial>

                			<HelmetProvider>
                				<Div100vh>
                                    <ReactTooltip
                                    effect="solid"
                                    className="tooltip"
                                    delayShow={300}
                                    delayUpdate={0}
                                    />
                                    <ModalCore/>
                					<Website />
                				</Div100vh>
                			</HelmetProvider>

                    </ProvideSocial>
                </ProvideDex>
            </ProvideWallet>
        </SocketProvider>
    );
}
