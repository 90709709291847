import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { SideBarLeft } from "../SideBarLeft";
import { SideBarRight } from "../SideBarRight";
import { SideBarContent } from "../SideBarContent";

// import { PageHeader } from "./PageHeader";

import { LoaderSpinner } from "./LoaderSpinner";

import { ChainSelector } from "../ChainSelector";
import { ChainPanel } from "../ChainPanel";
import { WalletButton } from "../WalletButton";
import { DexHeader } from "../Dex/DexHeader";


import { InputTextField } from "../Input/InputTextField";

import { IconActionButton } from "../../components/IconActionButton";
import { Footer } from "../../components/Footer";
import logo from "../../icons/artemis_logo.png";
import logo0x from "../../icons/logo_0xdex_faded.png";
import defaultAvatar from "../../images/avatar.svg";
import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useMarket } from "../../hooks/useMarket";
import { useWallet } from "../../hooks/useWallet";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";


import classNames from "classnames";

import {FpsView} from "react-fps";

import { formatCurrency, condenseZeros, formatPercent } from "../../utils/currency";
import { openPage, replaceSubDigits, getCautionColor, addCommas } from "../../utils/tools";
import { bgGradients, vibrantColors } from "../../utils/data";
import {ReactComponent as GasPumpIcon} from '../../icons/svgs/gaspump.svg';
import {ReactComponent as BridgeIcon} from "../../icons/svgs/bridge.svg";
import {ReactComponent as QRCodeIcon} from "../../icons/svgs/qrcode.svg";

var parse = require('html-react-parser');



export function Layout({ children, addClassFull, addClassPage, stylePass, stylePassPage }) {

    const { screens } = useNavigator();
    const navigator = useNavigator();


    const { 
        version,
        chainPanel,
        chain,
        handleChainPanel,
        sidebarLeft,
        handleSidebarLeft,
        sidebarRight,
        handleSidebarRight,
        fullscreen,
        handleDexSearchModal,
        handleModalVisible,
        websiteLoaded,
        handleAccessAllowed,
        accessAllowed,
        handleQrurl,
        handleModalTitle,
        handleQrModal,
        settingsPanel,
        handleSettingsPanel,
        heroImage,
        handlePageSize,
        pageSize,
        windowDimensions,
        lobbyPanel,
        handleLobbyPanel,
        listPage
    } = useCore();

    let { token, handleTokenPair, loading, listView, tokenList } = useDex();
    let { twitterSearchedUser, userPage } = useSocial();

    const [ testing, setTesting ] = useState(false);

    const [ accessCode, setAccessCode ] = useState('');

    const [ randomBg, setRandomBg ] = useState(0);

    const {
        block,
        balanceEth,
        balanceEthBase,
        balancesNative,
        walletAddress,
        chainId,
        chainName,
        inApp,
        ethGasPrice,
        ethPrice,
        ethData,
        btcData,
        btcPrice,
        solData,
        solPrice,
        user,
        profile,
        handleProfile
    } = useWallet();

    const imageRef = useRef();

    const pageWrapper = useRef();

   

    useEffect(() => {

        if(user && !profile){
            handleProfile({
                ...user,

            })
        }

    }, [ user ]);

    useEffect(() => {

        if( token && token.pair?.data?.priceUsd !== undefined && !document.hasFocus()){
           document.title = `${token.pair.data.baseToken.symbol} $${parse(replaceSubDigits(token.pair.data.priceUsd))}`;
        }

    }, [ token, token?.pair?.data?.priceUsd ]);


    


    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            const { width, height } = entry.contentRect;
            handlePageSize({ width, height });
          }
        });
    
        resizeObserver.observe(pageWrapper.current);
    
        return () => {
            if(pageWrapper.current){
                resizeObserver.unobserve(pageWrapper.current);
            }
          
        };

        

    }, []);


    useEffect(() => {
        const handleKeyPress = (event) => {
          if (event.keyCode === 27) {

              handleChainPanel(false);


          }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
          window.removeEventListener('keydown', handleKeyPress);
        };


        


    }, [  ]);

    useEffect(() => {
        let rn = Math.floor(Math.random()*bgGradients.length);
        // console.log(rn)
        setRandomBg(rn);

    }, [  ]);

   


    const toggleSidebarLeft = () => {
        handleSidebarLeft(!sidebarLeft);
    }

    const toggleSidebarRight = () => {
        handleSidebarRight(!sidebarRight);
    }

    const toggleDexSearch = () => {
        handleDexSearchModal(true);
        handleModalVisible(true);
    }

    const onAccessCodeChange = (v) => {
        setAccessCode(v);
        if(v == "0xgotime"){
            handleAccessAllowed(true);
        }
    }

    const launchApp = () => {
        window.open("http://app.localhost:3737", "_self");
    }


    const openChart = (v) => {
        window.open(v, "_blank");
    }

    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if(accessCode=="0xgotime"){
                handleAccessAllowed(true)
            }else{
                setAccessCode('');
            }
        }
    };

    const showQRModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // handleModalTitle(nft.name + ' - #' + nft.token_id);
        // let qu = `${process.env.REACT_APP_ARTEMIS_URL_IP}/assets/${ca}/${nft.token_id}`;
        // console.log(qu);
        // handleQrurl(qu);

        let mt = "0xDEX_website";
        console.log(mt);
        handleModalTitle(mt);
        handleQrurl(`${window.location.href}`);
        handleModalVisible(true);
        handleQrModal(true);

    }


    const openBridgePage = () => {

        let purl = `https://app.rhino.fi/bridge`;
        purl = `https://www.orbiter.finance`
        window.open(
            purl,
            "_blank"
            // 'toolbar=0,status=0,width=600,height=700,left=0,top=100'
        );
    }

    const onLogoClick = () => {
        if(!walletAddress){
            handleLobbyPanel(!lobbyPanel);
        }
    }



    return (
        <div style={ stylePass ? stylePass : {} } className={classNames('full-wrapper', inApp && walletAddress && "access-allowed",addClassFull && addClassFull, !inApp && screens.length < 3 && screens[1] !== undefined && !screens[1].length && 'home', inApp && !walletAddress && 'lobby', user && user.walletBlur && 'walletBlur', user && user.balanceBlur && 'balanceBlur', pageSize.width < 1024 && pageSize.width > 768 ? "medium" : pageSize.width >= 1024 ? "" : "small")}>

            <Helmet>

                {/* {token && token.pair.data !== undefined && (
                    <title>{ token ? `${token.pair?.data?.baseToken.symbol} :: $${token.pair?.data?.priceUsd}` : "0xDex :: Dashboard"}</title>
                )} */}

                { profile || user ? (
                    <style>
                        {`.token-balance-dex, .portfolio-header{
                            background: ${profile.colorbgportfolio || user.colorbgportfolio} !important;
                        }\n
                        body{
                            background-image: linear-gradient(315deg, ${profile.colorbg1} 0%, ${profile.colorbg2} 99%) !important;
                            background-image: ${ listView == "user" && screens[1] !== "settings" && userPage && userPage.colorgradient !== undefined ? userPage.colorgradient : listView=="list" && tokenList && tokenList.owner?.colorgradient !== undefined ? tokenList.owner.colorgradient : profile.colorgradient} !important;
                        }
                        `}
                    </style>
                ):(
                    
                    <style>
                        {`
                        body{
                            background-image: ${ listView == "user" && screens[1] !== "settings" && userPage && userPage.colorgradient !== undefined ? userPage.colorgradient : listView=="list" && tokenList && tokenList.owner?.colorgradient !== undefined ? tokenList.owner.colorgradient : !walletAddress && inApp ? bgGradients[randomBg] : ''} !important;
                          
                        }
                        `}
                    </style>
                )}




                { token && token.cg ? (
                    <link rel="icon" type="image/jpeg" href={token.cg.image.thumb} />
                ) : token && token.iconImage ? (
                    <link rel="icon" type="image/jpeg" href={`${process.env.REACT_APP_MEDIA_URL}${token.iconImage}`} />
                ) : twitterSearchedUser && screens[1] == "social" ? (
                    <link rel="icon" type="image/jpeg" href={twitterSearchedUser.profile_image_url} />
                ) : userPage && screens[1] == userPage.username && userPage.avatarFile ? (
                    <link rel="icon" type="image/jpeg" href={`${process.env.REACT_APP_MEDIA_URL}${userPage.avatarFile}`} />
                ) : (
                    <link rel="icon" type="image/jpeg" href="/logo192.png" />
                ) }


                {token && token.pair && token.pair.data && token.pair.data !== undefined ? (
                    <title>{token.pair.data.baseToken.symbol} ${parse(replaceSubDigits(token.pair.data.priceUsd))}</title>
                ) : listView && listView.length && screens[1] !== undefined && screens[1].length ? (
                    <>
                        {listView == "user" && userPage ? (
                            <title>{userPage.name && userPage.name.length > 2 ? userPage.name + " (" : ""}@{userPage.username}{userPage.name && userPage.name.length > 2 ? ")" : ""} - 0xDΞX</title>
                            
                        ):(
                            <title>0xDΞX : {listView}</title>
                        )}
                    </>
                    
                ):(
                    <title>Welcome to 0xdex</title>
                )}

            </Helmet>
               

            { heroImage && (!walletAddress || (profile && profile.photobg)) ? (
                <div style={{
                    position:'absolute',
                    backgroundSize:'cover',
                    backgroundPosition: 'center',
                    filter: profile && profile.photobrightness !== undefined ? `brightness(${profile.photobrightness/100})` : 'brightness(.33)',
                    opacity: profile && profile.photoalpha !== undefined ? profile.photoalpha/100 : .73,
                    width:'100%',
                    height:'100%',
                    backgroundImage:`url(${require(`../../media/photos/${heroImage}`)})`
                }} className="hero-image" ref={imageRef}/>
            ):null}

            {/*<FpsView left={20} top={'80%'} />*/}
            
            { !accessAllowed && !walletAddress && inApp ? (
               
                <div className="load-page">
                    
                    <div style={{maxWidth:144}}>
                        <img style={{marginTop:0,maxWidth:'100%'}} src={logo0x} alt="0xdex" />
                    </div>

                    {/*<LoaderSpinner bgsize={77} loadersize={55}/>*/}
                    <InputTextField
                        title={'Access code'}
                        onChange={onAccessCodeChange}
                        // placeholder='Access Code'
                        value={accessCode}
                        onKeyPress={_handleKeyDown}
                        autoComplete="off"
                        

                        // focus={true}
                    />

                    <p style={{marginTop:22}}>App is currently in maintenance mode. 🛠️</p>
                    <p style={{margin:0}}><span style={{marginRight:4}} className="small mute">version</span><span style={{fontWeight:'600'}}>{version}</span></p>

                </div>
            
            ):null}


            {/*{ inApp && accessAllowed && (*/}
                <div id="mainheader" className="main-header transition-long">

                    <div className="flex-row align center">

                        <NavLink
                            style={{textDecoration:'none',display:'flex',flexDirection:'row',justifyContent:'center',height:22}}
                            to="/"
                            exact="true"
                            className="art-logo"
                            onClick={() => { if(inApp){
                                onLogoClick();
                                // handleTokenPair(null);
                            }}}>
                            <img style={{marginTop:0}} src={logo0x} alt="0xdex logo" />
                            {/*<p style={{marginLeft:4}} className="currency dexter small">DΞXTΞR</p>*/}
                        </NavLink>
                        <div data-tip={`v${version}`} style={{margin:4,fontSize:9,padding:'2px 4px',borderRadius:0,cursor:'pointer'}} className="data-panel micro outline transparentbg">
                            BETA
                        </div>

                        { inApp ? (
                            <div onClick={toggleSidebarLeft} className="menu-hamburger">
                                <div className="icon-hamburger"></div>
                            </div>
                        ):null}

                        { inApp ? (
                            <div className="coin-links dex-search-link">

                                <div style={{marginLeft:11}} onClick={toggleDexSearch} data-tip="search dex" className="coin-link">
                                    {/*<div className="svg-icon">
                                        <svg viewBox="0 0 1024 1024" style={{maxHeight:25}} version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M252 462h524c19.9 0 36-16.1 36-36s-16.1-36-36-36H339.4l59-59c14.1-14.1 14.1-36.9 0-50.9-14.1-14.1-36.9-14.1-50.9 0L230.3 397.2c-8.7 6.6-14.3 17-14.3 28.8 0 19.9 16.1 36 36 36zM802 564c-8.5-8.5-20.3-11.9-31.4-10H252c-19.9 0-36 16.1-36 36s16.1 36 36 36h437.1l-58.5 58.5c-14.1 14.1-14.1 36.9 0 50.9 7 7 16.2 10.5 25.5 10.5s18.4-3.5 25.5-10.5L802 615c14-14.1 14-36.9 0-51z"  />
                                        </svg>
                                    </div>*/}
                                    <div style={{width:14,height:14}} className="svg-icon">
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4" >
                                            <path d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
                                                s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
                                                M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"/>
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        ):null}
                        
                        { btcPrice && ethPrice && solPrice && windowDimensions.width > 1000 ? (
                             <div style={{transform:'scale(.69)'}} className="data-row currency no-blur mobile-off">
                                {/* {parse(formatPercent(String(btcPercent)))} */}
                                <div style={{cursor:"pointer"}} onClick={() => openChart("https://www.tradingview.com/chart/?symbol=BITSTAMP%3ABTCUSD")}>
                                    <span style={{marginRight:5}} className="mute">BTC</span>
                                    <span style={{marginRight:2}} className="small mute">$</span>{addCommas(Math.round(btcPrice))}
                                    <span style={{marginLeft:5}}></span>{parse(formatPercent(btcData.h24))}
                                </div>

                                <div style={{cursor:"pointer"}} onClick={() => openChart("https://www.tradingview.com/chart/?symbol=BITSTAMP%3AETHUSD")}>
                                    <span style={{marginLeft:14,marginRight:5}} className="mute">ETH</span>
                                    <span style={{marginRight:2}} className="small mute">$</span>{addCommas(Math.round(ethPrice))}
                                    <span style={{marginLeft:5}}></span>{parse(formatPercent(ethData.h24))}
                                </div>

                                <div style={{cursor:"pointer"}} onClick={() => openChart("https://www.tradingview.com/chart/?symbol=BITSTAMP%3ASOLUSD")}>
                                    <span style={{marginLeft:14,marginRight:5}} className="mute">SOL</span>
                                    <span style={{marginRight:2}} className="small mute">$</span>{addCommas(Math.round(solPrice))}
                                    <span style={{marginLeft:5}}></span>{parse(formatPercent(solData.h24))}
                                </div>
                                
                                
                            </div>
                        ):null}
                       

                    </div>

                   

                    { inApp && walletAddress ? (

                        <div className="header-right-container">
                            {/*{ chainId !== undefined && (*/}
                            
                            { windowDimensions.width > 500 ? (
                                <div style={{marginRight:11}} className="coin-links">
                                    <div data-tip={`bridge`} onClick={() => openBridgePage()} className="coin-link">
                                        <div className="svg-icon large">
                                            <BridgeIcon/>
                                        </div>
                                    </div>
                                </div>
                            ):null}

                            

                            { ethGasPrice > 0 && chainId=="0x1" && windowDimensions.width > 600 && (
                                <div onClick={() => openPage(null,"gastracker")} style={{marginRight:22,padding:'0 10px 0 17px',position:'relative',justifyContent:'space-between', minWidth:108}} className="data-panel flex-row align center interactive">
                                    <div className="active-green-circle relative" style={ethGasPrice > 5 ? {position:'relative',top:3,marginRight:2,backgroundColor: getCautionColor(ethGasPrice)} : {top:3,marginRight:2,position:'relative'}}/>

                                    <div style={{width:11,opacity:.64,marginTop:1,marginRight:7}} className="svg-icon">
                                        <GasPumpIcon/>
                                    </div>

                                    <p style={{margin:0}}><span className="data-value">{Math.round(ethGasPrice)}</span><span style={{fontSize:11}} className="small"> gwei</span></p>
                                </div>
                            )}


                            { walletAddress && (

                                <div data-tip={ user && !user.balanceBlur ? `$ ${(balanceEth*ethPrice).toFixed(2)}` : "$ $ $"} className="data-value currency">

                                    <div style={{marginRight:17,alignItems:"center"}} className="flex-row justify space-between">

                                        <p className="eth-balance"><span style={{}} className="small">Ξ</span> {balancesNative[chainId] !== undefined ? parse((balancesNative[chainId]).toFixed(4)) : "0"}</p>

                                    </div>


                                </div>

                            )}



                            { walletAddress && chainId && (

                                <ChainSelector/>

                            )}


                            <WalletButton/>
                        </div>
                    ):(

                        <div className="flex-row align center">
                            {!inApp || (inApp && !walletAddress) ? (
                                <div style={{marginRight:7}} className="coin-links">
                                    <div data-tip="QR code" onClick={showQRModal} className="coin-link">
                                        <div className="svg-icon">
                                            <QRCodeIcon/>

                                        </div>
                                    </div>

                                </div>
                            ):null}

                            {inApp ? (
                                <WalletButton/>
                            ):null}
                            
                        </div>



                    )}


                    {/*<button
                        disabled={true}
                        onClick={launchApp}
                        className="wallet-button slim launch-app">Launch app</button>*/}

    				{/*
                    <div className="account-btn-wrapper">
                        <NavLink to="/account" className="account-btn">
                            <img className="avatar" src={currentUser?.avatarUrl ? currentUser.avatarUrl : defaultAvatar} />
                            {currentUser.username}
                        </NavLink>
                    </div>
    				*/}
                </div>



            {/*)}*/}


            { accessAllowed && inApp ? (
                <ChainPanel/>
            ):null}




           

                <div className={classNames('main-wrapper', !sidebarLeft && 'no-sidebar')}>
                    {/*{ !sidebarLeft && (
                        <IconActionButton type="arrow-right" position="top-left" passStyle={{marginTop:'44px'}} passClick={toggleSidebarLeft}/>
                    )}*/}

                    {/*{ inApp && !sidebarRight && walletAddress && (
                        <IconActionButton addClass="sidebar-right" type="arrow-left" position="center-right" passClick={toggleSidebarRight}/>
                    )}*/}

                
                    

                    {inApp && (
                        <SideBarLeft/>
                    )}


                    { screens[1] !== "settings" && screens[1] !== "admin" && screens[1] !== undefined && screens[1].length > 0 && (
                        <>
                            {/*<SideBarContent />*/}
                            <SideBarRight />
                        </>
                    )}



                    <div ref={pageWrapper} style={ stylePassPage ? stylePassPage : {} } className={`page-wrapper${addClassPage ? " " + addClassPage : ""}`}>
                        <>


                            {children}
                        </>

                    </div>

                </div>

           

{/*
            <Footer/>*/}
        </div>
    );
}
