import React, { useEffect, useState } from "react";
// import { useNavigate, NavLink } from "react-router-dom";


import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";



export function DexChart() {

    const { tokenPair } = useSocial();


    useEffect(() => {

    }, [  ]);


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          // handleSubmit();
        }
    }


    const authHandler = (err, data) => {
    console.log(err, data);
    };

    return (
        <Layout>
            <Helmet><title>Dex Chart : Artemis</title>
            </Helmet>


            <div className="dex-chart">
                {tokenPair && (
                    <iframe src={`https://dexscreener.com/ethereum/${tokenPair.pairAddress}?embed=1&theme=dark&info=0`}/>
                )}

            </div>

        </Layout>
    );
}
