import React from "react";

import Blockies from 'react-blockies';

import classNames from "classnames";

// import { useExchange } from "../../hooks/useExchange";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";

var parse = require('html-react-parser');

export function CurrencyItem({ token, passClick }) {

    // let { tokenInput, tokenOutput } = useExchange();

    // const disabled = token.name !== tokenInput.name && token.name !== tokenOutput.name ? false : true;
    const disabled = false;

    const handleClick = () => {
        passClick(token);
    }

    return (

        <div onClick={handleClick} className={classNames('coin-item', disabled && 'disabled')}>
            <div className="flex-row">
                { token.logoURI !== undefined && token.logoURI.length>4 ? (
                    <img className="coin-icon" src={token.logoURI}/>
                ):(
                    <Blockies
                        seed={token.address}
                        size={7}
                        scale={5}
                        // color="#dfe"
                        // bgColor="#ffe"
                        // spotColor="#abc"
                        className="identicon"
                    />
                )}

                <div className="coin-meta">
                    <p className="coin-name">{token.name}</p>
                    <p className="coin-symbol">{token.symbol}</p>
                </div>

            </div>
            <>
                { Number(token.balance) > 0 && (
                    <p className="token-balance small">{parse(condenseZeros(formatCurrency((token.balance / (10**token.decimals)), "")))}</p>
                )}
            </>
        </div>
    )
}
