import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";

export function InputSlider({ 
    title, 
    subtitle = null, 
    value = 0, 
    min = 0, 
    max = 100, 
    step = 1, 
    onChange,
    onMouseUp=null,
    disabled = false, 
    igstyle = null 
}) {
    const [sliderValue, setSliderValue] = useState(value);

    const inputRef = useRef(null);

    useEffect(() => {
        if (sliderValue !== value) {
            setSliderValue(value);
        }
    }, [value]);

    function handleSliderChange(e) {
        const newValue = e.target.value;
        setSliderValue(newValue);
        onChange(Number(newValue));
    }

    function handleSliderMouseUp(e) {
        const newValue = e.target.value;
        setSliderValue(newValue);
        if(onMouseUp){
            onMouseUp(Number(newValue));
        }
        
    }

    return (
        <div style={igstyle ? igstyle : {}} className="input-group">
            {title && (
                <label style={{ marginBottom: subtitle ? 0 : 7 }}>
                    {title}
                </label>
            )}
            {subtitle && (
                <label className="small" style={{ fontSize: 11, fontWeight: '500', opacity: .7, width: '100%' }}>
                    {subtitle}
                </label>
            )}
            <input
                value={sliderValue}
                className="outline"
                type="range"
                min={min}
                max={max}
                step={step}
                disabled={disabled}
                onChange={handleSliderChange}
                onMouseUp={handleSliderMouseUp}
                ref={inputRef}
            />
            {/* <div className="slider-value">
                {sliderValue}
            </div> */}
        </div>
    );
}
