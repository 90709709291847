import React, {useState, useEffect} from "react";

import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useNavigator } from "../../hooks/useNavigator";
import { useDex } from "../../hooks/useDex";
import { DexPairCard } from "../Dex/DexPairCard";
import { TokenDataTabsInteractive } from "../Dex/TokenDataTabsInteractive";
import { TickerBlock } from "../../components/Dex/TickerBlock";
import { DexPairItem } from "../../components/Dex/DexPairItem";
import { ContractAddressToolbar } from "./ContractAddressToolbar";

import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";
import { ReactComponent as MicIcon } from '../../icons/svgs/mic.svg';

import xlogo from "../../icons/news/logo_x.png";
import ytlogo from "../../icons/news/logo_youtube.png";
import toast from 'react-simple-toasts';

import { dataTimeLabels } from "../../utils/tools";

var parse = require('html-react-parser');

export function ListPresentation({ list=null, listTitle=null, xSpaceToken=null, ytLiveToken=null, homePage=null, showList=true }) {

    const navigator = useNavigator();
    const {
        pageSize,
        listPage,
        handleListPage
    } = useCore();
    const { token, handleTokenList, listView, handleListView } = useDex();
    const { contractAddress, handleContractAddress, screens, pathname, handleSearchParams, searchParams } = useNavigator();

    const [listDataTime,setListDataTime] = useState('list');
    const [listDataSort, setListDataSort ] = useState('desc');

    const [xSpace, setXSpace] = useState(null);

    const {
        walletAddress
    } = useWallet();

    useEffect(() => {

        if(xSpaceToken){
            setXSpace(xSpaceToken);
        }else{
            if(screens[1] !== undefined && screens[1] == "spaces"){
                if(screens[2] !== undefined){
                    
                    console.log(screens[2]);
    
                    setXSpace(screens[2]);
                    
                }
                
            }
        }
        
        
    }, [ ]);

    const onListTimeClick = (v) => {
        console.log(v, listDataTime, listDataSort);
        if(listDataTime == v){
            if(listDataSort=='desc'){
                setListDataSort('asc');
            }else{
                setListDataSort('desc');
            }
            
        }else{
            setListDataTime(v);
        }
        
    }

    const toggleListDataSort = () => {
        let fmls = '';
        if(listDataSort == "desc"){
            fmls = "asc";
        }else{
            fmls = "desc";
        }
        setListDataSort(fmls);
    }

    const onFeaturedTokenClick = () => {
        console.log('lets go please')
    }

    const copyAddress = () => {
        toast(parse('<span className="mute">link</span> copied'));
    }

    const xLink = `https://x.com/i/spaces/${list.xSpace}`;
    const ytLink = `https://youtube.com/watch?v=${list.ytLive}`;
    const listLink = `${process.env.REACT_APP_ARTEMIS_URL}/list/${list.slug}`;

    const openSpace = () => {
        window.open(
            `https://x.com/i/spaces/${list.xSpace}`,
            "_blank"
        )
    }

    const openYoutube = () => {
        window.open(
            `https://youtube.com/watch?v=${list.ytLive}`,
            "_blank"
        )
    }

    const openList = () => {
        // handleTokenList(null);
        if(token){
            let v = "list";
            
            v += `&list=${list.slug}`;
            handleListView("list");
            handleListPage(list.slug);
            

            setTimeout(() => {
                navigator.push(`/${token.pair.data.chainId}/${token.pair.address}?view=${v}`);
            },200)
        }else{
            navigator.push(`/list/${list.slug}`);
        }
        
    }

    return (
        <>
            { list && list.featuredToken !== undefined && list.featuredToken && list.featuredToken?.pair?.data ? (
                <div style={{margin: walletAddress ? 7 : screens[1] !== undefined && screens[1] == "list" ? '7px' : '7px'}} className="flex-column">
                    <div style={{marginBottom:7}} className="feature-card">
                        <div className="feature-card-title">
                            <div style={{zIndex:-1}} className="ticker-overlay"/>
                            <div style={{display:"flex",flexDirection:"row"}} className="flex-row align center text-content justify space-between">
                                <div style={{maxHeight:27}} className="flex-row align center">
                                    <div style={{color: list.color}} className="icon-button-star"></div><p>featured</p>
                                </div>
                                <div style={{maxHeight:27}} className="flex-row align center">
                                    <div onClick={openList} style={{maxHeight: 33, pointerEvents: screens[1] == undefined || screens[1] !== "list" ? "all" : "none",marginRight:11}} className={`data-panel align center${screens[1] !== undefined && screens[1] == "list" ? "" : "  interactive"}`}>
                                        <div className="flex-row align center">
                                            <h6 style={{margin:0,marginRight:7}}>{list.name}</h6>
                                            <div style={{marginRight: 7, borderColor: list.color, background: list.color, margin: 0}} className="color-box"></div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="icon-button-list"></div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                   
              
                    { pageSize.width < 720 ? (
                        <DexPairCard
                            t={list.featuredToken}
                            tokenStyle="featured"
                            featured={true}
                        />
                    ):(
                        <DexPairItem
                            withSpace={false}
                            rankAnimation={false}
                            hideRank={true}
                            passClick={onFeaturedTokenClick}
                            pairData={list.featuredToken.pair?.data}
                            pair={list.featuredToken.pair}
                            token={list.featuredToken}
                            coreToken={token}
                            addClass={'no-margin featured'}
                            tokenStyle="featured"
                            featured={true}
                            list={list}
                        />
                    )}
                    
                    
                    
                </div>
            ):null}

            { list && list.tokens.length ? (
                
                <div style={{margin: !walletAddress && list.featuredToken ? '7px 7px' : !walletAddress ? 7 : 7}} className="flex-column">
                    
                    { listPage == list.slug || listPage !== list.slug ? (
                        <div className="feature-card">
                            <div style={{minHeight: 22}} className="feature-card-title">
                                <div style={{zIndex:-1}} className="ticker-overlay"/>
                                <div style={{paddingTop: list.tags?.length ? 4 : 4 }} className="text-content">
                                    
                                    <div style={{marginBottom:3}} className="flex-row align center">
                                        <div style={{opacity:.8}} className="icon-button-list"/>
                                        { listTitle ? (
                                            <p>{listTitle}</p>
                                        ): list?.title? (
                                            <p>{list.title}</p>
                                        ):null}
                                    </div>
                                    

                                    
                                    
                                    
                                    {list.name ? (
                                        <div className="flex-row">
                                            
                                            <div style={{maxHeight:33}} className="flex-row align center">
                                                <div onClick={openList} style={{pointerEvents: screens[1] == undefined || screens[1] !== "list" ? "all" : "none",maxHeight: 33, marginTop:3, marginRight:11}} className={`data-panel align center${screens[1] !== undefined && screens[1] == "list" ? "" : "  interactive"}`}>
                                                    <div className="flex-row align center">
                                                        <div style={{marginRight: 0, borderColor: list.color, background: list.color}} className="color-box"></div>
                                                        <h6 style={{margin:0,marginLeft:7,marginRight:7}}>{list.name}</h6>
                                                        <div className="count-circle">{list.tokens.length}</div>
                                                    </div>
                                                    
                                                </div>
                                            
                                            
                                                { !homePage ? (
                                                    <div style={{marginTop:4,marginLeft:0,transform:'scale(.84)'}} className="coin-links small">
                                                        <CopyToClipboard
                                                                    text={listLink}
                                                                    onCopy={copyAddress}
                                                                    style={{cursor:"pointer"}}
                                                                
                                                        >
                                                            <div onClick={null} data-tip={`copy link`} className="coin-link">

                                                                <div style={{fontSize:14}} className="icon-button-copy"/>

                                                            </div>
                                                        </CopyToClipboard>

                                                    </div>
                                                ):null}
                                            </div>
                                            
                                        
                                        </div>
                                    ):null}

                                    { list.xSpace ? (
                                        <div style={{marginTop:7   }} className="flex-row">
                                            
                                            { list.live ? (
                                                <div style={{marginTop:6}} className="green-circle animated"/>
                                            ):null}
                                            <div data-tip={`open X space`} className="flex-row" style={{cursor:"pointer"}} onClick={openSpace}>
                                                <div  style={{width:19,height:19,opacity:.7}} >
                                                    <img src={xlogo}/>
                                                </div>

                                                <div style={{marginTop:0,marginLeft:-2,width:15,height:15}}>
                                                    <MicIcon/>
                                                </div>
                                            </div>
                                            
                                            <div style={{alignItems:'flex-start',marginLeft:3}} className="flex-row">
                                                <CopyToClipboard
                                                    text={xLink}
                                                    onCopy={copyAddress}
                                                >
                                                    <div data-tip={`copy X space link`} style={{marginLeft:7,cursor: "pointer"}} className={classNames("flex-row address")}>
                                                        
                                                        <p style={{fontSize:11, color: "inherit"}} className="data-value currency contract">{list.xSpace}</p>
                                                    </div>
                                                </CopyToClipboard>
                                                <div style={{marginTop:-3,marginLeft:7,transform:'scale(.84)'}} className="coin-links small">
                                                    <CopyToClipboard
                                                        text={xLink}
                                                        onCopy={copyAddress}
                                                    >
                                                        <div onClick={null} data-tip={`copy X space link`} style={{cursor:"pointer",padding:4}} className="coin-link">

                                                            <div style={{fontSize:14}} className="icon-button-copy"/>

                                                        </div>
                                                    </CopyToClipboard>
                                                
                                                    <div onClick={openSpace} data-tip={`open X space`} style={{cursor:"pointer",padding:4}} className="coin-link">
                                                        <div style={{fontSize:14}} className="icon-button-external-link"/>
                                                    </div>
                                                </div>
                                            </div>
                                        

                                        
                                        </div>

                                    ):null}


                                    { list.ytLive ? (
                                        <div style={{marginTop: list.xSpace ? 0 : 3}} className="flex-row">
                                            
                                            { list.live ? (
                                                <div style={{marginTop:8,background:"red"}} className="green-circle animated delay"/>
                                            ):null}
                                            <div data-tip={`open Youtube`} className="flex-row" style={{cursor:"pointer"}} onClick={openYoutube}>
                                                <div style={{marginLeft:2,width:22,height:22,opacity:.7}} >
                                                    <img src={ytlogo}/>
                                                </div>
                                            </div>
                                            
                                            <div style={{alignItems:'flex-start',marginLeft:3}} className="flex-row">
                                                <CopyToClipboard
                                                    text={ytLink}
                                                    onCopy={copyAddress}
                                                >
                                                    <div data-tip={`copy Youtube link`} style={{marginLeft:7,cursor: "pointer"}} className={classNames("flex-row address")}>
                                                        
                                                        <p style={{fontSize:11, color: "inherit"}} className="data-value currency contract">{list.ytLive}</p>
                                                    </div>
                                                </CopyToClipboard>
                                                <div style={{marginTop:-3,marginLeft:7,transform:'scale(.84)'}} className="coin-links small">
                                                    <CopyToClipboard
                                                        text={ytLink}
                                                        onCopy={copyAddress}
                                                    >
                                                        <div onClick={null} data-tip={`copy Youtube link`} style={{cursor:"pointer",padding:4}} className="coin-link">

                                                            <div style={{fontSize:14}} className="icon-button-copy"/>

                                                        </div>
                                                    </CopyToClipboard>
                                                
                                                    <div onClick={openYoutube} data-tip={`open Youtube`} style={{cursor:"pointer",padding:4}} className="coin-link">
                                                        <div style={{fontSize:14}} className="icon-button-external-link"/>
                                                    </div>
                                                </div>
                                            </div>
                                        

                                        
                                        </div>

                                    ):null}

                                    <div style={{marginTop:7}} className="flex-row">
                                    
                                    
                                        <TokenDataTabsInteractive
                                            title="price"
                                            bookend={false}
                                            addStyle={{borderRadius:'4px',overflow:'hidden'}}
                                            addClass={'centerlast'}
                                            passClick={onListTimeClick}
                                            buttonValue={listDataTime}
                                            buttonValues={['list','launch','m5','h1','h6','h24']}
                                            values={ ['icon-button-list','icon-button-clock','5m','1h','6h','24h']}
                                            dataTips={['list order', 'created']}
                                            valuesFormat={['string','string','string','string','string','string']}
                                            labels={['','','','','','']}
                                        />

                                        { listDataTime !== "list" ? (
                                            <div style={{borderRadius:'4px',overflow:'hidden', marginLeft:7}} className="data-row interactive-items">
                                                <div onClick={toggleListDataSort} style={{display:"flex",alignItems:'center',justifyContent:'center'}} className="data-item">
                                                    <div className={listDataSort == "desc" ? "icon-arrow-down" : "icon-arrow-up"}/>
                                                </div>
                                            </div>
                                        ):null}
                                    

                                        { list.tokens.length > list.viewLimit ? (
                                            <div style={{marginLeft:11}} className="flex-row align center">
                                                <p>{list.viewLimit} <span className="mute">of</span> {`${list.tokens.length}`}</p>
                                            </div>
                                        ):null}
                                        
                                    </div>
                                    
                                </div>

                                { list.tags?.length ? (
                                    <div style={{position:"absolute",top:4,right:0,zIndex:111}} className="flex-row">
                                
                                        { list.tags
                                    
                                        .map((tag,i) => (
                                            
                                            <div key={i} style={{pointerEvents:'none', background: list.color }} className="social-item-tag hash"><span className="mute">#</span>{tag}</div>
                                            
                                        ))}

                                    
                                    </div>
                                ):null}

                                
                                
                            </div>

                            
                            
                        </div>
                    ):(
                        <div style={{marginTop:0}} className="flex-row align center">
                                    
                                    
                            <TokenDataTabsInteractive
                                title="price"
                                bookend={false}
                                addStyle={{borderRadius:'4px',overflow:'hidden'}}
                                addClass={'centerlast'}
                                passClick={onListTimeClick}
                                buttonValue={listDataTime}
                                buttonValues={['list','launch','m5','h1','h6','h24']}
                                values={ ['icon-button-list','icon-button-clock','5m','1h','6h','24h']}
                                dataTips={['list order', 'created']}
                                valuesFormat={['string','string','string','string','string','string']}
                                labels={['','','','','','']}
                            />

                            { listDataTime !== "list" ? (
                                <div style={{borderRadius:'4px',overflow:'hidden', marginLeft:7}} className="data-row interactive-items">
                                    <div onClick={toggleListDataSort} style={{display:"flex",alignItems:'center',justifyContent:'center'}} className="data-item">
                                        <div className={listDataSort == "desc" ? "icon-arrow-down" : "icon-arrow-up"}/>
                                    </div>
                                </div>
                            ):null}

                            { list.tags?.length ? (
                                <div style={{position:"relative",marginLeft:11}} className="flex-row">
                            
                                    { list.tags
                                
                                    .map((tag,i) => (
                                        
                                        <div key={i} style={{pointerEvents:'none', background: list.color }} className="social-item-tag hash"><span className="mute">#</span>{tag}</div>
                                        
                                    ))}

                                
                                </div>
                            ):null}

                        </div>
                    )}
                    
                    

                    {/* <div className="feature-card code">
                        <p>here we go. watch fiona and look at these others as they might dip further. lets go.</p>
                    </div> */}

                    
                    { list.tokens.length && showList ? (
                        <div className="feature-card-tokens">
                           
                            { list.tokens
                            .filter(n => n.pair?.data)
                            .sort((a, b) => {

                                if(listDataTime == "list"){
                                    return true;
                                }else if(listDataTime == "launch"){
                                    if(listDataSort == "desc"){
                                        return b.pair.data?.pairCreatedAt - a.pair.data?.pairCreatedAt;
                                    }else{
                                        return a.pair.data?.pairCreatedAt - b.pair.data?.pairCreatedAt;
                                    }
                                }else{
                                    if(listDataSort == "desc"){
                                        return b.pair.data?.priceChange[`${listDataTime}`] - a.pair.data?.priceChange[`${listDataTime}`];
                                    }else{
                                        return a.pair.data?.priceChange[`${listDataTime}`] - b.pair.data?.priceChange[`${listDataTime}`];
                                    }
                                }



                            })
                            .slice(0, list.viewLimit)
                            .map((t,i) => (
                               
                                <DexPairCard
                                key={`list${i}`}
                                dataTime={listDataTime}
                                dataTimeLabel={dataTimeLabels[`${listDataTime}`]}
                                addStyle={ walletAddress ? {} : {}}
                                activeColor={list.featuredToken?._id == t._id || list.featuredToken == t._id ? list.color : false}
                                t={t}/>
                                
                            ))}
                        </div>
                    ):null}
                    
                </div>
            ):null}
        </>
    );
}





