import React, { useState, useEffect, useContext, createContext } from "react";
import toast from 'react-simple-toasts';
import { client } from "../../services/api";

import { useWallet } from "../useWallet";

const collectionContext = createContext();

function useProvideCollection() {

    // const [authToken, setAuthToken] = useState('test');

    // const [authToken, setAuthToken] = useState(
    //     localStorage.getItem("token") || true
    // );

    // const [loading, setLoading] = useState(!!authToken);

    const { walletAddress } = useWallet();

    const [loading, setLoading] = useState(false);
    const [collections, setCollections] = useState([]);

    const [ assetToAdd, setAssetToAdd ] = useState(null);

    const [ tokenListCollectionModal, setTokenListCollectionModal ] = useState(false);
    const [ twitterUserListCollectionModal, setTwitterUserListCollectionModal ] = useState(false);

    const [showCollectionsOptions, setShowCollectionsOptions] = useState(false);
    const [ cox, setCox ] = useState(0);
    const [ coy, setCoy ] = useState(0);

    useEffect(() => {
        // if(walletAddress){
        //     fetchCollections(walletAddress);
        // }

    }, [ walletAddress ]);


    const fetchCollections = async (wa) => {
        setLoading(true);

        try {

            const c = await client.getCollections({ wa });
            console.log(c)
            setCollections(c.data);

            setLoading(false);
        } catch (error) {
            // setHasError(true);
            if(error){
                // setError(error.message);
            }
            setLoading(false);
        }
    };

    // const createCollection = async (title) => {
    //     setLoading(true);
    //
    //     try {
    //         let data = {
    //
    //         }
    //         const c = await client.createCollection({ walletAddress });
    //         console.log(c)
    //
    //         setAuthToken(authResult.data.token);
    //
    //         setLoading(false);
    //     } catch (error) {
    //         setHasError(true);
    //         if(error){
    //             setError(error.message);
    //         }
    //         setLoading(false);
    //     }
    // };

    async function createCollection(data) {

        const c = { ...data };
        console.log(c)
        const response = await client.createCollection(c);
        console.log(response);
        // setCollections({ ...collections, [response.data._id]: response.data });
        // setThemesRevert({ ...themesRevert, [response.data._id]: response.data })
    }

    async function saveCollection(c) {
        // const { isDirty, ...collection } = collections[id];
        console.log('in save collection', c)
        const response = await client.saveCollection(c);

        // setCollections([ ...collections, response.data ]);
        // setThemesRevert({ ...themesRevert, [response.data._id]: response.data })
    }

    // async function saveMapDynamic(id, dataInput) {
    //     let mutation = { ...dataInput };
    //
    //     // We need to send the full features object so we create the next
    //     // version of the state here and push to api
    //     if (!!mutation.features) {
    //         mutation.features = {
    //             ...map.features,
    //             ...mutation.features,
    //         };
    //     }
    //
    //     const response = client.saveMap({ _id: id, ...mutation });
    //     updateMaps(response.data);
    //     return response;
    // }

    // function updateCollection(data) {
    //     let themeid = space.theme;
    //     const nextTheme = { ...themes[themeid], ...data, isDirty: true };
    //
    //     setCollections({ ...collections, [themeid]: nextTheme });
    //
    //     // if (iFrame) {
    //     //     for(var key in data){
    //     //         if(key.indexOf('embedButton') > -1){
    //     //             delete data[key]
    //     //         }
    //     //     }
    //     //     postMessage({
    //     //         updateTheme: true,
    //     //         themeUpdates: data,
    //     //     });
    //     // }
    // }

    //
    // const logOut = () => {
    //     localStorage.removeItem("token");
    //     Cookies.remove("cktoken", "");
    //
    //     setAuthToken(null);
    //     setCurrentUser(null);
    //     setIsAuthenticated(false);
    // };


    const handleShowCollectionsOptions = (v, x, y) => {
        setShowCollectionsOptions(v);
        setCox(x);
        setCoy(y);
    }

    const handleAddToCollection = (c, a) => {
        console.log(c, a);
        setShowCollectionsOptions(false);
        let item = {
            name: a.name,
            project: a.collection || a.project,
            asset_contract: a.asset_contract,
            ca: a.asset_contract.address,
            token_id: a.token_id,
            image_original_url: a.image_original_url,
            image_preview_url: a.image_preview_url,
            image_thumbnail_url: a.image_thumbnail_url,
            image_url: a.image_url,
            owner: a.owner
        }

        let cs = [];
        for(var i=0;i<collections.length;i++){
            if(collections[i].token == c.token){
                let ctemp = collections[i];
                let cassets = [...ctemp.assets];
                let inarray = false;
                for(var j=0;j<cassets.length;j++){
                    if(cassets[j].ca == item.ca && cassets[j].token_id == item.token_id){
                        inarray = true;
                    }
                }
                if(!inarray){
                    ctemp.assets = [...ctemp.assets, item];
                    console.log(ctemp)
                    saveCollection(ctemp);

                }
                cs.push(ctemp);
            }else{
                cs.push(collections[i])
            }
        }
        setCollections(cs);

        console.log(item);
        toast(a.collection.name + ' #' + a.token_id + ' added to ' + c.name );
    }

    const handleRemoveFromCollection = (c, a) => {

    }

    const handleAssetToAdd = (n) => {
        setAssetToAdd(n);
    }

    const handleTokenListCollectionModal = (v) => {
        setTokenListCollectionModal(v);
    }

    const handleTwitterUserListCollectionModal = (v) => {
        setTwitterUserListCollectionModal(v);
    }


    // Return the user object and auth methods
    return {
        loading,
        createCollection,
        saveCollection,
        fetchCollections,
        collections,
        showCollectionsOptions,
        handleShowCollectionsOptions,
        cox,
        coy,
        handleAddToCollection,
        handleRemoveFromCollection,
        handleAssetToAdd,
        assetToAdd,
        tokenListCollectionModal,
        handleTokenListCollectionModal,
        handleTwitterUserListCollectionModal,
        twitterUserListCollectionModal
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideCollection({ children }) {
    const c = useProvideCollection();
    return <collectionContext.Provider value={c}>{children}</collectionContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useCollection = () => {
    return useContext(collectionContext);
};
