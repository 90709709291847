import React from "react";

import classNames from "classnames";

export function FilterSegment({ filters, activeValue, passClick }) {


    return (
        <div className="filter-segment">

            {filters.map((filter, i) => (
                <button key={i} className={classNames('filter tiny', activeValue == filter.value && 'active')} onClick={() => passClick(filter.value)}>
                    {filter.name}
                </button>
            ))}

        </div>
    )
}
