import React, { useEffect, useState, useRef } from "react";

// import { ReactSortable, Swap } from "react-sortablejs";

import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";

import { DexSwapDraggable } from "../Dex/DexSwapDraggable";
import { FilterSegment } from '../FilterSegment';
import { InputSelect } from "../Input/InputSelect";
import { InputTextField } from "../Input/InputTextField";
import { LoaderSpinner } from "../Layout/LoaderSpinner";


import { TwitterUserPage } from "./TwitterUserPage";
import { TwitterUserPanel } from "./TwitterUserPanel";
import { TwitterTimePanel } from "./TwitterTimePanel";
import { TwitterUserCard } from "./TwitterUserCard";
import { ViewToolbar } from "./ViewToolbar";
import { QRCodeSVG } from 'qrcode.react';

import classNames from "classnames";

import * as tools from "../../utils/tools";
import web3 from "web3";
// import Draggable from "react-draggable";

import {ReactComponent as ClockIcon} from '../../icons/svgs/clock.svg';


export function TwitterDashboard({ visible }) {

	const navigator = useNavigator();
	const { screens } = useNavigator();

	const socialContainerRef = useRef(null);


	const { chainPanel, handleChainPanel, sidebarRight, handleSidebarRight } = useCore();
	const { user, walletAddress } = useWallet();
	const { view,
		loadingSocial,
		twitterUsers,
		searchTwitterUsers,
		twitterUser,
		fetchTwitterUserByUsername,
		timeFilter,
		handleTimeFilter,
		twitterSearchedUser,
		twitterUserLists,
		updateTwitterUserList,
		handleTwitterUserLists,
		handleTwitterUsers,
		listSlug
	} = useSocial();

	const { loading,
		tokenPair,
		dexChartVisible,
		handleDexChartVisible,
		swapVisible,
		apeSettings,
		handleScreenClickDims,
		searchDex
	} = useDex();

	const [ socialCollection, setSocialCollection] = useState('default');
	const [ twid, setTwid ] = useState(null);
	const [ searchUsers, setSearchUsers ] = useState('');

	useEffect(() => {
		if(twitterUser){
			handleSidebarRight(true);
		}
	}, [ twitterUser ]);

	useEffect(() => {
	    const sc = socialContainerRef.current;

	    const handleKeyDown = (event) => {
	      if (event.key === "ArrowLeft") {
	        event.preventDefault();

			let st = sc.scrollLeft%320 > 0 ? Math.floor(sc.scrollLeft / 320)*320 : Math.floor(sc.scrollLeft / 320)*320 - 320;
	        sc.scrollTo({
	          left: st,
	          behavior: "smooth",
	        });
	      } else if (event.key === "ArrowRight") {
	        event.preventDefault();
			console.log(sc.scrollLeft%320)
			let st = sc.scrollLeft%320 > 0 ? sc.scrollLeft + 320 - sc.scrollLeft%320  : (sc.scrollLeft / 320)*320 + 320;
	        sc.scrollTo({
	          left: st,
	          behavior: "smooth",
	        });
	      }
	    };

	    window.addEventListener("keydown", handleKeyDown);

	    return () => {
	      window.removeEventListener("keydown", handleKeyDown);
	    };
	}, [socialContainerRef]);

	const switchTwitterUser = (tu) => {
		console.log(tu);
		setTwid(tu.twid);
	}

	const socialCollectionClick = (f) => {
		setSocialCollection(f);
		console.log(f)

        // let q = {
        //     category: f,
        //     order: coinOrder
        // }
        // fetchCoinsMarkets(q);

    }

	const timeFilters = [
        {
            name: '1d',
            value: "1"
        },
        {
            name: "3d",
            value: "3"
        },
        {
            name: "7d",
            value: "7"
        },
        {
            name: "1m",
            value: "30"
        },
        {
            name: "all",
            value: "max"
        }
    ];

	const mockCollections = [
		{
			name: 'default',
			value: 'default'
		},
		{
			name: 'strategy',
			value: 'strategy'
		}
	]

	const selectcollection = mockCollections.map((cat, i) => {
        return cat.name;
    });
    const selectcollectionvalues = mockCollections.map((cat, i) => {
        return cat.category_id;
    });



	const onSearchUsersChange = (v) => {
        setSearchUsers(v);
    }

	const _handleKeyDown = (e) => {
        console.log(e)
        if (e.key === "Enter") {
            onSearchUsers();
        }
    };

	const onSearchUsers = async () => {

        searchTwitterUsers(searchUsers);
		navigator.push(`/social/search/${searchUsers}`);
        // setSearchUsers('');

    };

	const onTagClick = async (e,t,type="default") => {

        console.log('lets go', e,t);
        let ex = e.clientX;
        let ey = e.clientY + 33;
        handleScreenClickDims(ex,ey);
        let tagtype = t.charAt(0);
        if(tagtype == '$' || type=="dex"){
			let q = t.replace('$','');
            searchDex(q);
        }else if(tagtype=="@"){
            let un = t.replace("@","");
            fetchTwitterUserByUsername(un);
        }


    };


	const onUserOrderChange = async (data) => {
        console.log(data);
        let t = [...data];


		let tl = { ...twitterUserLists };
		let tw = [];
		for(var i=0;i<t.length;i++){
		   tw.push(t[i]._id);
		}
		console.log(tw)

		let lid = null;
		for(var key in twitterUserLists){
			if(listSlug == twitterUserLists[key].slug){
				lid = twitterUserLists[key]._id;
				tl[key] = {...tl[key], twitterUsers: [...tw]}
			}
		}


		// handleTwitterUsers(t);
		//

		handleTwitterUserLists(tl);
		let d = {
			_id: lid,
			twitterUsers: [...tw],
		}
		//
		let u = await updateTwitterUserList(d);




        // console.log(data);
        // console.log(t);
    // // //    setKeyFrames(t);
    // // //    handleChangeScene({id: sceneId, keyFrames: t, isDirty: true});
    }

	const twitterUsersFiltered = twitterUserLists[listSlug] !== undefined ?
	[] : twitterUsers.filter(tu => {
		if(twitterUserLists[listSlug] == undefined){
			return false;
		}else{
			console.log(twitterUserLists[listSlug].twitterUsers.includes(tu._id))
			return twitterUsers.includes(tu._id);
		}

	});

    return (
		<div className={classNames("social-dashboard", visible && "visible")} >
			<div className="dex-chart" style={{display: dexChartVisible ? 'flex' : 'none'}}>
				{tokenPair && tokenPair.data !== undefined && (
					<iframe src={`https://dexscreener.com/${tokenPair.data.chainId}/${tokenPair.data.pairAddress}?embed=1&theme=dark&info=0`}/>
				)}
			</div>

{/*

			{ tokenPair && (
				<Draggable>
					<div className="qr-code-panel">
						<QRCodeSVG value={`${process.env.REACT_APP_ARTEMIS_URL_IP}/dex/${tokenPair.pairAddress}`}
						size="512"
						bgColor="#FFFFFF"
						fgColor="#1d1d1d" />
					</div>
				</Draggable>
			)}
*/}






			<div className="social-list" style={{display: !dexChartVisible  ? 'initial' : 'none'}}>
				{ ( loadingSocial || ( loading.dex && !sidebarRight )) && (
					<LoaderSpinner/>
				)}

		        <div className={classNames('list-wrapper')}>

					{  screens[2] !== "user" && (

						<div className="social-header">

							<div className="header-texture"/>

							<div style={{ paddingLeft:33}} className="flex-row align center">
								<div style={{marginRight:33,transform: 'scale(.77)',opacity: .44 }} className="svg-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
										<path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
									</svg>
								</div>

								<ViewToolbar/>
							</div>


							<div className="coin-filters">

								{ view == "time" && (
									<div className="coin-links center">
										{/*<div style={{opacity:.5,marginRight:7}} className="svg-icon">
											<ClockIcon/>
										</div>*/}

										<FilterSegment activeValue={timeFilter} passClick={handleTimeFilter} filters={timeFilters}/>
									</div>
								)}

	{/*
								{ view == "panel" && (
									<>
										<InputSelect
											title=''
											onChange={socialCollectionClick}
											value={socialCollection}
											options={selectcollection}
											optionsValues={selectcollectionvalues}
											addClass="button-icon-select"
											addClassInputGroup="no-margin"

										/>
									</>
								)}*/}


								{/*<FilterSegment activeValue={coinCategory} passClick={catClick} filters={filterCats}/>*/}

							</div>
							<InputTextField
								title={''}
								igstyle={{padding:0}}
								onChange={onSearchUsersChange}
								placeholder='Add Twitter User'
								value={searchUsers}
								onKeyPress={_handleKeyDown}
								autoComplete="off"
								disabled={loading.social}
								// focus={true}
							/>

						</div>

					)}


					<div ref={socialContainerRef} className="social-content">


						{ screens[2] == "user" && screens[3] !== undefined && twitterUser && twitterUser.public_metrics !== undefined && (

							<TwitterUserPage twu={twitterUser} passTagClick={onTagClick} />

						)}


						{ screens[2] == "search" && screens[3] !== undefined && twitterSearchedUser && (
							<div style={{marginTop:88,marginLeft:26}}>
								<TwitterUserCard twu={twitterSearchedUser} type="search"/>
							</div>
						)}
{/*
						<p>{twitterUserLists.length}-{listSlug}</p>
*/}
					{/*	{ view == "users" && twitterUserLists.length && listSlug && (

								<ReactSortable
									  className="social-panel users"
									  // model="twitterUsers"
									  // axis="x"
									  // delay={1000}
									  fallbackOnBody={true}
									  // list={twitterUserLists[listSlug].twitterUsers}
									  list={twitterUsersFiltered}
									  setList={onUserOrderChange}
									  animation={0}
									  group="twitterusers"
									  // onSwap={onUserOrderChange}
									  onChange={(order, sortable, evt) => {}}
									  // onEnd={evt => {}}
									  // swap={true}
									  swapThreshold={1}
									  // sort={true}
									  // scroll={true}
									  ghostClass='ghost'
									>


									{ twitterUsersFiltered.map((twu, i) => (
										<TwitterUserCard key={`twu${i}`} twu={twu}/>
									))}
								</ReactSortable>



						)}*/}


						{/*
						{ twitterUserLists[listSlug].twitterUsers.map((twuid, i) => (
							<TwitterUserCard key={`twu${i}`} twu={twitterUsers[twuid]}/>
						))}*/}


						{ screens[2] == "list" && (
							<>
								{ view == "time" && (
									<>
										<TwitterTimePanel passTagClick={onTagClick} users={twitterUsers}/>
									</>
								)}

								{ view == "panel" && twitterUsers.length ? (
									<>
										{twitterUsers.map((twu, i) => (
											<TwitterUserPanel passTagClick={onTagClick} twu={twu} key={i} kid={i}/>
										))}
									</>
								) : null}
							</>
						)}




					</div>
		        </div>
			</div>
		</div>
    );
}
