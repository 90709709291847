import React, { useState, useEffect, useCallback, useRef} from "react";

import { TwitterPost } from "./TwitterPost";
import { TwitterTag } from "./TwitterTag";
import { IconActionButton } from "../IconActionButton";
import { FilterSegment } from '../../components/FilterSegment';
import { TwitterMetrics } from "../../components/Social/TwitterMetrics";

import classNames from "classnames";

import { dateToTime } from "../../utils/tools";

import { useSocial } from "../../hooks/useSocial";

import ReactTooltip from 'react-tooltip';

import {ReactComponent as NodesIcon} from '../../icons/svgs/nodes.svg';

import {ReactComponent as RetweetIcon} from '../../icons/svgs/retweet.svg';
import {ReactComponent as ClockIcon} from '../../icons/svgs/clock.svg';

import { addCommas, getPosition } from "../../utils/tools";
import { formatCurrencyScale } from "../../utils/currency";
var parse = require('html-react-parser');


export function TwitterTimePanel({ users, passTagClick }) {

    let { tagRoll,
        handleTagRoll,
        fetchTwitterUserByUsername,
        handleTimeline,
        timeline,
        timeFilter,
        handleTimeFilter,
        twitterUsers
    } = useSocial();

    const ref = useRef();

    const [ posts, setPosts ] = useState([]);
    const [ tags, setTags ] = useState([]);

    const [ userRoll, setUserRoll ] = useState(null);
    const [ userRollData, setUserRollData ] = useState(null);

    const [ tagPostPercent, setTagPostPercent ] = useState(0);


    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );

    useEffect(() => {
        let p = [];
        let t = [];

        let now = new Date();

        let timeStart = null;

        if(timeFilter == "max"){
            timeStart = dateToTime(now) - 7*24*3600;
        }else{
            timeStart = dateToTime(now) - timeFilter*24*3600;
        }

        let timeEnd = dateToTime(now);
        console.log(timeStart,timeEnd)
        for(var i=0;i<users.length;i++){

            for(var j=0;j<users[i].posts.length;j++){
                let post = users[i].posts[j];
                p.push(post);


                for(var key in post.tags){
                    if(key == "cash" || key == "hash"){
                        for(var k=0;k<post.tags[key].length;k++){
                            let ct = post.tags[key][k];
                            let symbol = ct;

                            let time = dateToTime(new Date(post.created));

                            if(time > timeStart || timeFilter == "max"){

                                if(t[symbol] == undefined){
                                    t[symbol] = {
                                        posts: [],
                                        users: [],
                                        firstPost: null,
                                        latestPost: post.created,
                                        userReach: 0,
                                        impressions: 0,
                                        retweets: 0
                                    }
                                }

                                let tag = {
                                    symbol:ct,
                                    date: post.created,
                                    time: time,
                                    username: users[i].username,
                                    profile_image_url: users[i].profile_image_url,

                                }

                                console.log(t[symbol].users);
                                if(t[symbol].users.findIndex(x => x.username === users[i].username) < 0){
                                    let u = [...t[symbol].users];
                                    u.push(users[i]);
                                    t[symbol].users = u;

                                    let fcount = t[symbol].userReach*1;
                                    fcount += users[i].public_metrics.followers_count*1;
                                    t[symbol].userReach = fcount;
                                }

                                let icount = t[symbol].impressions*1;
                                icount += post.public_metrics.impression_count*1;
                                t[symbol].impressions = icount;

                                let rtcount = t[symbol].retweets*1;
                                rtcount += post.public_metrics.retweet_count*1;
                                t[symbol].retweets = rtcount;

                                let ptemp = [...t[symbol].posts];
                                ptemp.push(tag);
                                t[symbol].posts = ptemp;
                            }

                            if(timeFilter == "max" && time < timeStart){
                                timeStart = time;
                            }

                        }

                    }

                }

            }
        }

        handleTimeline({
            timeStart,
            timeEnd,
            timeTotal: timeEnd - timeStart
        })

        console.log(p);
        console.log(t);

        setPosts(p);
        setTags(t);


    }, [ users, timeFilter ] );




    const onTagRollOver = (t) => {
        handleTagRoll(t);
    }
    const onTagRollOut = () => {
        handleTagRoll(null);
    }

    const getTimePositionPercent = (t) => {
        return ((t-timeline.timeStart) / timeline.timeTotal)*100 + '%';
    }

    const onUserRollOver = (e, u, t=null) => {

        setUserRoll(u);

        if(t){
            console.log(t,timeline)
            let timep = ((t.time-timeline.timeStart) / timeline.timeTotal)*100;
            console.log(timep)
            setTagPostPercent(timep);
            setUserRollData(t);
            handleTagRoll(t.symbol);
        }
    }
    const onUserRollOut = () => {
        setUserRoll(null);
        handleTagRoll(null);
    }



    // let orderedPosts = twu.posts.filter(p => p.tags)
    // .sort((a, b) => {
    //     return new Date(b.created) - new Date(a.created);
    // });

    let orderedPosts = posts.filter(p => p.tags.cash.length)
    .sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
    });

    let filteredTags
    let orderedTags = Object.keys(tags)
        // .filter(t => (tags[t].cats.includes(coinCategory) || coinCategory == "all" ))
    .sort((a, b) => {
        return tags[b].users.length - tags[a].users.length || tags[b].posts.length - tags[a].posts.length || tags[b].latestPost - tags[a].latestPost
    });

    // const toggleOptionsMenu = () => {
    //     setOptionsMenu(!optionsMenu);
    // }

    const timeTickCounts = {
        "1": {
            ticks:25,
            steps:4
        },
        "3": {
            ticks:10,
            steps:3
        },
        "7": {
            ticks:15,
            steps:2
        },
        "30": {
            ticks:31,
            steps:7
        },
        "all": {
            ticks:31,
            steps:7
        },
    }

    const timeTicks = Array(timeTickCounts[timeFilter].ticks).fill(1);


    return (

        <div
        ref={ref}
        //onClick={() => switchTwitterUser(twu)}
        //className={classNames('social-item', twid == twu.twid && 'active')}

        className="social-panel time"
        >

            { orderedTags
                // .filter(t => (tags[t].cats.includes(coinCategory) || coinCategory == "all" ))
                // .sort((a, b) => a["latestPost"] > b["latestPost"] ? 1 : -1)
                .map((p, i) => (
                    <div className="tag-timeline-wrapper">

                        <div className="tag-info">
                            <div className="header-texture"/>
                            <div className="flex-row">
                                <TwitterTag
                                    // post={post}
                                    tag={p}
                                    tagRoll={tagRoll}
                                    passClick={passTagClick}
                                    passOnMouseEnter={onTagRollOver}
                                    passOnMouseLeave={onTagRollOut}
                                    count={tags[p].posts.length}
                                />

                                <TwitterMetrics
                                    metrics={tags[p]}
                                />
                            </div>

                            <div className="tag-users">
                                {tags[p].users
                                .sort((a, b) => {
                                    return b.public_metrics.followers_count - a.public_metrics.followers_count
                                })
                                .map((u,i) => (
                                    <div key={i} data-tip={`@${u.username}`}
                                    onMouseEnter={(e) => onUserRollOver(e,u.username)}
                                    onMouseLeave={() => onUserRollOut()}
                                    style={{
                                        marginLeft: tags[p].users.length > 9 ? `-${(Math.round(tags[p].users.length-8.4)*24/tags[p].users.length)}px` : 0,

                                    }}
                                    className={classNames("tag-user-image", userRoll && userRoll !== u.username && "mute", userRoll == u.username && "active")}>
                                        <img src={u.profile_image_url}/>
                                    </div>
                                ))}
                            </div>
                            {/*<p key={i}>{p}-{tags[p].users.length}-{tags[p].posts.length}</p>*/}
                        </div>

                        <div className="tag-timeline-left-column">
                            <div className="header-texture"/>
                        </div>
                        <div className="tag-timeline">
                            <div className="header-texture"/>

                            { tagRoll == p && (

                                <div style={{left: tagPostPercent + '%', marginLeft: `-${tagPostPercent*1.2}px`}}
                                className="timeline-user">

                                    {userRollData && (
                                        <>
                                        <div className={classNames("tag-user-image", "")}>

                                                <img src={userRollData.profile_image_url}/>


                                        </div>
                                        <p style={{marginLeft:0,transform: "scale(.9)"}} className="data-value">@{userRollData.username}</p>
                                        </>

                                    )}

                                </div>


                            )}



                            { tags[p].posts.map((tp,i) => (
                                <div
                                key={i}
                                onMouseEnter={(e) => onUserRollOver(e,tp.username,tp)}
                                onMouseLeave={() => onUserRollOut()}
                                className={classNames("tag-post", userRoll && userRoll !== tp.username && "mute", userRoll == tp.username && "active")} style={{ left: getTimePositionPercent(tp.time)  }}>

                                </div>
                            ))}

                            { timeTicks.map((t,i) => (
                                <div className="time-tick"
                                    style={{
                                        opacity: i%timeTickCounts[timeFilter].steps > 0 ? .17 : .44,
                                        marginTop: -1.5,
                                        top: '70%',
                                        left: (100/(timeTicks.length-1)*i) + "%"
                                    }}
                                >

                                </div>
                            ))}

                            <div className="time-line-dotted"/>
                            <div className="time-line-start"/>
                            <div className="time-line-end"/>



                        </div>

                        <div className="tag-timeline-right-column">
                            <div className="header-texture"/>
                        </div>




                    </div>

                ))
            }

            {/*{ orderedPosts.map((p, i) => (
                <p key={i}>{p.text}</p>
            ))}*/}

            {/*<div className="social-item-header">

                <div className="flex-row align center">
                    <img
                        className="profile-image"
                        src={twu.profile_image_url}
                        // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                        />

                    <p>{twu.username}</p>
                </div>
                <div style={{marginLeft: 10}}>

                    <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" } passClick={toggleOptionsMenu}/>
                </div>
            </div>*/}


{/*
            <div className="social-item-posts">
                { orderedPosts.map((p, i) => (
                    <TwitterPost key={i} post={p}/>
                ))}
            </div>*/}

            {/*{ twid == twu.twid && (
                <div className="active-green-circle"/>
            )}*/}
        </div>
    )
}
