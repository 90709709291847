import React, { useEffect, useState } from "react";

import { useSocial } from "../../hooks/useSocial";
// import { useDex } from "../../hooks/useDex";

import toast from 'react-simple-toasts';

import ReactTooltip from 'react-tooltip';

import classNames from "classnames";

import {ReactComponent as RetweetIcon} from '../../icons/svgs/retweet.svg';

import { addCommas, getPosition } from "../../utils/tools";
import { formatCurrencyScale } from "../../utils/currency";

var parse = require('html-react-parser');

export function TwitterMetrics({ metrics }) {


    // const { fullscreen, toggleFullscreen, modalVisible, handleModalVisible, qrModal, handleQrModal, handleQrurl, handleModalTitle, handleCollectionModal } = useCore();


    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );


    const handleClick = () => {
        // passClick(nftp.address);
    }

    const toggleView = (v) => {
        // handleView(v);
    }


    return (

        <div className="data-row social-metrics" style={{alignItems: "center",padding:4}}>
            {/*<div style={{opacity:.64}} className="svg-icon">
                <NodesIcon/>
            </div>*/}
            <div style={{padding:'0 7px'}} className="data-column align center">
                <div style={{padding:'4px 7px 0 7px',paddingBottom:0}} className="data-row align center">
                    <div className="icon-button-users"/>
                    <div style={{display:"flex",flexDirection:"row",alignItems:"center"}} className="data-value">
                        { metrics.users !== undefined && (
                            <div style={{marginRight:7 }} className="count-circle">
                                {metrics.users.length}
                            </div>
                        )}

                        {parse(formatCurrencyScale(metrics.userReach))}

                    </div>
                </div>
                { metrics.retweets !== undefined && (
                    <div style={{paddingTop:3,paddingBottom:0}} className="data-row align center">
                        <div className="svg-icon">
                            <RetweetIcon/>
                        </div>
                        <div style={{paddingLeft:5,marginTop:4}} className="data-value">
                            {addCommas(metrics.retweets)}

                        </div>
                    </div>
                )}

                {/*{ metrics.imporessions !== undefined && metrics.impressions > 0 && (*/}
                    <div style={{paddingTop:3,marginLeft:5,paddingBottom:0}} className="data-row align center">
                        <div style={{opacity:.77}} className="icon-button-eye"/>
                        <div style={{paddingLeft:1,}} className="data-value">
                            { metrics.impressions < 1000 ? (
                                <>
                                    {metrics.impressions}
                                </>
                            ):(
                                <>
                                    {parse(formatCurrencyScale(metrics.impressions))}
                                </>
                            )}


                        </div>
                    </div>
                {/*)}*/}


            </div>

        </div>
    )
}
