import React, { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import web3 from 'web3';

import { useAuth } from "../../hooks/useAuth";
import { useMarket } from "../../hooks/useMarket";
import { useCore } from "../../hooks/useCore";

import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";
import { CoinList } from "../../components/CoinList";
import { MarketChart } from "../../components/MarketChart";
// import { SwapCurrency } from "../../components/SwapCurrency";

// import SplitPane from "react-split-pane";

export function Dashboard() {
    let navigate = useNavigate();
    let { loading, isAuthenticated, fetchTwitterAuth } = useAuth();
    let { coinCurrent } = useMarket();
    let { windowDimensions } = useCore();

    const [ bottomSize, setBottomSize ] = useState("50%");
    const { active, account, library, activate, deactivate } = useWeb3React();

    const toggleBtmHeight = (newSize) => {
        setBottomSize(newSize + "px");
    }



    // window.screen.width
    // window.screen.height


    return (
        <Layout>
            <Helmet><title>0xDex :: Dashboard</title>
            </Helmet>



            {/*<SplitPane
              split="horizontal"
              defaultSize="50%"
              onChange={size => toggleBtmHeight(size)}
            >
                <MarketChart candles={coinCurrent.candles == undefined ? [] : coinCurrent.candles }/>

                 <SwapCurrency/>
                <CoinList height={bottomSize}/>
            </SplitPane>*/}

        </Layout>
    );
}
