import React, { useState, useEffect } from "react";

export function InputTextArea({ title, value, placeholder = null, onChange, disabled, resize=true, maxLength=null }) {
    const [content, setContent] = useState(value || "");


    useEffect(() => {
        setContent(value)
    }, [value]);

    function handleChange(e) {
        setContent(e.target.value);
        onChange(e.target.value);
    }

    return (
        <div className="input-group">
            <label>{title}</label>
            <textarea
                style={{resize: resize ? resize : "none"}}
                value={content}
                className="outline"
                placeholder={placeholder}
                type="text"
                disabled={disabled}
                onChange={handleChange}
                maxlength={maxLength}
            />
        </div>
    );
}
