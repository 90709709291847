import React, { useEffect } from "react";

import { Notification } from "./Notification";

import classNames from "classnames";

import { useCore } from "../../hooks/useCore";


export function Notifications() {

    let { notifications, showNotifications } = useCore();


    // useEffect(() => {
    //
    //
    // }, [notifications]);


    const notificationClick = (n) => {
        console.log('close notification')
    }

    return (
        <div style={ showNotifications ? {} : {right:-300} } className="notifications-container">

            {notifications.map((n, i) => (
                <Notification n={n} key={n.id}/>
            ))}

        </div>
    )

}
