import React from 'react';

export function YoutubeEmbed({ videoId, title="YouTube" }) {

    return (
        <div className="video-responsive">
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoId}&rel=0`}
                title={`${title}`}
                allow="accelerometer; autoplay; clipboard-write; picture-in-picture"
                frameBorder="0"
                allowFullScreen
            ></iframe>
        </div>
    );
};
