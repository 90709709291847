import React, { useEffect, useState } from "react";
// import { useNavigate, NavLink } from "react-router-dom";


import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useCore } from "../../hooks/useCore";
import { useNavigator } from "../../hooks/useNavigator";
import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";
import { TickerBlock } from "../../components/Dex/TickerBlock";

import { InputToggle } from "../../components/Input/InputToggle";
import { TokenList } from "../../components/Dex/TokenList";

import { formatCurrency, condenseZerosTitle } from "../../utils/currency";

import { replaceSubDigits } from "../../utils/tools";

import useSocketContext from '../../hooks/useSocketContext';

var parse = require('html-react-parser');

export function TokenDashboard() {

    let { contractAddress, handleContractAddress, screens } = useNavigator();

    let { windowDimensions, handleSidebarRight } = useCore();

    const [ tokenView, setTokenView ] = useState("list");

    const {
        tokenPair,
        newPairs,
        handleTokenPair,
        fetchTokenPair,
        loading
    } = useDex();

    const [ tickerBlock, setTickerBlock ] = useState(false);

    const [ dexChartVisible, setDexChartVisible ] = useState(true);

    useEffect(() => {
        if(screens[1] == undefined || !screens[1].length){
            if(tokenPair){
                handleTokenPair(null);
            }
        }

        if(screens[1] == "ethereum" && screens[2] !== undefined){
            if(!tokenPair || tokenPair.caPair !== screens[2]){
                handleTokenPair(null);
                handleSidebarRight(true);
                console.log('load now', screens[2])
                fetchTokenPair(screens[2]);
            }
        }
    }, [ screens ]);



    return (
        <>


            {/*<Helmet>

            </Helmet>*/}

            { tickerBlock && (screens[1] !== undefined && !screens[1].length) && (

                <TickerBlock stylePass={{position:'relative'}}/>

            )}




            {tokenPair && tokenView == "chart" && (

                <>
                    <div
                    className="dex-chart"
                    style={{
                        height: tickerBlock && !tokenPair ? 'calc( 100% - 320px )' : 'calc( 100% - 6px )',
                        display: dexChartVisible ? 'flex' : 'none'}}
                    >
                        {tokenPair && tokenPair.data !== undefined && (
                            <iframe style={{height: 'calc( 100% - 6px )'}}
                            src={`https://dexscreener.com/${tokenPair.data.chainId}/${tokenPair.data.pairAddress}?embed=1&theme=dark&info=0`}/>
                        )}

                    </div>

                    <div className="dex-footer">
                        {/*<p>tracked by dexscreener</p>*/}

                        <div style={{width: 18,height: 18,opacity: .5,marginLeft: 5}} className="dex-icon">
                            <img style={{maxWidth:'100%',maxHeight:'100%'}} src={require(`../../icons/data/logo_dexscreener_mark.png`)}/>
                        </div>

                    </div>
                </>
            )}



            { newPairs && tokenView == "list" && (
                <TokenList tokens={newPairs}/>
            )}




        </>
    );
}
