import React, { useEffect, useState } from "react";
// import { useNavigate, NavLink } from "react-router-dom";


import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";

import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";

import { InputTextField } from "../../components/Input/InputTextField";
import { InputToggle } from "../../components/Input/InputToggle";
import { InputTabs} from "../../components/Input/InputTabs";
import { InputSelect} from "../../components/Input/InputSelect";
import { InputCodeEditor } from "../../components/Input/InputCodeEditor";
import { InputColorPick } from "../../components/Input/InputColorPick";
import { TokenList } from "../../components/Dex/TokenList";
import Blockies from 'react-blockies';
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js';
import { ContractAddressToolbar } from "../../components/Dex/ContractAddressToolbar";

import { sfx } from "../../utils/data";


import useSocketContext from '../../hooks/useSocketContext';

import classNames from "classnames";
import toast from 'react-simple-toasts';
import { HomeMeta } from "../../components/Layout/HomeMeta";

export function Admin() {
  
    const { tokenPair, newPairs, fetchAdminTokenLists, adminTokenLists, homeMeta, welcomeMessage } = useDex();
    const { user, handleUser, adminWallet, walletAddress, profile, handleProfile, handleUpdateProfile, featuredMetaList, featuredMetaLimit, showFeaturedToken, updateAdminSettings } = useWallet();
    const { handlePlaySound, sidebarLeft } = useCore();

    const [apeMode, setApeMode] = useState(false);
    const [adminSettings, setAdminSettings] = useState(null);

    useEffect(() => {
        if(user){
            fetchAdminTokenLists();
        }

    }, [ user ]);

    useEffect(() => {
        
        setAdminSettings({
            featuredMetaList,
            featuredMetaLimit,
            showFeaturedToken,
            welcomeMessage
        })

    }, [ adminTokenLists ]);

    const updateAdmin = async () => {

        console.log(adminSettings);

        let as = {
            ...adminSettings,
            isDirty: false
        }

        updateAdminSettings(as);

        setAdminSettings(as);
        // let p = {
        //     _id: user._id,
        //     ...profile,
        // }
        // if(p.walletAddressSolana?.length < 44){
        //     p.walletAddressSolana = "";
        //     handleProfile(p);
        //     if(p.walletAddressSolana?.length > 0){
        //         toast("Invalid wallet address");
        //     }
            
        // }
        
        

        // handleUpdateProfile(p);
        toast("Admin settings saved");

    }


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          // handleSubmit();
        }
    }

    const toggleShowFeaturedToken = () => {
        let aSettings = {
            ...adminSettings,
            showFeaturedToken: !adminSettings.showFeaturedToken,
            isDirty: true
        }
       
        setAdminSettings(aSettings)
    }

    const onMetaListChange = (v) => {

        let al = null;
       
        for(var i=0;i<adminTokenLists.length;i++){
            console.log(adminTokenLists[i].slug)
            if(adminTokenLists[i].slug == v){
                al = adminTokenLists[i];
            }
        }
		let aSettings = {
            ...adminSettings,
            featuredMetaList: v == "null" ? null : v,
            isDirty: true
        }
       
        setAdminSettings(aSettings)
	}

    const onMetaLimitChange = (v) => {

       
		let aSettings = {
            ...adminSettings,
            featuredMetaLimit: Number(v),
            isDirty: true
        }
        console.log(aSettings)
        setAdminSettings(aSettings)
	}

    const changeWelcomeMessage = (v) => {

       
		let aSettings = {
            ...adminSettings,
            welcomeMessage: v,
            isDirty: true
        }
        console.log(aSettings)
        setAdminSettings(aSettings)
	}


    const mListstemp = adminTokenLists.map((l, i) => {
        return `${l.name} (${l.tokens.length})`;
    });

    const mListsValuestemp = adminTokenLists.map((l, i) => {
        return l._id;
    });

    let mLists = [
        ":: none ::",
		...mListstemp
		
	];

	let mListsValues = [
        'null',
		...mListsValuestemp
		
	]

    const lstart = 3;
    const lend = 25;

// Create an array with values from start to end
    const mLimits = Array.from({ length: lend - lstart + 1 }, (v, i) => i + lstart);

  

    return (
        <Layout>
            <div className="profile-panel">
            {/*<div className={classNames("profile-panel", !sidebarLeft && "collapsed")}>*/}
                <Helmet><title>0xDΞX : Admin</title>
                </Helmet>
                <div className="flex-row">
                    <div style={{minWidth:444,minHeight:'100vh'}} className="profile-container">
                        


                        {/*<div className="input-switch-group">
                            <label>Platform</label>
                            <InputTabs
                                onChange={handleProfileUpdate("theme")}
                                // value={profile.theme}
                                tabs={['dark', 'light']}
                                tabsValues={['dark', 'light']}
                            />


                        </div>*/}

                    
                      
                        { adminSettings ? (
                            <>

                                <div className="input-switch-group">
                                    <div className="flex-row full justify space-between align center">
                                        <h2>Admin</h2>
                                        <button onClick={updateAdmin} style={{minWidth: 120}} disabled={!adminSettings.isDirty}>save</button>
                                    </div>
    
                                    
                                </div>
    
                                <InputSelect
                                    title={'Featured Meta List'}
                                    onChange={onMetaListChange}
                                    value={adminSettings.featuredMetaList}
                                    options={mLists}
                                    optionsValues={mListsValues}
                                    
                                    addClass={`button-icon-select small`}
                                    addStyleInputGroup={{padding:"0 8px"}}
                                    
                                />
    
    
                                <InputSelect
                                    title={'Featured Meta Limit'}
                                    onChange={onMetaLimitChange}
                                    value={adminSettings.featuredMetaLimit}
                                    options={mLimits}
                                    optionsValues={mLimits}
                                    
                                    addClass={`button-icon-select small`}
                                    addStyleInputGroup={{padding:"0 8px"}}
                                    
                                />


                                { homeMeta.fmp?.name ? (
                                    <>
                                        <p>{homeMeta.fmp.name}</p>
                                    </>
                                ):null}

                               
                                <InputToggle
                                    title="Featured Token"
                                    value={adminSettings.showFeaturedToken}
                                    onChange={toggleShowFeaturedToken}/>   

                                <div className="flex-row" style={{height:11}}/>

                                <InputCodeEditor
                                    title="Welcome message"
                                    // value="welcome"
                                    value={adminSettings.welcomeMessage}
                                    onChange={changeWelcomeMessage}
                                />       
                                
    
    
    
                            </>
                        ):null}
                       






                      
                    </div>


                </div>




            </div>


            

        </Layout>
    );
}
