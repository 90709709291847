import React, { useState, useEffect } from "react";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "prismjs/themes/prism-dark.css";  // Optional: You can choose a different theme if desired

export function InputCodeEditor({ title, value, placeholder = "", onChange, disabled = false, maxLength = null }) {
    const [content, setContent] = useState(value || "");

    useEffect(() => {
        setContent(value);
    }, [value]);

    function handleChange(newValue) {
        if (maxLength && newValue.length > maxLength) return;
        setContent(newValue);
        onChange(newValue);
    }

    // Syntax highlighting function using Prism.js
    const highlightWithPrism = (code) => Prism.highlight(code, Prism.languages.javascript, "javascript");

    const getLineNumbers = () => {
        const lines = content.split("\n").length;
        return Array.from({ length: lines }, (_, i) => i + 1).join("\n");
    };

    return (
        <div className="input-group">
            <label>{title}</label>
            <div className="editor-container">
                <div className="line-numbers">
                    <pre>{getLineNumbers()}</pre>
                </div>
                <Editor
                    value={content}
                    onValueChange={handleChange}
                    highlight={highlightWithPrism}
                    padding={10}
                    placeholder={placeholder}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14,
                        backgroundColor: "rgba(7, 17, 21, 0.44)",
                        borderRadius: "5px",
                        border: "1px solid rgba(35, 45, 52, 0.3529411765)",
                        width:'100%',
                        outline: "none",
                        cursor: disabled ? "not-allowed" : "text",
                        opacity: disabled ? 0.5 : 1,
                        minHeight: "150px",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                    }}
                    disabled={disabled}
                />
            </div>
        </div>
            
    
    );
}
