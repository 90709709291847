import React from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";

import { addCommas } from "../../utils/tools";

import classNames from "classnames";

var parse = require('html-react-parser');

export function TokenDataTabs({ values, valuesFormat, labels, subImages=[], subImageStyle=null, addClass, addStyle, bookend }) {

    return (
        <div style={ addStyle ? addStyle : {} } className={classNames("data-row tabs bg flex-row justify space-around", addClass && addClass, bookend && "bookend")}>
            { values.map(( v, i) => (
                <div key={i} className="data-item" style={{minWidth: `${(100/values.length)}%`}} >
                    
                    {subImages && subImages[i] !== undefined && subImages[i]  ? (
                        <div style={subImageStyle ? subImageStyle : {position:"absolute",width:'17px',right:3,top:3}} className="data-tab-image-container">
                            <div className="data-tab-image">
                                { subImages[i].indexOf('icon-') > -1 || subImages[i].indexOf('icon-button') > -1 ? (
                                    <div className={`${subImages[i]}`}></div>
                                ):(
                                    <img src={subImages[i]}/>
                                )}
                                
                                
                            </div>
                        </div>
                    ):null}

                    { labels[i] !== undefined ? (
                        <>
                            { labels[i].indexOf('icon-') > -1 || labels[i].indexOf('icon-button') > -1 ? (
                                <div style={{margin:0,height:18}} className={`${labels[i]}`}></div>
                            ):(
                                <div className="data-title">{labels[i]}</div>
                            )}
                        </>
                        
                    ):null}

                    <div className="data-value percent">
                        { valuesFormat[i] == "percent" ? (
                            <>
                                {parse(formatPercent(String(values[i])))}
                            </>
                        ) : valuesFormat[i] == "currency" ? (
                            <>
                                {parse(condenseZeros(formatCurrency(values[i], "USD")))}
                            </>
                        ) : valuesFormat[i] == "currencymin" ? (
                            <>
                                <span className="small">$</span>{parse(formatCurrencyScale(v))}

                            </>
                        ) : valuesFormat[i] == "numbermin" ? (
                            <>
                                {parse(formatNumberScale(v))}
                            </>
                        ) : (
                            <>
                                {addCommas(values[i])}
                            </>
                        )}
                    </div>


                </div>
            ))}
        </div>
    );
}
