import React, { useEffect, useState } from "react";

import { DexHeader } from "../Dex/DexHeader";
import { DexToolbar } from "../Dex/DexToolbar";
import { IconActionButton } from "../IconActionButton";

import { TwitterTag } from "../Social/TwitterTag";
import { SwapIcon } from "../Dex/SwapIcon";
import { TokenSymbolIcon } from "../Dex/TokenSymbolIcon";
import { useCore } from "../../hooks/useCore";

import { useNavigator } from "../../hooks/useNavigator";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";


import toast from 'react-simple-toasts';

import ReactTooltip from 'react-tooltip';

import classNames from "classnames";


export function PageHeader({ addClass, disabled=false }) {

    const navigator = useNavigator();
    let { contractAddress, handleContractAddress, screens } = useNavigator();
    let { handleHealthCheck,
        dexChartVisible,
        handleDexChartVisible,
        handleSwapVisible,
        apeSettings,
        token,
        loading,
        dexView,
        editFilters,
        handleEditFilters,
        tokenFilters,
    } = useDex();

    const {
        fullscreen,
        toggleFullscreen,
        modalVisible,
        handleModalVisible,
        qrModal,
        handleQrModal,
        handleQrurl,
        handleModalTitle,
        handleCollectionModal,
        sidebarRight,
        handleSidebarRight
    } = useCore();

    const [copied, setCopied] = useState(false);



    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );

    const passTagClick = (e, tag) => {
        console.log(e.target, tag);
    }

    const toggleSidebarRight = () => {
        handleSidebarRight(!sidebarRight);
    }

    let tag = !token || token.symbol == undefined ? '--' : token.symbol.charAt(0) == '$' ? token.symbol : `$${token.symbol}`;

    const pairData = token && token.pair?.data !== undefined ? { ...token.pair?.data } : null;


    return (
        <div className="page-header-wrapper">
            <div className="page-header">
                <div className="flex-row align center">
                    { token && !sidebarRight && pairData && pairData.baseToken?.address ? (
                        <div className="data-panel data-item data-row section-header" style={{justifyContent:'space-between',alignItems:'center',borderRadius:0,padding:'8px 13px',}}>
                            <div style={{fontSize:11,fontWeight:'bold'}} className="data-value">

                                {!sidebarRight ? (
                                    <TokenSymbolIcon
                                        token={token}
                                        ca={pairData.baseToken.address}
                                        symbol={pairData ? pairData.baseToken.symbol : token.symbol}
                                        name={pairData ? pairData.baseToken.name : token.name}
                                        // includeQuote={true}
                                        addClass="minimal"
                                    />
                                ):(
                                    <>
                                        {pairData.baseToken.symbol} <span className="mute"> /</span> {pairData.quoteToken.symbol}

                                        {pairData.labels !== undefined && pairData.labels.length > 0 && (
                                            <span className="data-label" style={{marginLeft:7}}>
                                                {pairData.labels[0]}
                                            </span>
                                        )}
                                    </>
                                )}

                                


                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:22,marginRight:7}}>
                                <div data-tip={pairData.dexId} className="svg-icon raw swap-small grey">
                                    <SwapIcon swap={pairData.dexId}/>
                                </div>
                                <img data-tip={pairData.chainId} style={{marginLeft:5}} className="chain-icon" src={require(`../../icons/logo_chain_${pairData.chainId}.png`)}/>
                            </div>

                            { !sidebarRight && (
                                <IconActionButton passStyle={{position:"relative",background:'transparent'}} type="arrow-right"  passClick={toggleSidebarRight}/>
                            )}

                        </div>

                    ):null}







                    {/* <div className="coin-links">
                        <div style={{maxWidth:'initial'}} onClick={() => handleEditFilters(!editFilters)} className={classNames("coin-link", editFilters && "active")}>
                            <div className="icon-button-filter"/>
                            { tokenFilters && Object.keys(tokenFilters).length ? (
                                <div style={{marginLeft:7}} className="count-circle">{Object.keys(tokenFilters).length}</div>
                            ):null}
                        </div>
                    </div> */}


                    {(token) && (
                        <div style={{marginLeft:7}} className="flex-row align center">
                            {/*<TwitterTag
                            // key={`${keyt}`}
                            // post={post}
                            tag={tag}
                            // tagRoll={tagRoll}
                            passClick={passTagClick}
                            // passOnMouseEnter={onRollOver}
                            // passOnMouseLeave={onRollOut}
                            />
*/}
                            <DexToolbar addStyle={{marginLeft:0}}/>
                        </div>
                    )}
                </div>



                {(token || loading.dex) && (
                    <DexHeader/>
                )}

            </div>
        </div>
    )
}
