import React, { useState, useEffect, useContext, createContext } from "react";

import { useWeb3React } from '@web3-react/core';

import { debounce, find } from "lodash";
import { client } from "../../services/api";
import { client_debank } from "../../services/api_debank";
import { client_opensea } from "../../services/api_opensea";


// import { useAuth } from "../useAuth";
import { chains, mockPortfolio, walletWhiteList, tgSnipers, adminWalletList } from "../../utils/data";
import { zeroxdexAddress, getTimeDiff, stableTokens } from "../../utils/tools";
import { ethers } from "ethers";
// import { Web3Provider } from 'ethers/providers';
// import { getDefaultProvider } from 'ethers/providers';

import { nftProjects } from "../../services/nfts";

import { useCore } from "../useCore";

import { useCookies } from 'react-cookie';

import toast from 'react-simple-toasts';

// import { CoinbaseWallet, WalletConnect, Injected } from "../../utils/connector";
// import Web3 from 'web3/dist/web3.min.js';
import { Network, Alchemy } from "alchemy-sdk";
import { utils } from 'ethers';

// const {
//
//     Provider,
//     Web3Provider,
//     WebSocketProvider,
//     JsonRpcSigner,
//     getDefaultProvider,
//
// } = require("@ethersproject/providers");

import { providers } from 'ethers';

import {
    fetchBaseBalance,
    fetchSOLBalance,
    fetchSolanaTokenBalance,
    fetchBaseTokenBalance,
    stableTokensEth,
    stableTokensBase,
    stableTokensSol
} from "../../services/defiTools";

const { detect } = require('detect-browser');




const {
    BigNumber,
    FixedFormat,
    FixedNumber,
    formatFixed,
    parseFixed,
    BigNumberish
} = require("@ethersproject/bignumber");



var parse = require('html-react-parser');
// import { supportedFileExtensions } from "../../utils/validations";

const walletContext = createContext();

function useWalletProvider(token) {

    let { newNotification,
        handleWalletModalVisible,
        handleNews,
        news,
        calls, 
        handleCalls,
        handleEthTrending,
        handleEthTrendingUpdated,
        handlePlaySound,
        handleSoundVolume
    } = useCore();
 
    const [inApp, setInApp] = useState(false);

    const [walletInit, setWalletInit] = useState(false);
    const [walletAddress, setWalletAddress] = useState(null);
    const [adminWallet, setAdminWallet] = useState(false);
    const [accessAllowed, setAccessAllowed] = useState(true);
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);

    const [usernameUnique, setUsernameUnique] = useState(false);
    const [usernameSearched, setUsernameSearched] = useState(false);

    const [walletHistory, setWalletHistory ] = useState([]);

    const [block, setBlock] = useState(null);
    const [chainName, setChainName] = useState(null);
    const [chainNameTemp, setChainNameTemp] = useState(null);
    const [chainId, setChainId] = useState(null);
    const [chainRpcUrls, setChainRpcUrls] = useState(null);

    const [ethGasPrice, setEthGasPrice] = useState(0);
    const [ethPrice, setEthPrice] = useState(0);
    const [btcPrice, setBtcPrice] = useState(0);
    const [solPrice, setSolPrice] = useState(0);
    const [ethData, setEthData] = useState(0);
    const [btcData, setBtcData] = useState(0);
    const [solData, setSolData] = useState(0);

    const [featuredMetaList, setFeaturedMetaList] = useState(null);
    const [featuredMetaLimit, setFeaturedMetaLimit] = useState(9);
    const [showFeaturedToken, setShowFeaturedToken] = useState(false);

    const [balanceEth, setBalanceEth ] = useState(0);
    const [balanceEthBase, setBalanceEthBase ] = useState(0);
    const [balances, setBalances] = useState({});
    const [balancesAlchemy, setBalancesAlchemy] = useState({});
    const [balancesNative, setBalancesNative] = useState({
        "0x1": 0,
        "0x2105": 0,
        "sol": 0
    })
    const [zeroXBalance, setZeroXBalance] = useState(null);
    const [zeroXPercent, setZeroXPercent] = useState(0);
    const [whiteListed, setWhiteListed] = useState(false);
    const [nativeTokenAddress, setNativeTokenAddress] = useState("0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    const [transactions, setTransactions] = useState({});
    const [transactionsMeta, setTransactionsMeta] = useState({});
    const [nfts, setNfts] = useState({loaded:false});
    const [nft, setNft] = useState(null);
    const [nftProject, setNftProject] = useState(null);

    const [nftAssets, setNftAssets] = useState(null);

    const [checkingForNft, setCheckingForNft] = useState(false);
    const [assetCheck, setAssetCheck] = useState(null);

    const [chainTotalBalances, setChainTotalBalances] = useState({});

    const [loading, setLoading] = useState(false);

    const [loadingBalances, setLoadingBalances] = useState({
        eth: false,
        bsc: false,
        polygon: false,
        fantom: false,
        avalanche: false,
        goerli: false,
    });

    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [signedMessage, setSignedMessage] = useState(null);
    const [ethListeners, setEthListeners] = useState(false);

  // useEffect(() => {
  //   async function connectToProvider() {
  //     try {
  //       // Connect to MetaMask
  //       const provider = new ethers.providers.Web3Provider(window.ethereum);
  //       await provider.getNetwork();
  //       setProvider(provider);
  //       setSigner(provider.getSigner());
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }
  //   connectToProvider();
  // }, []);

    const [currentUser, setCurrentUser] = useState(null);
    const [moralisInit, setMoralisInit] = useState(false);

    const [cookies, setCookie] = useCookies(['zeroxdex']);

    const chainNames = {
        "1":"eth",
        "56":"bsc",
        "137":"polygon",
        "250":"fantom",
        "43114":"avalanche"
    }

    const alchemyEthSettings = {
        apiKey: process.env.REACT_APP_ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
        network: Network.ETH_MAINNET, // Replace with your network.
    };

    const alchemyEth = new Alchemy(alchemyEthSettings);


    const baseEthSettings = {
        apiKey: process.env.REACT_APP_ALCHEMY_API_KEY, // Replace with your Alchemy API Key.
        network: Network.ETH_BASE_MAINNET, // Replace with your network.
    };

    const alchemyBase = new Alchemy(baseEthSettings);

    useEffect(() => {

        const images = require.context('../../media/memes', false, /\.(png|jpe?g|svg)$/);

        const imageList = images.keys().map((filename) => {
            return filename.replace('./','');
        });

        // setNfts(nftProjects);

        if(inApp && !walletAddress){

            let wa = cookies.wa;
            let wt = cookies.wt;
            let sm = cookies.sm;

            if(wa !== undefined && wt !== undefined && sm !== undefined && sm && sm.length == 132){
                if(wa.indexOf('0x') > -1 && wt){
                    // console.log('signed message',sm.length,sm)
                    setSignedMessage(sm);
                    activateWallet(wt);

                }

            }else{
                setWalletInit(true);
            }

            


            // for(var key in chains){
            //     console.log(chains[key].name,`0x${Number(chains[key].id).toString(16)}`)
            // }


            //
            //
            // (function(c){
            //
            //
            //
            // })(chains);
            //




            if(window.ethereum) {

                if (typeof window.ethereum !== 'undefined' && !ethListeners) {

                    window.ethereum.on('chainChanged', (c) => {
                        

                        
                        // console.log(c);
                        // console.log(chains[c]);
                        setChainId(c);
                        
                        // console.log(chainNameTemp, chains[c].name);

                        // if(chainNameTemp){
                        //     setChainName(chainNameTemp);
                        //     // toast(`Switched to ${chainNameTemp}.`);
                        //     setChainNameTemp(null);
                        // }else{
                        //     setChainName(chains[c].name);
                        //     // toast(`Switched to ${chains[c].name}.`);
                        // }
                        
                        // window.location.reload();
                    })


                    window.ethereum.on('accountsChanged', (accounts) => {

                        let walower = accounts[0].toLowerCase();

                        // if(walletWhiteList.includes(walower)){
                        //     handleSetWalletAddress(walower);
                        //     setBalancesAlchemy({});
                        //     // fetchTokenBalances(walower);
                        // }else{

                            setSignedMessage(null);
                            setBalancesAlchemy({});
                            toast('Wallet switched.');
                            setCookie('sm','', { path: '/' });
                            setCookie('wa','', { path: '/' });
                            setCookie('wt','', { path: '/' });

                            handleSetWalletAddress(null);
                            handleAdminWallet(null);
                            handleAccessAllowed(null);
                            setWhiteListed(null);
                            setUser(null);
                            window.location.reload();
                        // }

                    });

                    setEthListeners(true);

                }
            }
        }
    }, [ inApp ]);

    useEffect(() => {
        // if(inApp){
            fetchEthData();
        // }
        const interval = setInterval(fetchEthData, 60000);
        return () => clearInterval(interval);
    }, [ inApp ]);


    useEffect(() => {

        if(walletAddress && ( !user || user.walletAddress !== walletAddress )){
            getUser();
        }

        if(walletAddress && user && !balancesAlchemy.length){
            fetchEthData();
            fetchNativeBalances(walletAddress);
            fetchTokenBalances(walletAddress);
            const browser = detect();
            let uu = {
                _id: user._id,
                browser
            }
            client.updateUser(uu);
        }

       


    }, [ walletAddress, user ]);


    async function tgSnipe(tgsniper,ta,chain){
        let tgurl = "";
        console.log(tgsniper,chain)
        if(tgsniper == "sui"){
            let tas = String(ta).substring(0,35);
            tgurl = `${tgSnipers[chain][tgsniper].tgUrl}${tas}`;
        }else if(tgsniper == "prophet"){
            tgurl = `${tgSnipers[chain][tgsniper].tgUrl}${btoa(`DEX-${ta}`)}`;
        }else{
            tgurl = `${tgSnipers[chain][tgsniper].tgUrl}${ta}`;
        }
        if(tgSnipers[chain][tgsniper].tgTail !== undefined){
            tgurl = tgurl + tgSnipers[chain][tgsniper].tgTail;
        }

        console.log(tgurl)
   
        window.open(
            tgurl,
            "_blank"
            // 'toolbar=0,status=0,width=600,height=700,left=0,top=100'
        );
    }

    async function fetchEthData(){
        
        let nps = await client.getEthData();
        let now = new Date();
        if(nps.data){
      
            setEthData(nps.data.ethPriceChange);
            setEthPrice(nps.data.ethPrice);
            setEthGasPrice(nps.data.ethGas);
            setBtcPrice(nps.data.btcPrice);
            setBtcData(nps.data.btcPriceChange);
            setSolData(nps.data.solPriceChange);
            setSolPrice(nps.data.solPrice);
            setFeaturedMetaList(nps.data.featuredMetaList);
            setFeaturedMetaLimit(nps.data.featuredMetaLimit);
            setShowFeaturedToken(nps.data.showFeaturedToken);
            // console.log('news',news, nps.data.news[0]);
            if(nps.data.news[0] !== undefined && getTimeDiff(now,nps.data.news[0].created)<1/60 ){
                // console.log('try to play sound?')
                if(user){
                    handlePlaySound(user.sfx.news);
                }

            }
            handleNews(nps.data.news);
            handleCalls(nps.data.calls);
            handleEthTrending(nps.data.ethTrending);
            handleEthTrendingUpdated(nps.data.ethTrendingUpdated);
        }

    }

    async function fetchNativeBalances(wa){

        // get native eth balance
        let nb = await alchemyEth.core.getBalance(wa);
        let ethbalance = parseInt(BigNumber.from(nb));
        ethbalance = ethbalance / 10**18
        setBalanceEth(ethbalance);

        // get native sol balance
        let balanceSol = 0;
        if(user && user.walletAddressSolana){
            balanceSol = await fetchSOLBalance(user.walletAddressSolana);
        }

        let ethbalanceBase = await fetchBaseBalance(wa);

        setBalancesNative({
            "0x1": ethbalance,
            "0x2105":  ethbalanceBase,
            "sol": balanceSol
        });

        
       
        // setAccessAllowed(true);

       

    }


    async function fetchTokenBalances(wa, ca=null){

        
        let zx = null;
        if(ca){

            let calist = ca.split(",");
            calist = [
                ...calist,
                ...stableTokensEth,
                ...stableTokensBase,
                ...stableTokensSol,
            ]
            let ba = {...balancesAlchemy};

            for(var i=0;i<calist.length;i++){
                let cad = calist[i].split(":");
                let ca = cad[0];
                let chn = cad[1];
                let data = null;
               
                if(chn == "ethereum"){
                    
                    data = await alchemyEth.core.getTokenBalances(
                        wa,
                        [`${ca}`]
                      );
                    
                    
                    let tmeta = await alchemyEth.core.getTokenMetadata(ca);
                       
                    let b = {
                        balance: data.tokenBalances[0].tokenBalance,
                        address: data.tokenBalances[0].contractAddress,
                        ...tmeta
                    };

                    ba[ca] = b;
      
                }

                if(chn == "base"){
                    let basedata = await fetchBaseTokenBalance(wa, ca);
                   
                    ba[ca] = basedata;
                  
                }

                if(chn == "solana"){
                    if(user && user.walletAddressSolana){
                        let soldata = await fetchSolanaTokenBalance(user.walletAddressSolana, ca);
                        if(soldata){
                            ba[ca] = soldata;
                        }
                        
                    }
                    
                }
                
                // console.log(data)

                
            }

            if(ba[stableTokens['base']['usdbc']] !== undefined && Number(ba[stableTokens['base']['usdbc']].balance) > 0){
                let btemp = {
                    ...ba[stableTokens['base']['usdc']]
                };
               
                btemp.balance = Number(btemp.balance) + Number(ba[stableTokens['base']['usdbc']].balance);
                ba[stableTokens['base']['usdc']] = {
                    ...btemp
                }
            }

            if(user && user.mockPortfolio){
                ba = {
                    ...ba,
                    ...mockPortfolio
                }
            }

            setBalancesAlchemy(ba);

            if(ba[zeroxdexAddress] !== undefined){
                setZeroXBalance(ba[zeroxdexAddress]);
                zx = ba[zeroxdexAddress];
            }

        }else{

            // let tempb = {...balancesAlchemy};

            // let t = await alchemyEth.core.getTokenBalances(wa);  // "erc20" or "DEFAULT_TOKENS"

            // let tb = [...t.tokenBalances];
            // for(var i=0;i<tb.length;i++){
            //     tempb[tb[i].contractAddress] = {
            //         balance: tb[i].tokenBalance,
            //         address: tb[i].contractAddress,
            //     }
            // }

            // tempb = {
            //     ...tempb,
            //     // ...mockPortfolio
            // }
            // console.log(tempb);
            // setBalancesAlchemy(tempb);

            // if(tempb[zeroxdexAddress] !== undefined){
            //     setZeroXBalance(tempb[zeroxdexAddress]);
            //     zx = tempb[zeroxdexAddress];
            // }

        }


        // console.log('zero x balance', zx);
        // setAccessAllowed(true);

        fetchNativeBalances(walletAddress);

    }


    async function checkClipboardContents(){
        navigator.clipboard.readText().then((clipText) => {
            // console.log('clip text')
            // console.log(clipText);
        })
    }

    async function activateWallet(type) {

		try {

            if (typeof window.ethereum !== 'undefined') {

                try {


                    const cId = await window.ethereum.request({ method: 'eth_chainId' });

                    const accounts = await window.ethereum.request({
                        method: "eth_requestAccounts"
                    });

                    // let isUnlocked = window.ethereum._metamask.isUnlocked();

                    const prov = new ethers.providers.Web3Provider(window.ethereum);

                    // const prov = new providers.Web3Provider(window.ethereum);
                    // await prov.enable();
                    let pn = await prov.getNetwork();
                    const s = prov.getSigner();

                    let smc = cookies.sm;

                    const address = await s.getAddress();

                    let zb = 0;
                    let zp = 0;

                    try{
                        const data = await alchemyEth.core.getTokenBalances(
                            address,
                            [`${zeroxdexAddress}`]
                        );
                        zb = parseInt(data.tokenBalances[0].tokenBalance) / (10**18);
                        zp = (zb/1000000000) * 100;
                    }catch(e){
                        zb = 0;
                        zp = 0;
                    }
                    

                    

                    setZeroXPercent(zp);
                    setZeroXBalance(zb);

                    // console.log(prov, s, address);
                    let wlisted = null;
                    
                    let awl = process.env.REACT_APP_ALLOW_WHITELIST !== undefined && process.env.REACT_APP_ALLOW_WHITELIST ? true : false;
               
                    if(awl){
                        wlisted = walletWhiteList.includes(address);
                    }

                    if(!wlisted){
                        wlisted = adminWalletList.includes(address);
                    }
                    
                    setWhiteListed(wlisted);
                    
                    let mp = Number(process.env.REACT_APP_MINIMUM_PERCENT);

                    // console.log(wlisted, zp, mp)

                    if( wlisted || zp > mp ){

                        let me = `Welcome to 0xdex!\n
By signing this message, you accept the 0xDex Terms of Service at (https://www.0xdex.ai/tos). We are big believers in protecting your data, assets and privacy.

Wallet Address: ${address}`;

                        // Message:
                        // Welcome to OpenSea!
                        //
                        // Click to sign in and accept the OpenSea Terms of Service (https://opensea.io/tos) and Privacy Policy (https://opensea.io/privacy).
                        //
                        // This request will not trigger a blockchain transaction or cost any gas fees.
                        //
                        // Your authentication status will reset after 24 hours.
                        //
                        // Wallet address:
                        // 0x0780e25f8e3642a32f23bcbfb6ffe2a1035eedba
                        //
                        // Nonce:
                        // be6ce2cf-a7b6-4954-a0b9-d9f7976b2e4b


                        try{

                            // console.log(signedMessage, smc, cookies);
                            if(!signedMessage && (!smc || smc.length !== 132)){
                                let sm = await s.signMessage(me);

                                console.log('here is the s message',sm)
                                setSignedMessage(sm);

                                setCookie('sm',sm, { path: '/' });
                            }

                            if(cId){
                            setChainId(cId);
                            }
                            setProvider(prov);

                            setCookie('wt',type, { path: '/' });

                            let admin = adminWalletList.includes(address);
                            handleAdminWallet(admin);

                            toast('Welcome to 0xdex');

                            handleSetWalletAddress(address);

                            setWalletInit(true);

                        }catch(e){
                            setCookie('wt',"", { path: '/' });
                            setCookie('sm',"", { path: '/' });
                            setCookie('wa',"", { path: '/' });
                            toast('Wallet not signed.');
                            setZeroXPercent(0);
                        }



                    }else{
                        // console.log('no whitelist');
                        toast('Wallet not whitelisted or holding enough tokens.');
                    }

                    handleWalletModalVisible(false);



                } catch (err) {
                    if(err.code == 4001){
                        // console.log('user rejected');
                    }
                    // console.error(err);
                }


            } else {
                toast('No wallet connected.');
                handleWalletModalVisible(false);
                // console.log("No wallet");
            }


		} catch (ex) {
            toast('Wallet connect error.');
            handleWalletModalVisible(false);
			// console.log(ex)
		}

    }

    async function deactivateWallet(type) {

		try {

            setZeroXPercent(0);
            setProvider(null);
            setSignedMessage(null);
            setSigner(null);
            setCookie('wt',null, { path: '/' });
            setCookie('wa',null, { path: '/' });
            setCookie('sm',null, { path: '/' });
            setUser(null);
            handleSetWalletAddress(null);
            handleAdminWallet(null);
            handleAccessAllowed(null);
            setWhiteListed(null);
            setChainRpcUrls(null);
            handleWalletModalVisible(false);
            toast("Wallet disconnected.");

		} catch (ex) {
			// console.log(ex)
		}

    }

    async function switchChain(cname){

        try{

            if(!window.ethereum) throw new Error("No wallet found");

            let rpcUrls = chains[cname].rpcUrls.length ? [...chains[cname].rpcUrls] : null;

            let coptions = {
                chainId: `0x${Number(chains[cname].id).toString(16)}`,
                chainName: chains[cname].name,
                nativeCurrency: {...chains[cname].nativeCurrency},
                rpcUrls: rpcUrls,
                blockExplorerUrls: chains[cname].blockExplorerUrls.length ? [...chains[cname].blockExplorerUrls] : null
            }

            setChainRpcUrls(rpcUrls,coptions);

            // console.log('switch chain init',coptions.chainName)

            setChainNameTemp(coptions.chainName);

            if(coptions.chainId == "0x1" && cname !== "flashbots" && cname !== "mevblocker"){
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: '0x1' }], // replace with the chain ID you want to switch to
                });
            }else{
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            ...coptions
                        }
                    ]
                })
            }




            // const provider = new ethers.providers.Web3Provider(window.ethereum, 'pulse', {
            //   name: 'pulse',
            //   chainId: 943,
            //   chainName: 'PulseChain Testnet',
            //   url: 'https://rpc.v4.testnet.pulsechain.com',
            // });
            // await provider.send('eth_requestAccounts', []);
            // const signer = provider.getSigner();
            //
            // const address = await signer.getAddress();
        } catch (err) {
            if(err.code == 4001){
                // setChainNameTemp(null);
            }
            // console.error(err);
        }
    }



    function handleSetWalletAddress(value) {
        setWalletAddress(value);

        if(value){
            setCookie('wa', value, { path: '/' });
        }else{
            setCookie('wa', null, { path: '/' });
        }

    }

    function handleAdminWallet(value) {
        setAdminWallet(value);
    }


    function handleNft(n){
        // console.log(n);
        setNft(n);
    }

    function handleCheckingForNft(v){
        setCheckingForNft(v);
    }

    async function getUser(){
        let d = {
            wa: walletAddress
        }
        const p = await client.getUser(d);
        setUser(p.data);
        handleProfile(p.data);
    }

    async function getUserCurrent(){
        return user;
    }



    //
    // async function fetchBalances(cname, passNativeToken=null) {
    //
    //     try{
    //
    //         let blon = {
    //             [cname]: true
    //         }
    //         let bloff = {
    //             [cname]: false
    //         }
    //         handleLoadingBalances(blon);
    //
    //         const options = {
    //           chain: cname,
    //           address: walletAddress,
    //           // to_block: "10253391",
    //         };
    //
    //         let tempbalances = {};
    //         let chn = {};
    //
    //         for(var i=0;i<chains.length;i++){
    //             if(chains[i].nameid == cname){
    //                 chn = chains[i];
    //             }
    //         }
    //
    //         console.log(chn)
    //
    //         const nb = await Moralis.Web3API.account.getNativeBalance(options);
    //         // let nb = await Moralis.Web3API.account.getNativeMetadata(options);
    //
    //         tempbalances[nativeTokenAddress] = {
    //             decimals: chn.nativeCurrency.decimals,
    //             name: chn.nativeCurrency.name,
    //             symbol: chn.nativeCurrency.symbol,
    //             token_address: chn.nativeCurrency.address,
    //             balance: nb.balance
    //         };
    //
    //         const b = await Moralis.Web3API.account.getTokenBalances(options);
    //
    //         // let c = [nativeTokenAddress];
    //
    //         for(var i=0;i<b.length;i++){
    //             tempbalances[b[i].token_address] = b[i];
    //             tempbalances[b[i].token_address].symbol = tempbalances[b[i].token_address].symbol.toLowerCase();
    //             // c.push(b[i].token_address);
    //         }
    //
    //         let ob = {...balances};
    //
    //         let synced = Date.now();
    //         console.log('sync time',cname, synced);
    //         if(balances[cname] !== undefined){
    //             ob[cname] = { ...balances[cname], ...tempbalances };
    //         }else{
    //             ob[cname] = { ...tempbalances };
    //         }
    //
    //         console.log('balances',ob);
    //
    //
    //
    //         // start getting txs
    //         const toptions = {
    //           chain: cname,
    //           address: walletAddress,
    //           from_block: "0",
    //           //offset:"0",
    //           //limit
    //         };
    //         const transactions = await Moralis.Web3API.account.getTransactions(toptions);
    //
    //         let txs = { ...transactions };
    //
    //
    //         console.log('sync time txs',cname, synced);
    //         txs[cname] = [ ...transactions.result ];
    //
    //         let txmeta = {
    //             synced,
    //             total: transactions.total
    //         }
    //         let txm = { ...transactionsMeta };
    //         txm[cname] = { ...txmeta };
    //
    //         console.log(txm);
    //         console.log('txs!',txs)
    //
    //         setTransactions(txs);
    //         setTransactionsMeta(txm);
    //
    //         setBalances(ob);
    //
    //
    //         handleLoadingBalances(bloff);
    //
    //
    //     }catch(e){
    //         console.log(e);
    //     }
    //
    // }

    // async function fetchTransactions(cname) {
    //
    //     try{
    //
    //         const options = {
    //           chain: cname,
    //           address: walletAddress,
    //           from_block: "0",
    //           //offset:"0",
    //           //limit
    //         };
    //         const transactions = await Moralis.Web3API.account.getTransactions(options);
    //
    //
    //         console.log(transactions);
    //
    //         let txs = { ...transactions };
    //
    //         let synced = Date.now();
    //         console.log('sync time txs',cname, synced);
    //         txs[cname] = [ ...transactions.result ];
    //
    //         let txmeta = {
    //             synced,
    //             total: transactions.total
    //         }
    //         let txm = { ...transactionsMeta };
    //         txm[cname] = { ...txmeta };
    //
    //         console.log(txm);
    //
    //         setTransactions(txs);
    //         setTransactionsMeta(txm);
    //
    //
    //     }catch(e){
    //         console.log(e);
    //     }
    //
    // }
    //
    //
    //
    // async function fetchToken(cname) {
    //
    //     try{
    //
    //         //0x5ec75ab6b9c096E1D97934Afe86572Fc6CF58f6D
    //
    //         const options = {
    //           chain: cname,
    //           address: walletAddress,
    //           from_block: "0",
    //           //offset:"0",
    //           //limit
    //         };
    //         const transactions = await Moralis.Web3API.account.getTransactions(options);
    //
    //
    //         console.log(transactions);
    //
    //         let txs = { ...transactions };
    //
    //         let synced = Date.now();
    //         console.log('sync time txs',cname, synced);
    //         txs[cname] = [ ...transactions.result ];
    //
    //         let txmeta = {
    //             synced,
    //             total: transactions.total
    //         }
    //         let txm = { ...transactionsMeta };
    //         txm[cname] = { ...txmeta };
    //
    //         console.log(txm);
    //
    //         setTransactions(txs);
    //         setTransactionsMeta(txm);
    //
    //
    //     }catch(e){
    //         console.log(e);
    //     }
    //
    // }
    //
    //
    // async function fetchNFTs(cname="eth", provider="moralis") {
    //     setLoading(true);
    //
    //     try{
    //
    //         let tempts = {};
    //
    //         if(provider == "moralis"){
    //
    //             const options = {
    //               chain: cname,
    //               address: walletAddress,
    //               // to_block: "10253391",
    //             };
    //
    //             const ts = await Moralis.Web3API.account.getNFTs(options);
    //             console.log(ts);
    //
    //             for(var i=0;i<ts.result.length;i++){
    //                 let t = ts.result[i];
    //                 if(tempts[t.token_address] == undefined){
    //                     let m = JSON.parse(t.metadata);
    //                     console.log('project',m)
    //                     tempts[t.token_address] = {
    //                         name: t.name,
    //                         symbol: t.symbol,
    //                         address: t.token_address,
    //                         // description: m.description,
    //                         tokens: [ {...t, metadata: JSON.parse(t.metadata) } ],
    //                     }
    //                 }else{
    //                     let meta = JSON.parse(t.metadata);
    //                     let tempt = { ...t, metadata:{...meta}};
    //                     tempts[ts.result[i].token_address].tokens.push(tempt);
    //                 }
    //             }
    //             tempts.loaded = true;
    //
    //             console.log(tempts);
    //             setNfts(tempts);
    //
    //         }
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //         //
    //         // let tempbalances = {};
    //         //
    //         // let chn = {};
    //         // for(var i=0;i<chains.length;i++){
    //         //     if(chains[i].nameid == cname){
    //         //         chn = chains[i];
    //         //     }
    //         // }
    //
    //         //
    //         // const nb = await Moralis.Web3API.account.getNativeBalance(options);
    //         //
    //         // // let nb = await Moralis.Web3API.account.getNativeMetadata(options);
    //         // console.log(nb);
    //         //
    //
    //
    //     }catch(e){
    //         console.log(e);
    //     }
    //
    //     //
    //     //
    //     // const _history = historyResponse.data || [];
    //     // console.log(_history);
    //     //
    //     // setWalletHistory(_history);
    //
    //     setLoading(false);
    // }


    async function fetchNFT(ca, ti) {
        setLoading(true);

        try{

            alchemyEth.nft.getNftMetadata(ca,ti).then(response => {
                // console.log('alchemy nft metadata', response);
            });


            let options = {
                contractAddress: ca,
                tokenId: ti
            }

            const response = await client_opensea.getAsset(options);
            const _asset = response.data || [];
            // console.log(_asset);

            setNft(_asset);

            let project = { ..._asset.collection, ca: _asset.asset_contract.address }
            setNftProject(project);

        }catch(e){
            console.log(e);
        }

        setLoading(false);
    }

    async function fetchProjectAssets(ca, check=true){
        setLoading(true);

        let data = {
            owner: walletAddress,
            asset_contract_address: ca,
            order_direction: 'desc',
            limit: 20,
            include_orders: false,
        }
        const options = {
            method: 'GET',
            headers: {Accept: 'application/json', 'X-API-KEY': 'null'},
            body: data,
        };

        const response = await client_opensea.getProjectAssets(data);
        let _assets = response.data.assets || [];
        let next = response.data.next;


        if(next){
         
            for(var i=0;i<100;i++){
             
                if(next){
                    data.cursor = next;
                    let r = await client_opensea.getProjectAssets(data);
                    let a = r.data.assets || [];
                    _assets = [..._assets, ...a];
                    next = r.data.next;
                }else{
                    break;
                }
            }

        }

        if(_assets.length){
            let stato = {
                slug: _assets[0].collection.slug
            }

            const stats = await client_opensea.getProjectStats(stato);

            _assets[0].collection.stats = stats.data.stats;

         
            setAssetCheck(true);
            setNft(_assets[0]);
            setNftAssets(_assets);
            let p = { ..._assets[0].collection, ca: _assets[0].asset_contract.address};
            setNftProject(p)

        }else{
            setAssetCheck('empty');
            setNft(null);
        }

        // if(_assets.length){
        //
        // }else{
        //
        // }

        setLoading(false);
    }

    const handleAssetCheck = (v) => {
        setAssetCheck(v);
    }

    const handleLoadingBalances = (obj) => {

        let lb = { ...loadingBalances, ...obj };
        setLoadingBalances(lb);

    }

    const handleChainTotalBalances = (cb) => {
        let cbtemp = { ...chainTotalBalances, ...cb };
        setChainTotalBalances(cbtemp);
    }

    const handleUser = (u) => {
        setUser(u);
    }

    const handleUpdateUser = (d) => {
        let u = {
            ...user,
            ...d
        }
        console.log(u);
        setUser(u);
    }
    const handleProfile = (u) => {
        setProfile(u);
        handleSoundVolume(u.sfxVolume !== undefined ? u.sfxVolume/100 : .5);
    }

    const handleAccessAllowed = (v) => {
        setAccessAllowed(v);
    }

    const handleUpdateProfile = async (p) => {
        let usersave = await client.updateUser(p);
        // setProfile({
        //     ...profile,
        //     isDirty: false,
        // });
        getUser();
    }

    const addTokenToPortfolio = async (tp) => {
        let data = {
            _id: user._id,
            walletAddress: user.walletAddress,
            type: "addToken",
            tokenid: tp._id
        }

        let update = await client.updateUser(data);

        getUser();

        toast(parse(`<span className="mute">${tp.symbol}</span> added to <span className="mute">portfolio</span>`));
        // ca, symbol, decimals  ??
    }

    const removeTokenFromPortfolio = async (tp) => {

        let data = {
            _id: user._id,
            walletAddress: user.walletAddress,
            type: "removeToken",
            tokenid: tp._id
        }

        let update = await client.updateUser(data);

        await getUser();
     
        toast(parse(`<span className="mute">${tp.symbol}</span> removed from <span className="mute">portfolio</span>`));

    }

    const hideToken = async (tp) => {
        let data = {
            _id: user._id,
            walletAddress: user.walletAddress,
            type: "hideToken",
            tokenid: tp._id
        }

        let update = await client.updateUser(data);

        let verb = user.hideTokens.includes(tp._id) ? 'unhiding' : 'hiding';

        getUser();

        toast(`${verb} ${tp.symbol.toUpperCase()}`);

    }

    const handleInApp = (v) => {
        if(v){
            document.body.classList.add('inApp');
        }else{
            document.body.classList.add('inWebsite');
        }
        setInApp(v);
    }

    const updateAdminSettings = async (ud) => {
        let uAdmin = await client.updateAdmin({
            data: {...ud}
        });
    
    }

    const handleUsernameSearchUnique = async (un) => {
        let d = {
            un: un
        }
        let unu = await client.checkIfUsernameUnique(d);
        
        if(unu.data?.exists !== undefined){
            if(unu.data.exists){
                setUsernameUnique(false);
            }else{
                setUsernameUnique(true);
            }
            setUsernameSearched(true);
           
        }
    }

    const handleUsernameSearched = async (v) => {
        setUsernameSearched(v);
    }


    return {
        block,
        ethGasPrice,
        ethPrice,
        btcPrice,
        solPrice,
        ethData,
        btcData,
        solData,
        walletInit,
        activateWallet,
        walletAddress,
        handleSetWalletAddress,
        adminWallet,
        handleAdminWallet,
        deactivateWallet,
        accessAllowed,
        handleAccessAllowed,
        balanceEth,
        balanceEthBase,
        balancesNative,
        balances,
        zeroXPercent,
        whiteListed,
        featuredMetaList, // app home meta list (object id)
        featuredMetaLimit,
        showFeaturedToken,
        user,
        handleUser,
        handleUpdateUser,
        getUser,
        getUserCurrent,
        profile,
        handleProfile,
        handleUpdateProfile,

        usernameUnique,
        usernameSearched,
        handleUsernameSearched,
        handleUsernameSearchUnique,

        updateAdminSettings,

        tgSnipe,

        // fetchBalances,
        fetchTokenBalances, // alchemy
        balancesAlchemy,
        loadingBalances,
        zeroXBalance,
        transactions,
        transactionsMeta,
        // fetchTransactions,
        nfts,
        // fetchNFTs,
        nft,
        fetchNFT,
        handleNft,
        nftProject,
        nftAssets, //opensea
        moralisInit,
        chainName,
        chainId,
        switchChain,
        nativeTokenAddress,
        handleChainTotalBalances,
        chainTotalBalances,
        checkingForNft,
        handleCheckingForNft,
        fetchProjectAssets,
        assetCheck,
        handleAssetCheck,

        addTokenToPortfolio,
        removeTokenFromPortfolio,
        hideToken,
        inApp, // used to kick off connect wallet if in app, not website
        handleInApp,
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideWallet({ token, children }) {
    const media = useWalletProvider(token);
    return (
        <walletContext.Provider value={media}>{children}</walletContext.Provider>
    );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useWallet = () => {
    return useContext(walletContext);
};
