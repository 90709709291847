import React, { useState, useEffect, useCallback, useRef} from "react";

import { TwitterPost } from "./TwitterPost";
import { IconActionButton } from "../IconActionButton";

import classNames from "classnames";

import { getTimeDisplay } from "../../utils/tools";
import { useSocial} from "../../hooks/useSocial";

import { useNavigator } from "../../hooks/useNavigator";

export function TwitterUserPanel({ twu, passTagClick, kid }) {


    const navigator = useNavigator();

    const [ scrollTop, setScrollTop ] = useState(0);
    const [ optionsMenu, setOptionsMenu ] = useState(false);
    const [ panelHeight, setPanelHeight ] = useState(0);

    const [ showPostCount, setShowPostCount ] = useState(25);

    const ref = useRef(null);

    const handleScroll = useCallback(() => {
        // console.log("scrolling")
        let sTop = ref.current.scrollTop;
        setScrollTop(sTop);

        if(sTop > (ref.current.scrollHeight-ref.current.offsetHeight-700)){
            let sc = 1*showPostCount;
            sc += 5;
            setShowPostCount(sc);
        }
    }, [ showPostCount ])

    // Attach the scroll listener to the div
    useEffect(() => {
        const div = ref.current;
        div.addEventListener("scroll", handleScroll)
    }, [handleScroll])

    // useEffect(() => {
    //     const div = ref.current;
    //     // let height = div.scrollHeight;
    //     //
    //     // setPanelHeight(height);
    // }, [])


    const handleClick = () => {
        // passClick(post);
    }


    const userClick = () => {

        navigator.push(`/social/user/${twu.username}`);
    }

    // const onRollOver = (t) => {
    //     handleTagRoll(t);
    // }
    // const onRollOut = (t) => {
    //     handleTagRoll(null);
    // }


    // const onScroll = (e) => {
    //
    // }

    let headerIndex = scrollTop < 150 ? 0 : scrollTop > 200 ? 1 : (scrollTop-150)/50;


    let orderedPosts = twu.posts.filter(p => p.tags)
    .sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
    });

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }

    return (

        <div
        ref={ref}
        //onClick={() => switchTwitterUser(twu)}
        //className={classNames('social-item', twid == twu.twid && 'active')}

        className="social-item"
        >

            <div style={{ opacity: headerIndex,  pointerEvents: headerIndex < 1 ? 'none' : 'all',display: "flex" }} className="social-item-header-micro">
                {/*<p className="time">{getTimeDisplay(twu.updated)}</p>*/}
                <div onClick={userClick} className="data-panel interactive flex-row align center">
                    <img
                        className="profile-image"
                        src={twu.profile_image_url}
                        // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                        />
                    <p>{twu.username}</p>
                </div>
                <div style={{marginLeft: 10}}>
                    <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }  passClick={toggleOptionsMenu}/>
                </div>
            </div>

            <div className="social-item-header">

                <div onClick={userClick} className="data-panel interactive flex-row align center">
                    <img
                        className="profile-image"
                        src={twu.profile_image_url}
                        // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                        />

                    <p>{twu.username}</p>
                </div>
                <div style={{marginLeft: 10}}>

                    <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" } passClick={toggleOptionsMenu}/>
                </div>
            </div>



            <div className="social-item-posts">
                { orderedPosts.map((p, i) => {
                    if(i<showPostCount){
                        return (
                            <TwitterPost key={`${kid}${i}`} post={p} passTagClick={passTagClick}/>
                        )
                    }

                })}
            </div>

            {/*{ twid == twu.twid && (
                <div className="active-green-circle"/>
            )}*/}
        </div>
    )
}
