import React, { useEffect, useState } from "react";
// import { useNavigate, NavLink } from "react-router-dom";


import { useAuth } from "../../hooks/useAuth";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";

import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";

import { InputTextField } from "../../components/Input/InputTextField";
import { InputTextArea } from "../../components/Input/InputTextArea";
import { InputToggle } from "../../components/Input/InputToggle";
import { InputTabs} from "../../components/Input/InputTabs";
import { InputSelect} from "../../components/Input/InputSelect";
import { InputSelectMulti} from "../../components/Input/InputSelectMulti";
import { InputColorPick } from "../../components/Input/InputColorPick";
import { InputColorGradientPicker } from "../../components/Input/InputColorGradientPicker";
import { TokenDataTabsInteractive } from "../../components/Dex/TokenDataTabsInteractive";
import ProfilePhotoUpload from "../../components/Input/ProfilePhotoUpload";
import { TokenList } from "../../components/Dex/TokenList";
import Blockies from 'react-blockies';
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js';
import { ContractAddressToolbar } from "../../components/Dex/ContractAddressToolbar";
import { DebounceInput } from 'react-debounce-input';

import { blockieSeed, sfx, tgSnipers, tgSnipersLogos, bgGradients, vibrantColors } from "../../utils/data";

import useSocketContext from '../../hooks/useSocketContext';

import classNames from "classnames";
import toast from 'react-simple-toasts';
import { InputSlider } from "../../components/Input/InputSlider";


var parse = require('html-react-parser');

export function Settings() {
    const socket = useSocketContext();
    const { token } = useSocial();
    const { tokenPair, newPairs } = useDex();
    const { user, handleUser, handleUpdateUser, walletAddress, profile, handleProfile, handleUpdateProfile, handleUsernameSearchUnique, usernameUnique, handleUsernameSearched, usernameSearched } = useWallet();
    const { handlePlaySound, sidebarLeft } = useCore();

    const [ sfxNames, setSfxNames ] = useState([]);
    const [ sfxValues, setSfxValues ] = useState([]);

    const [ usernameSearch, setUsernameSearch] = useState("");
    const [ usernameTemp, setUsernameTemp] = useState("");
    const [ usernameTempValid, setUsernameTempValid] = useState(false);
    const [ editUsername, setEditUsername ] = useState(false);

    const [apeMode, setApeMode] = useState(false);

    const [settingsView, setSettingsView] = useState("general");

    const [sniperOptions, setSniperOptions] = useState([]);


    useEffect(() => {
        let so = [];
        let soused = [];
        for(var key in tgSnipers){
            for(var subkey in tgSnipers[key]){
                if(!soused.includes(subkey)){
                    let o = {
                        value: subkey,
                        name: tgSnipers[key][subkey].name
                    }
                    so.push(o);
                    soused.push(subkey);
                }
            }
        }
        setSniperOptions(so);

    }, [ ]);


    useEffect(() => {
        if(user){
            let tp = {...user};
            handleProfile(tp);

            setUsernameTemp(user.username);

            let snames = [];
            let svalues = [];

            for(var key in sfx){
                snames.push(sfx[key].name);
                svalues.push(key);
            }

            setSfxNames(snames);
            setSfxValues(svalues);


        }

    }, [ user ]);

    const updateProfile = () => {
        let p = {
            _id: user._id,
            ...profile,
        }
        if(p.walletAddressSolana?.length < 44){
            p.walletAddressSolana = "";
            handleProfile(p);
            if(p.walletAddressSolana?.length > 0){
                toast(parse(`Invalid <span className="mute">wallet address</span>`));
            }
            
        }

        if(p.username?.length < 3){
            p.username = "";
            handleProfile(p);
           
            toast(parse(`Invalid <span className="mute">username</span>`));
            
            
        }
        
        

        handleUpdateProfile(p);
        
        // handleUpdateUser(p);
       
        toast(parse(`<span className="mute">portfolio</span> saved`));

    }

    const onSettingsViewClick = (v) => {
        // handleUserView(v);
        setSettingsView(v);
    }


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          // handleSubmit();
        }
    }

    const toggleApeMode = () => {
        console.log('ape it');

        setApeMode(!apeMode);
    }

    const pingTest = () => {
        socket.emit(
          'TEST_PING',
          {
            wa: 'word up',
            room: "lobby",
            // roomUrl: `${process.env.REACT_APP_URL_VIDEO}/${spaceContext.token}`,
            // domain: 'http://localhost:3000',
          }
      )
    }

    const toggleNotificationsOn = () => {
     
        handleProfile({
            ...profile,
            notificationsOn: !profile.notificationsOn,
            isDirty: true
        });
    }


    const onChangeSound = (v) => {
        
        let s = {...profile.sfx};
        for(var key in v){
            handlePlaySound(v[key]);
            s[key] = v[key];
        }



        handleProfile({
            ...profile,
            sfx: {
                ...profile.sfx,
                ...s
            },
            isDirty: true
        });
    }

    const togglePublic = () => {
     
        handleProfile({
            ...profile,
            public: !profile.public,
            isDirty: true
        });
    }

    const togglePhotoBg = () => {
     
        handleProfile({
            ...profile,
            photobg: !profile.photobg,
            isDirty: true
        });
    }

    const toggleWalletBlur = () => {
     
        handleProfile({
            ...profile,
            walletBlur: !profile.walletBlur,
            isDirty: true
        });
    }

    const toggleBalanceBlur = () => {
     
        handleProfile({
            ...profile,
            balanceBlur: !profile.balanceBlur,
            isDirty: true
        });
    }

    const toggleMockPortfolio = () => {
     
        handleProfile({
            ...profile,
            mockPortfolio: !profile.mockPortfolio,
            isDirty: true
        });
    }

    const portfolioSwatchClick = (g) => {
        let u = {...profile};
        u.colorbgportfolio = g;
        u.isDirty = true;
        handleProfile(u);
    }

    const gradientSwatchClick = (g) => {
        let u = {...profile};
        u.colorgradient = g;
        u.isDirty = true;
        handleProfile(u);
    }

    const handleColorGradientSelection = (key) => (value) => {
        let u = {...profile};

        console.log(value)
        u[key] = value;
        u.isDirty = true;
        handleProfile(u);


        // autoUpdateThemeDebounced({ [key]: value });
    };

    const handleColorSelection = (key) => (value) => {
        let u = {...profile};
        u[key] = value;
        u.isDirty = true;
        handleProfile(u);


        // autoUpdateThemeDebounced({ [key]: value });
    };

    const handleProfileUpdate = (key) => (value) => {

        // autoSaveMap({ [key]: value });
    };

    const handleZoomRangeUpdate = (value) => {
        // console.log(value);
        // let zupdate = {
        //     minZoom: value[0],
        //     maxZoom: value[1]
        // }
        // console.log(zupdate);
        // if(map){
        //     autoSaveMap(zupdate);
        // }

    }


    const onUserNameChange = (v) => {
       
        handleProfile({
            ...profile,
            username: v,
            isDirty: true
        });
    }

    const changeUsernameSearch = (e) => {

        let v = e.target.value;
        console.log(v);
        if(usernameTempValid){
            handleUsernameSearchUnique(usernameTemp);
        }
        
        // setUsernameSearch(v);

        // setUsernameUnique(true);
      
        // if(e.target.value.length > 1){
        //     searchDex(e.target.value);
        // }

    }

    const handleUsernameTemp = (v) => {

        console.log(v, validateUsername(v));
        if(validateUsername(v)){
            setUsernameTemp(v);
            if(v.length > 2){
                setUsernameTempValid(true);
            }
            
        }else{
            setUsernameTempValid(false);
        }
        // let v = e.target.value;
        // console.log(v)
        // setUsernameSearch(v);
      
        // if(e.target.value.length > 1){
        //     searchDex(e.target.value);
        // }

    }

    const saveUsername = () => {
        let p = {
            _id: profile._id,
            username: usernameTemp
        }
        handleUpdateProfile(p);
        setEditUsername(false);
    }

    const handleEditUsername = (v) => {
        setUsernameTemp(profile.username);
        setUsernameTempValid(false);
        handleUsernameSearched(false);
        setEditUsername(v);
    }

   

    const onNameChange = (v) => {
       
        handleProfile({
            ...profile,
            name: v,
            isDirty: true
        });
    }

    const changePhotoAlpha = (v) => {
       
        handleProfile({
            ...profile,
            photoalpha: v,
            isDirty: true
        });
    }

    const changePhotoBrightness = (v) => {
       
        handleProfile({
            ...profile,
            photobrightness: v,
            isDirty: true
        });
    }

    const changeSfxVolume = (v) => {
        
      

        handleProfile({
            ...profile,
            sfxVolume: v,
            isDirty: true
        });
    }

    const playSfxVolume = (v) => {
        
        let vtemp = v;
        handlePlaySound("store",vtemp);

        handleProfile({
            ...profile,
            sfxVolume: v,
            isDirty: true
        });
    }

    const onBioChange = (v) => {
       
        handleProfile({
            ...profile,
            bio: v,
            isDirty: true
        });
    }

    const onSolWalletChange = (v) => {
       
        handleProfile({
            ...profile,
            walletAddressSolana: v,
            isDirty: true
        });
    }

    const newPairChainsChange = (v) => {
       
        let p = {
            ...profile,
            newPairChains: v,
            isDirty: true
        }
      
        handleProfile({
            ...profile,
            newPairChains: v,
            isDirty: true
        });
    }

    const sniperWalletsChange = (v) => {
       
        let p = {
            ...profile,
            sniperWallets: v,
            isDirty: true
        }
      
        handleProfile({
            ...profile,
            sniperWallets: v,
            isDirty: true
        });
    }

    const showNotificationsChange = (v) => {
       
        handleProfile({
            ...profile,
            showNotifications: v,
            isDirty: true
        });
    }

    const validateSolWallet = (e) => {
  
        let re = /^[a-zA-Z0-9]*$/i;
        console.log('validate',e)
        let dataCheck = re.test(e);
        if(e.length > 44){
            return false;
        }
        return dataCheck;
    }

    const validateUsername = (e) => {
        let re = /^[a-zA-Z0-9_]*$/i;
        let dataCheck = re.test(e);
        if(e.length > 22){
            return false;
        }
        return dataCheck;
    }

    

    const validateName = (v) => {
        // let re = /^(?! )[a-zA-Z0-9āēīōūĀĒĪŌŪ_]*( ?[a-zA-Z0-9āēīōūĀĒĪŌŪ_]+)*$/i;
        let re = /^(?! )[a-zA-Z0-9āēīōūĀĒĪŌŪ_.]*( ?[a-zA-Z0-9āēīōūĀĒĪŌŪ_.]+)* ?$/i;

        let dataCheck = re.test(v);
        if(v.length > 33){
            return false;
        }
        return dataCheck;
    }

   

    return (
        <Layout>
            <div style={{paddingTop:0}} className="profile-panel">
           
             
                
                {/*<div className="input-switch-group">
                    <label>Platform</label>
                    <InputTabs
                        onChange={handleProfileUpdate("theme")}
                        // value={profile.theme}
                        tabs={['dark', 'light']}
                        tabsValues={['dark', 'light']}
                    />


                </div>*/}

                {/*<div className="input-switch-group">
                    <label>Zoom Range</label>
                    <InputRangeSlider
                        onChange={handleVolumeRangeUpdate}
                        min={0}
                        max={100}
                        step={1}
                        // value={min: map.minZoom, max: map.maxZoom}
                        value={[map.minZoom, map.maxZoom]}

                    />


                </div>*/}
                { profile ? (

                    <>

                        <div style={{marginTop:4}} className="input-switch-group">
                            <div className="flex-row full align center">
                                <h2 style={{margin:0,marginRight:22,marginLeft:16}}>Edit Profile</h2>
                                <button onClick={updateProfile} style={{minWidth: 120}} disabled={!profile.isDirty}>save{profile.isDirty}</button>
                            </div>

                            <div className="input-group">
                                {/* <label>Wallet</label> */}
                                <div className="flex-row">
                                    <div className="data-panel">
                                        <div style={{alignItems: 'center'}} className="data-row align center">
                                            <Blockies
                                                seed={walletAddress !== undefined ? walletAddress + blockieSeed : "love"}
                                                size={7}
                                                scale={5}
                                                // color="#dfe"
                                                // bgColor="#ffe"
                                                // spotColor="#abc"
                                                className="identicon"
                                            />
                                            <ContractAddressToolbar
                                            // label="pair"
                                            walletAddress={true}
                                            type="address"
                                            ca={`${walletAddress}`}/>
                                            {/* <p style={{marginLeft:7}}>{walletAddress}</p> */}
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <TokenDataTabsInteractive
                                // addClass="active"
                                title="price"
                                values={['general','wallets','notifications','theme','soundfx','code']}
                                dataTips={['general','wallets','notifications','theme','sound fx','code']}
                                buttonValues={['general','wallets','notifications','theme','soundfx','code']}
                                buttonValue={settingsView}
                                showValues={false}
                                passClick={onSettingsViewClick}
                                
                                addStyle={{minWidth:333,maxWidth: 333,marginTop:11,marginBottom:22,marginLeft:11,overflow:"hidden"}}
                                valuesFormat={['string','string','string','string']}
                                subImages={[
                                    null,
                                    require(`../../icons/news/logo_telegraph.png`),
                                    null,
                                    null,
                                    null
                                ]}
                                dataItemStyle={{padding:"13px 11px 13px"}}
                                subImageStyle={{fontSize:13,width:13,height:13,position:"absolute",right:4,top:4,opacity:.6}}
                                labels={['icon-button-settings','icon-button-tablet','icon-button-bell','icon-button-eye','icon-button-volume-2','icon-code']}
                            />

                            {/* <InputToggle
                                title="Public Profile"
                                value={profile.public}
                                onChange={togglePublic}
                                horizontal={true}
                            />       */}

                            { settingsView == "general" ? (
                                <>
                                    <div className="input-group">
                                        <ProfilePhotoUpload/>
                                    </div>

                                    
                                    <div className="input-group">
                                        { !editUsername ? (
                                            <>
                                                <label>Username</label>
                                                
                                            
                                                <div style={{minHeight:37}} className="flex-row align center">
                                                    <p><span style={{marginRight:2}} className="mute">@</span>{profile.username}</p>
                                                    <button onClick={e => handleEditUsername(true)} data-tip={`change username`} style={{maxWidth:88,margin:0,marginLeft:22}} className="small outline">edit</button>
                                                </div>
                                            </>
                                            
                                        ):(
                                            <>
                                                <DebounceInput
                                                    className={`outline${usernameTemp.length < 1 || profile.username == usernameTemp ? "" : usernameTempValid && usernameUnique ? " active outline-valid" : " outline-danger"}`}
                                                    placeholder="username"
                                                    autoComplete="off"
                                                    autoFocus
                                                    minLength={3}
                                                    debounceTimeout={700}
                                                    onKeyDown={e => handleUsernameSearched(false)}
                                                    onKeyUp={(e) => handleUsernameTemp(e.target.value)}
                                                    value={usernameTemp}
                                                    onChange={e => changeUsernameSearch(e)} />
                                                
                                                <label>Username {usernameTempValid && usernameUnique && usernameTemp.length > 2 ? ( <div className="icon-check green" style={{marginLeft:7}}></div> ) : null} </label>
                                                
                                            

                                                <div style={{position:"absolute",display:"flex",alignItems:"center",flexDirection:"row",right:22}} >
                                                    {usernameTemp.length > 2 && usernameSearched && !usernameUnique && profile.username !== usernameTemp ? (
                                                        <>
                                                            <label className="danger">username taken</label>
                                                        </>
                                                    ):null}

                                                    { profile.username !== usernameTemp ? (
                                                        <button onClick={e => saveUsername()} style={{maxWidth:88,margin:0,marginLeft:7}} className={classNames("tiny", usernameUnique && usernameSearched && usernameTempValid ? "" : "disabled")}>save</button>
                                                    ):null}
                                                    
                                                    <button onClick={e => setEditUsername(false)} style={{maxWidth:88,margin:0,marginLeft:7}} className="tiny outline">cancel</button>
                                                </div>
                                            </>
                                        )}
                                        
                                        
                                    {/* <label style={{position:"absolute",right:44}} className="danger">invalid</label> */}
                                    </div>

                                

                                    <InputTextField
                                        title={'Display name'}
                                        // disabled={!theme.allowEmbedButtonText}
                                        checkmark={profile.name?.length > 0}
                                        checkClass={profile.name?.length > 0 && profile.name?.length < 3 ? '' : 'green'}
                                        validate={validateName}
                                        onChange={onNameChange}
                                        placeholder='name'
                                        value={profile.name}
                                    />

                                   

                                    <InputColorPick
                                    title="Color"
                                    onChange={handleColorSelection("color")}
                                    value={profile.color}
                                    // disableAlpha={true}
                                    mode="rgba"
                                    />

                                    <InputTextArea
                                        title={'Bio'}
                                        // disabled={!theme.allowEmbedButtonText}
                                        maxLength={160}
                                        resize={false}  // both, horizontal, vertical
                                        rows={3}
                                        validate={validateName}
                                        onChange={onBioChange}
                                        placeholder=''
                                        value={profile.bio}
                                        
                                    />      
                

                                
                                </>
                            ):null}

                            { settingsView == "notifications" ? (
                                <>
                                    <div style={{height:7}} className="flex-row"></div>   

                                     <InputToggle
                                        title="Notifications"
                                        value={profile.notificationsOn}
                                        onChange={toggleNotificationsOn}
                                        horizontal={true}
                                    />

                                    <div style={{height:7}} className="flex-row"></div>
                                    
                                    <InputSelectMulti
                                        title={"Notification Types"}
                                        // subtitle={"by chain"}
                                        field="showNotifications"
                                        optionsSelected={profile.showNotifications}
                                        options={["new pairs","calls","news","posts","list updates"]}
                                        optionValues={["pair","call","news","xpost","list"]}
                                        // images={ [
                                        //     require(`../../icons/logo_chain_ethereum.png`),
                                        //     require(`../../icons/logo_chain_base.png`),
                                        //     require(`../../icons/logo_chain_solana.png`),
                                        //     require(`../../icons/logo_chain_ton.png`),
                                        //     require(`../../icons/logo_chain_tron.png`),
                                        // ] }
                                        onChange={showNotificationsChange}
                                        addStyleInputGroup={{flexDirection:"row", flexWrap:"wrap"}}
                                        // optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />

                                    <InputSelectMulti
                                        title={"New Pair Notifications"}
                                        subtitle={"by chain"}
                                        field="newPairChains"
                                        optionsSelected={profile.newPairChains}
                                        options={["Ethereum","Base","Solana","Ton","Tron"]}
                                        optionValues={["ethereum","base","solana","ton","tron"]}
                                        images={ [
                                            require(`../../icons/logo_chain_ethereum.png`),
                                            require(`../../icons/logo_chain_base.png`),
                                            require(`../../icons/logo_chain_solana.png`),
                                            require(`../../icons/logo_chain_ton.png`),
                                            require(`../../icons/logo_chain_tron.png`),
                                        ] }
                                        onChange={newPairChainsChange}
                                        addStyleInputGroup={{flexDirection:"row", flexWrap:"wrap"}}
                                        // optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                </>
                            ):null}


                            { settingsView == "wallets" ? (
                                <>
                                    <div style={{height:7}} className="flex-row"></div>   
                                    
                                    <InputTextField
                                        title={'Solana Wallet Address'}
                                        subtitle={"for portfolio"}
                                        // disabled={!theme.allowEmbedButtonText}
                                        checkmark={profile.walletAddressSolana?.length > 0}
                                        checkClass={profile.walletAddressSolana?.length > 0 && profile.walletAddressSolana?.length < 44 ? 'red' : 'green'}
                                        validate={validateSolWallet}
                                        onChange={onSolWalletChange}
                                        placeholder='Paste address here'
                                        value={profile.walletAddressSolana}
                                    />


                                    <InputSelectMulti
                                        title={"Telegram Wallets"}
                                        subtitle={"snipers"}
                                        field="sniperWallets"
                                        optionsSelected={profile.sniperWallets}
                                        options={sniperOptions.map((s,i) => ( s.name ))}
                                        optionValues={sniperOptions.map((s,i) => ( s.value ))}
                                        images={sniperOptions.map((s,i) => ( require(`../../icons/wallets/logo_${s.value}.png`) ))} 
                                        addStyle={{minWidth:222}}
                                        onChange={sniperWalletsChange}
                                        addStyleInputGroup={{flexDirection:"row", flexWrap:"wrap"}}
                                        // optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />

                                </>
                            ):null}

                            { settingsView == "theme" ? (
                                <>
                                    <div style={{height:7}} className="flex-row"></div>   
                                    
                                    <InputToggle
                                        title="Photo background"
                                        value={profile.photobg}
                                        onChange={togglePhotoBg}
                                        horizontal={true}
                                    />

                                    <InputSlider
                                        title="Photo brightness"
                                        value={profile.photobrightness}
                                        onChange={changePhotoBrightness}
                                    />

                                    <InputSlider
                                        title="Photo opacity"
                                        value={profile.photoalpha}
                                        onChange={changePhotoAlpha}
                                    />

                                    <div style={{height:7}} className="flex-row"></div>  

                                    <InputColorGradientPicker
                                        title="Background color"
                                        onChange={handleColorGradientSelection("colorgradient")}
                                        value={profile.colorgradient}
                                        // disableAlpha={true}
                                        mode="rgba"
                                    />
                                    
                                    <div className="gradient-swatches flex-row wrap">

                                        { bgGradients.map((g,i) => (
                                            <div onClick={() => gradientSwatchClick(g)} style={{background: `${g}`}} className="gradient-swatch">

                                            </div>
                                        ))}

                                    </div>
                                    

                                    <InputColorPick
                                        title="Portfolio"
                                        onChange={handleColorSelection("colorbgportfolio")}
                                        value={profile.colorbgportfolio}
                                        mode="rgba"
                                    />

                                    <div className="gradient-swatches flex-row wrap">

                                        { vibrantColors.map((c,i) => (
                                            <div onClick={() => portfolioSwatchClick(c)} style={{background: `${c}`}} className="gradient-swatch">

                                            </div>
                                        ))}

                                    </div>

                                    <div style={{height:11}} className="flex-row"></div>  

                                    <InputToggle
                                        title="Mock portfolio"
                                        value={profile.mockPortfolio}
                                        onChange={toggleMockPortfolio}
                                        horizontal={true}
                                    />

                                    <InputToggle
                                        title="Wallet Blur"
                                        value={profile.walletBlur}
                                        onChange={toggleWalletBlur}
                                        horizontal={true}
                                    />

                                    <InputToggle
                                        title="Balance Blur"
                                        value={profile.balanceBlur}
                                        onChange={toggleBalanceBlur}
                                        horizontal={true}
                                    />

                                    <div style={{height:22}} className="flex-row"></div>

                                    {/* <InputColorPick
                                        title="Site #1"
                                        onChange={handleColorSelection("colorbg1")}
                                        value={profile.colorbg1}
                                        mode="rgba"
                                    />

                                    <InputColorPick
                                        title="Site #2"
                                        onChange={handleColorSelection("colorbg2")}
                                        value={profile.colorbg2}
                                        mode="rgba"
                                    /> */}

                                    
                                            
                                </>
                            ):null}

                            {settingsView == "soundfx" ? (
                                <>
                                    
                                    <InputSlider
                                        title="Volume"
                                        value={profile.sfxVolume}
                                        onChange={changeSfxVolume}
                                        onMouseUp={playSfxVolume}
                                    />
                                    
                                    <InputSelect
                                        title={"New Pair"}
                                        field="newpair"
                                        onChange={onChangeSound}
                                        value={profile.sfx.newpair}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />

                                    <InputSelect
                                        title={"New X Post"}
                                        field="newxpost"
                                        onChange={onChangeSound}
                                        value={profile.sfx.newxpost}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />  

                                    
                                    <InputSelect
                                        title={"News"}
                                        field="news"
                                        onChange={onChangeSound}
                                        value={profile.sfx.news}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                    <InputSelect
                                        title={"Calls"}
                                        field="call"
                                        onChange={onChangeSound}
                                        value={profile.sfx.call}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />

                                    <InputSelect
                                        title={"List Updates"}
                                        field="list"
                                        onChange={onChangeSound}
                                        value={profile.sfx.list}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                    <InputSelect
                                        title={"Price Up"}
                                        field="priceup"
                                        onChange={onChangeSound}
                                        value={profile.sfx.priceup}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                    <InputSelect
                                        title={"Price Down"}
                                        field="pricedown"
                                        onChange={onChangeSound}
                                        value={profile.sfx.pricedown}
                                        options={sfxNames}
                                        optionsValues={sfxValues}
                                        // addClass="button-icon-select"
                                    />
                                </>
                            ):null}
        
                            
                             { settingsView == "code" ? (
                                <>
                                    <h2 style={{margin:22}}>coming soon</h2>
                                </>
                             ):null}
                            

                             

                           

                            

                            

                            <div style={{height:22}} className="flex-row"></div>

                            

                        </div>


                    </>



                ):null}



                {/*{token && (

                    <Blockies
                        seed={`love&hope37${token.pair.data.baseToken.address}`}
                        size={9}
                        scale={99}
                        // color="#dfe"
                        // bgColor="#ffe"
                        // spotColor="#abc"
                        className="identiconlarge"
                    />

                )}*/}
        


            </div>


            <div style={{pointerEvents: "none",alignItems:'flex-end',position: "absolute",width: "100%",zIndex: 99}} className="flex-column">

                <div style={{margin:20,padding:20}} className="data-panel">
                    <div style={{margin:20,padding:20}} className="token-balance-dex">
                        <p>Portfolio</p>
                    </div>
                </div>



                <div className="">

                </div>
            </div>

        </Layout>
    );
}
