import React, { useEffect, useState, useRef } from "react";

import { useWeb3React } from '@web3-react/core';
import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";

import classNames from "classnames";
import { chains, toHex, chainData } from "../../utils/data";
import * as tools from "../../utils/tools";
import web3 from "web3";


export function ChainPanel() {
	// const { activate, deactivate, active, chainId, account } = useWeb3React();
	const { chainPanel, handleChainPanel } = useCore();

	const { library } = useWeb3React();

	const { switchChain, chainId } = useWallet();

	const chainp = useRef(null);

	useEffect(() => {
		const checkIfClickedOutside = e => {
		  if (chainPanel && chainp.current && !chainp.current.contains(e.target)) {
		    handleChainPanel(false)
		  }
		}

		document.addEventListener("click", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("click", checkIfClickedOutside)
		}
	}, [chainPanel]);

	// example of switching or adding network with Harmony Mainnet
	const switchNetwork = async (c) => {
		// console.log(chainData);
		// console.log(chainData[c.name].params);
		// console.log(web3.utils.toHex(c.id))
		try {
			// console.log('try switch', c);
			// setTimeout(() => {
			// 	handleChainPanel(false)
			// },500);



			switchChain(c);


			// await library.provider.request({
			// 	method: "wallet_switchEthereumChain",
			// 	// params: [{ chainId: c.hex.length ? c.hex : chainData[c.name].params[0].chainId }],
			// 	params: [{ chainId: web3.utils.toHex(c.id) }]
			// });
			//
			// // await window.ethereum.enable();
	        // const accounts = await library.provider.request({ method: 'eth_requestAccounts' });
	        // // const account = accounts[0];
	        // console.log('here they come',accounts)



		} catch (switchError) {
			// 4902 error code indicates the chain is missing on the wallet
			
			if (switchError.code === 4902) {
				try {
					await library.provider.request({
						method: "wallet_addEthereumChain",
						params: {...chainData[c.name].params[0]},
					});
				} catch (error) {
				  console.error(error)
				}
			}

			if (switchError.code === 4001) {
				try {
					// console.log(switchError.message);
				} catch (error) {
				//   console.error(error)
				}
			}
		}
	};




	//
	//
	// async function chainItemClick(c){
	// 	if(chainId !== c.id){
	// 		try {
	// 			await window.ethereum.request({
	// 			  method: 'wallet_switchEthereumChain',
	// 			  params: [{ chainId: web3.utils.toHex(c.id) }]
	// 			});
	// 		} catch (err) {
	// 		    // This error code indicates that the chain has not been added to MetaMask
	// 			if (err.code === 4902) {
	// 			  await window.ethereum.request({
	// 			    method: 'wallet_addEthereumChain',
	// 			    params: [
	// 			      {
	// 			        chainName: 'Polygon Mainnet',
	// 			        chainId: web3.utils.toHex(chainId),
	// 			        nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
	// 			        rpcUrls: ['https://polygon-rpc.com/']
	// 			      }
	// 			    ]
	// 			  });
	// 			}
	// 		}
	// 	}
	// }

    return (
        <div ref={chainp} className={classNames('chain-panel', chainPanel && 'show')}>
			{Object.keys(chains).map((key, i) => (
				<div style={{justifyContent:'space-between'}} onClick={() => switchNetwork(key)} className={classNames('chain-item', chainId == key && 'active')} key={i}>
					<div className="flex-row">
						<img
							className="chain-icon"
							src={require(`../../icons/logo_chain_${key=="mevblocker" || key=="flashbots" ? "ethereum" : chains[key].image}.png`)}
							style={{ filter: chains[key].test!==undefined && chains[key].length ? 'saturation(.3);' : ''}}/>
						<p>{chains[key].name}</p>
						{ chainId == key && (
							<div className="active-green-circle"/>
						)}
					</div>

					{ key=="mevblocker" || key=="flashbots" ? (
						<img
							className="chain-icon"
							src={require(`../../icons/logo_chain_${chains[key].image}.png`)}
							style={{ filter: chains[key].test!==undefined && chains[key].length ? 'saturation(.3);' : ''}}/>
					):null}

				</div>
			))}
        </div>
    );
}
