import React from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";

var parse = require('html-react-parser');


export function CoinDataPriceChange({ values, labels}) {

    return (
        <div className="data-row bg flex-row justify space-around">
            { values.map(( v, i) => (
                <div className="data-item">
                    <div className="data-title">{labels[i]}</div>
                    <div className="data-value percent">{parse(formatPercent(v))}</div>
                </div>
            ))}
        </div>
    );
}
