import React, { useState, useEffect } from "react";


import { blockieSeed } from "../../utils/data";
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js';

import { zeroxdexAddress } from "../../utils/tools";
import classNames from "classnames";

export function TokenListItemSymbol({ token = null, user=null, maxWidthIcon=null, i=0, addClass=null, passClick=null }) {


    const [imageError, setImageError ] = useState(false);
    const [imageProcessed, setImageProcessed] = useState(false);
    const [triedOnce, setTriedOnce] = useState(false);

    const tokenImageUrl = user ? null : token && (token.address == zeroxdexAddress) ? require('../../icons/logo_0xdex_100.jpg')
    : token && token.cg && token.cg.image !== undefined && token.cg.image.small !== "missing_small.png" ? token.cg.image.small
    : token && token.iconImage ? `${process.env.REACT_APP_MEDIA_URL}${token.iconImage}` : `${process.env.REACT_APP_MEDIA_URL}${token.address}.png`;

    const userImageUrl = user && user.avatarFile ? `${process.env.REACT_APP_MEDIA_URL}${user.avatarFile}` : null;

    const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    useEffect(() => {

        if(imageError && token.iconImage){
         setImageError(false);
         setImageProcessed(false)
        }
         
     }, [ token?.iconImage ]);
 
 
     const onImageError = (e) => {
         setImageError(true);
         setImageProcessed(true);
         if(!triedOnce){
             setTimeout(() => {
                 setImageProcessed(false);
                 setImageError(false);
             }, 10)
             setTriedOnce(true);
         }
     }
     const onImageLoad = (e) => {
        setImageProcessed(true);
     }

    const onClick = (e) => {
        if(passClick){
            passClick(token);
        }
    }

    return (
        <div onClick={onClick} data-tip={token ? `$${token.symbol} on ${capitalizeFirstLetter(token.chainId)}` : ""} style={{ zIndex: (40 - i) }} className={classNames("token-list-item-symbol", addClass && addClass)}>
            
            { user ? (
                <>
                    { user !== "default" && !imageError ? (

                        <img style={{ opacity: imageProcessed ? 1 : 0, maxWidth: maxWidthIcon ? (maxWidthIcon + 5) : ''}} onLoad={onImageLoad} onError={onImageError} src={tokenImageUrl}/>

                    ):(
                        <img style={{ borderRadius: '50%',margin: 3,maxWidth: maxWidthIcon ? maxWidthIcon : '33px'}} src={makeBlockiesUrl(blockieSeed + user.walletAddress)} />
                    )}
                </>
            ):(
                <>
                    { tokenImageUrl && !imageError ? (

                        <img style={{ opacity: imageProcessed ? 1 : 0, maxWidth: maxWidthIcon ? (maxWidthIcon + 5) : ''}} onLoad={onImageLoad} onError={onImageError} src={tokenImageUrl}/>

                    ):(
                        <img style={{ borderRadius: '50%',margin: 3,maxWidth: maxWidthIcon ? maxWidthIcon : '33px'}} src={makeBlockiesUrl(blockieSeed + token.address)} />
                    )}
                </>
            )}

            
        </div>
    );
}


