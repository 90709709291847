// a library to wrap and simplify api calls
import apisauce from "apisauce";

// our "constructor"
export const create = (baseURL = process.env.REACT_APP_COINGECKO_API_URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'AccessKey': process.env.REACT_APP_DEBANK_API_KEY,
        },
        // 10 second timeout...
        timeout: 10000,
    });



    const getHistory = (id) => {
        return api.get(
            `${process.env.REACT_APP_DEBANK_API_URL}/user/history_list?id=${id}`
        );
    };

    // const getExchange = (id) => {
    //     console.log(`${process.env.REACT_APP_COINGECKO_API_URL}/exchanges/${id}`)
    //     return api.get(
    //         `${process.env.REACT_APP_COINGECKO_API_URL}/exchanges/${id}`
    //     );
    // };
    //
    // const getMarketChart = (id) => {
    //     let data = {
    //         vs_currency:'usd',
    //         days:'30',
    //         interval:'hourly'
    //     }
    //     return api.get(
    //         `${process.env.REACT_APP_COINGECKO_API_URL}/coins/${id}/market_chart`,
    //         data
    //     );
    // };


    // return functions for interface
    return {
        getHistory,
    };
};

// Creates a singleton
export const client_debank = create();


//
//
//
// const options = {
//   method: 'GET',
//   url: 'https://coingecko.p.rapidapi.com/simple/price',
//   params: {ids: '<REQUIRED>', vs_currencies: '<REQUIRED>'},
//   headers: {
//     'X-RapidAPI-Host': 'coingecko.p.rapidapi.com',
//     'X-RapidAPI-Key': '9734a11ae1msh4ba2d2b64c367cep14689djsn47d3abd12758'
//   }
// };
//
// axios.request(options).then(function (response) {
// 	console.log(response.data);
// }).catch(function (error) {
// 	console.error(error);
// });







///////
