import React, { useState, useEffect, useContext, createContext } from "react";
import Cookies from "js-cookie";

import { client } from "../../services/api";
import { client_twitter } from "../../services/api_twitter";

const authContext = createContext();

function useProvideAuth() {

    // const [authToken, setAuthToken] = useState('test');

    // const [authToken, setAuthToken] = useState(
    //     localStorage.getItem("token") || true
    // );

    const [authToken, setAuthToken] = useState(null);

    // const [loading, setLoading] = useState(!!authToken);
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // useEffect(() => {
    //     if (authToken && !isAuthenticated) {
    //         // client.api.setHeaders({ Authorization: `Bearer ${authToken}` });
    //         // client.me().then((res) => {
    //             localStorage.setItem("token", authToken);
    //             Cookies.set("artemistoken", authToken);
    //
    //             // setCurrentUser(res.data);
    //             setIsAuthenticated(true);
    //             setLoading(false);
    //         // });
    //     }else{
    //         setLoading(false)
    //     }
    //
    // }, [authToken, isAuthenticated]);


    async function fetchTwitterAuth(query) {
        // setLoading(true);
        console.log('getting twitter auth')
        const twauthResponse = await client.getTwitterAuth();

        const _tw = twauthResponse.data || [];
        console.log(twauthResponse);
        // setLoading(false);
    }

    // const logIn = async (username, password) => {
    //     setLoading(true);
    //
    //     try {
    //         const authResult = await client.logIn({ username, password });
    //
    //         if (!authResult.ok) {
    //             throw authResult.data;
    //         }
    //
    //         console.log(authResult)
    //
    //         setAuthToken(authResult.data.token);
    //         localStorage.setItem("token", authToken);
    //         Cookies.set("cktoken", authToken);
    //
    //         setCurrentUser(authResult.data);
    //         setIsAuthenticated(true);
    //         setHasError(false);
    //         setError(null);
    //         setLoading(false);
    //     } catch (error) {
    //         setHasError(true);
    //         if(error){
    //             setError(error.message);
    //         }
    //         setLoading(false);
    //     }
    // };
    //
    // const logOut = () => {
    //     localStorage.removeItem("token");
    //     Cookies.remove("cktoken", "");
    //
    //     setAuthToken(null);
    //     setCurrentUser(null);
    //     setIsAuthenticated(false);
    // };





    // Return the user object and auth methods
    return {
        loading,
        isAuthenticated,
        fetchTwitterAuth,
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};
