import React, { useEffect, useState } from "react";

import classNames from "classnames";

// import { useAuth } from "../../hooks/useAuth";
import { useWallet } from "../../hooks/useWallet";
import { useCollection } from "../../hooks/useCollection";

import { useNavigator } from "../../hooks/useNavigator";

export function Breadcrumbs() {

	const navigator = useNavigator();

	let { contractAddress, handleContractAddress, screens } = useNavigator();

	let { moralisInit,
        balances,
        transactions,
        transactionsMeta,
        nfts,
        fetchNFTs,
        fetchBalances,
        fetchTransactions,
        walletAddress,
        chainTotalBalances,
    } = useWallet();

	let { collections } = useCollection();

	function onBreadCrumbClick(i){
        console.log(i)
        let path = [...screens].slice(0, (i+1)).join('/');
        navigator.push(path);
    }

    function onBack(){
        console.log('back button');
    }

	const collection = collections.filter(obj => {
		if( screens.length > 2){
			return obj.token == screens[2];
		}else{
			return {};
		}
	});

    return (
		<div className="breadcrumbs">
			{/*<div className="flex-row">*/}

				<button className="breadcrumb icon" onClick={onBack}>
					<i className="icon-arrow-left"></i>
				</button>
				<button onClick={ () => { navigator.push(''); }} className="breadcrumb icon">
					<i className="icon-home"></i>
				</button>

				{ screens.map((v,i) => {
					if(v.length){
						return (
							<button key={i} onClick={ () => onBreadCrumbClick(i)} className={classNames(
								"breadcrumb",
								i == (screens.length - 1) ? "active" : ""
								)}>
								{v.indexOf('0x') > -1 ? nfts[v].name :
								 screens[i-1] == 'collections' && i == (screens.length - 1) && collection[0] !== undefined ? collection[0].name : v}
							</button>
						)
					}


				})}
			{/*</div>*/}


		</div>
    );
}
