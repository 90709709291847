import React, { useEffect, useState, useRef } from "react";


import { useCore } from "../../hooks/useCore";

import classNames from "classnames";

import * as tools from "../../utils/tools";



export function CollectionItem({ collection, passClick, active=false }) {


	useEffect(() => {

	}, []);



    return (
        <div onClick={() => passClick(collection)} className={classNames('collection-item', active && 'active')}>
			<div className="collection-content-container">
				<div className="collection-content">
					<h6>{collection.name}</h6>
					<div style={{marginLeft: 7}} className="count-circle">
						{collection.assets.length}
					</div>
				</div>
				<div className="collection-background-images">
					{collection.assets.map((a, key) => (
						<div style={{backgroundImage: `url(${collection.assets.length > 1 ? a.image_preview_url : a.image_url})`}} className="collection-background-image">

						</div>
					))}
				</div>
			</div>
			<div style={{backgroundColor: collection.color }} className="collection-color-panel"/>
        </div>
    );
}
