import React, { useState, useEffect, useRef } from "react";
import ColorPicker from "react-best-gradient-color-picker";
import { validateColor } from "../../utils/validations";

export function InputColorGradientPicker({ title, value, onChange, addClass, mode = "hex" }) {
    const pickerRef = useRef();
    const [showPicker, setShowPicker] = useState(false);
    const [color, setColor] = useState(value || "#000");
    const [error, setError] = useState(false);

    function handleHidePicker(e) {
        if (pickerRef.current && !pickerRef.current.contains(e.target)) {
            setShowPicker(false);
            document.removeEventListener("click", handleHidePicker);
        }
    }

    useEffect(() => {
        setColor(value);
    }, [value]);

    function handleShowPicker() {
        setShowPicker(true);
        setTimeout(() => {
            document.addEventListener("click", handleHidePicker);
        }, 50);
    }

    function handleUpdateColorFromPicker(newColor) {
        setError(false);
        setColor(newColor);
        onChange(newColor);
    }

    function handleUpdateColor(e) {
        const newColor = e.target.value;
        setColor(newColor);
        const errorCheck = validateColor(newColor, mode === "hex" ? false : true);
        if (!errorCheck) {
            onChange(newColor);
            setError(false);
        } else {
            setError(errorCheck);
        }
    }

    return (
        <div className={`input-group ${addClass}`}>
            <label>{title}</label>

            <div className="color-picker-wrapper">
                <div
                    className="swatch-preview"
                    id={title}
                    onClick={!showPicker ? handleShowPicker : undefined}
                    style={{ background: color }}
                />

                <input
                    className="mono outline small"
                    value={color}
                    type="text"
                    maxLength={mode === "hex" ? "7" : "22"}
                    onChange={handleUpdateColor}
                />
            </div>

            {error && (
                <div className="error">{error}</div>
            )}

            {showPicker && (
                <div ref={pickerRef} className="color-picker" id={title}>
                    <ColorPicker
                        value={color}
                        onChange={handleUpdateColorFromPicker}
                        hideAnglePicker={true}
                    />
                </div>
            )}
        </div>
    );
}
