import React, { useEffect, useRef } from "react";
import { useCore } from "../../hooks/useCore";
import { useMarket } from "../../hooks/useMarket";

import { FilterSegment } from '../FilterSegment';
import { InputSelect } from "../Input/InputSelect";

import classNames from "classnames";

import { formatCurrency, condenseZeros, formatPercent } from "../../utils/currency";
import { geckoCats } from "../../utils/categories_cg";
var parse = require('html-react-parser');

export function CoinList({ height }) {


    let { coins, coinCurrent, handleCoinCurrent, coinsTrending, coinOrder, handleCoinOrder, coinCategory, handleCoinCategory, fetchCoinsMarkets } = useMarket();
    let { footerHeight, headerHeight, page } = useCore();

    const coinslist = useRef(null);


    const switchCoin = async (c) => {
        handleCoinCurrent(c.id);
    }

    const getCoinsMarkets = () => {

    }

    const catClick = (f) => {
        // console.log(f)
        handleCoinCategory(f);
        let q = {
            category: f,
            order: coinOrder
        }
        fetchCoinsMarkets(q);

    }

    // let filterCats = [
    //     {
    //         name: 'All',
    //         value: 'all',
    //     },
    //     {
    //         name: "DEX",
    //         value: "decentralized-exchange"
    //     },
    //     {
    //         name: "DeFi",
    //         value: "decentralized-finance-defi"
    //     },
    //     {
    //         name: "Governance",
    //         value: "governance"
    //     },
    //     {
    //         name: "Layer 1",
    //         value: "layer-1"
    //     },
    //     {
    //         name: "LP Tokens",
    //         value: "lp-tokens"
    //     },
    //     {
    //         name: "Media",
    //         value: "media"
    //     },
    //     {
    //         name: "DeFi Index",
    //         value: "defi-index"
    //     },
    //     {
    //         name: "Energy",
    //         value: "energy"
    //     },
    //     {
    //         name: "Finance + Banking",
    //         value: "finance-banking"
    //     },
    //     {
    //         name: "Yield Farming",
    //         value: "yield-farming"
    //     }
    // ];

    const orderClick = (f) => {
        // console.log(f);
        handleCoinOrder(f);
        let q = {
            category: coinCategory,
            order: f
        }
        fetchCoinsMarkets(q);
    }

    let filterOrder = [
        {
            name: 'volume',
            value: 'volume_desc',
        },
        {
            name: "Market Cap",
            value: "market_cap_desc"
        }
    ];

    const selectcats = geckoCats.map((cat, i) => {
        return cat.name;
    });
    const selectcatsvalues = geckoCats.map((cat, i) => {
        return cat.category_id;
    });

    return (
        <div style={{maxHeight: `calc( 100vh - 64px - ${height} )`}} className="coin-list-wrapper">
            <div className="filter-header">
                <div className="coin-filters">
                    <div className="icon-button-list"/>
                    <InputSelect
                        title=''
                        onChange={catClick}
                        value={coinCategory}
                        options={selectcats}
                        optionsValues={selectcatsvalues}
                        addClass="button-icon-select"
                        addClassInputGroup="no-margin"
                    />
                    {/*<FilterSegment activeValue={coinCategory} passClick={catClick} filters={filterCats}/>*/}

                </div>
                <div className="coin-filters">
                    <FilterSegment activeValue={coinOrder} passClick={orderClick} filters={filterOrder}/>
                </div>
            </div>
            <div className="coin-list">

                { Object.keys(coins).length > 0 && (

                    <div ref={coinslist} className={classNames('coins-items-container', coins.length && 'show')}>
                            {/*{Object.keys(coins).map((coin, i) => (*/}

                        {Object.keys(coins)
                            .filter(coin => (coins[coin].cats.includes(coinCategory) || coinCategory == "all" ))
                            // .sort((a, b) => a["market_cap"] > b["market_cap"] ? 1 : -1)
                            .map((coin, i) => (


                            <div onClick={() => switchCoin(coins[coin])} className={classNames('coin-item', coinCurrent.id == coins[coin].id && 'active')} key={i}>
                                <img
                                    className={classNames('coin-icon', coin)}
                                    // crossOrigin="anonymous"
                                    src={coins[coin].image}
                                    // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                                    />
                                <div className="coin-meta">
                                    <p className="coin-name">{coins[coin].name}</p>
                                    <p className="coin-symbol">{coins[coin].symbol}</p>
                                </div>

                                { coins[coin].usd !== undefined && (
                                    <>
                                        <p className="coin-24-change">
                                            <span className="small">24h </span>{parse(formatPercent(coins[coin].usd_24h_change))}
                                        </p>
                                        <p className="coin-price">

                                            {parse(condenseZeros(formatCurrency(coins[coin].usd, "USD")))}
                                            { /*  {parse(condenseZeros(formatCurrency(coin.item.price_btc, "BTC")))}  */}
                                        </p>
                                    </>
                                )}



                                { coins[coin].market_cap_rank && (
                                    <div className="coin-rank">{coins[coin].market_cap_rank}</div>
                                )}


                                { coinCurrent.id == coins[coin].id && (
                                    <div className="active-green-circle"/>
                                )}
                            </div>
                        ))}
                    </div>

                )}




            </div>


        </div>
    );
}
