import React from "react";
import { NavLink } from "react-router-dom";

import classNames from "classnames";

export function IconActionButton({ type, passClick, passStyle, position, addClass, active, children }) {

    let buttonposition = !position || position == undefined ? "" : position;
    let stylepass = !passStyle || passStyle == undefined ? {} : passStyle;

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        passClick();
    }

    return (
        <div onClick={handleClick} style={stylepass} className={classNames('icon-action-button', buttonposition, addClass, active ? 'active' : '')}>
            { type=="close" ? (
                <svg viewBox="0 0 24 24" fill="none" width="16" height="16">
    				<path d="M6 18L18 6M6 6l12 12" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    				</path>
    			</svg>
            ) : type=="arrow-left" ? (
                <div className="icon-chevron-left"/>
            ) : type=="arrow-right" ? (
                <div className="icon-chevron-right"/>
            ) : type=="arrow-down" ? (
                <div className="icon-chevron-down"/>
            ) : type=="plus" ? (
                <div className="icon-plus-thick"/>
            ) : type=="ellipsis-vertical" ? (
                <div className="icon-ellipsis-vertical"/>
            ) : type=="ellipsis-horizontal" ? (
                <div className="icon-ellipsis-horizontal"/>
            ) : type=="eye" ? (
                <svg x="0px" y="0px" viewBox="0 0 489.935 489.935" width="16" height="16">

            		<g>
            			<path d="M486.617,255.067c4.6-6.3,4.4-14.9-0.5-21c-74.1-91.1-154.1-137.3-237.9-137.3c-142.1,0-240.8,132.4-244.9,138
            				c-4.6,6.3-4.4,14.9,0.5,21c74,91.2,154,137.4,237.8,137.4C383.717,393.167,482.417,260.767,486.617,255.067z M241.617,358.867
            				c-69.8,0-137.8-38.4-202.4-114c25.3-29.9,105.7-113.8,209-113.8c69.8,0,137.8,38.4,202.4,114
            				C425.317,274.967,344.917,358.867,241.617,358.867z"/>
            			<path d="M244.917,157.867c-48,0-87.1,39.1-87.1,87.1s39.1,87.1,87.1,87.1s87.1-39.1,87.1-87.1S292.917,157.867,244.917,157.867z
            				 M244.917,297.767c-29.1,0-52.8-23.7-52.8-52.8s23.7-52.8,52.8-52.8s52.8,23.7,52.8,52.8S274.017,297.767,244.917,297.767z"/>
            		</g>


                </svg>
            ) : null}
        </div>
    );
}
