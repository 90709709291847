import React, { useState, useRef, useEffect } from "react";

import { useMarket } from "../../hooks/useMarket";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useDex } from "../../hooks/useDex";

import { useNavigator } from "../../hooks/useNavigator";

import { TokenSymbolIcon } from "./TokenSymbolIcon";
import { TokenDataTabs } from "./TokenDataTabs";
import { IconActionButton } from "../IconActionButton";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";

import classNames from "classnames";

var parse = require('html-react-parser');

export function PortfolioToken({ chain, token, passClick }) {

    const optionsref = useRef(null);

    const navigator = useNavigator();

    const [ optionsMenu, setOptionsMenu ] = useState(false);

    const {
        walletAddress,
        balancesAlchemy,
        fetchTokenBalances,
        removeTokenFromPortfolio,
        ethPrice
    } = useWallet();

    const { coins } = useMarket();


    const { handleTokenPair, tokenLists, handleAddToTokenList, fetchPortfolioTokens } = useDex();

    const handleClick = async () => {
        console.log(token)
        await handleTokenPair(token,"portfolio");
        // navigator.push(`/${token.pair.data.chainId}/${token.pair.address}`);
    }

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }




    useEffect(() => {

        // const fetchBalance = async (wa, ca) => {
        //     await fetchTokenBalances(wa, ca);
        // }
        //
        // if(walletAddress && balancesAlchemy[token.caToken] == undefined){
        //     fetchBalance(walletAddress, token.caToken);
        //
        // }

	}, [ walletAddress, balancesAlchemy ]);


    useEffect(() => {
		const checkIfClickedOutside = e => {

		  if ( optionsMenu && optionsref.current && !optionsref.current.contains(e.target) && !e.target.classList.contains('icon-ellipsis-horizontal') ) {
		    setOptionsMenu(false);
		  }
		}

		document.addEventListener("mouseup", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("mouseup", checkIfClickedOutside)
		}

	}, [optionsMenu]);


    const addToList = (c) => {
		console.log(c)
		handleAddToTokenList(c,token);
	}

    const removeToken = () => {

        removeTokenFromPortfolio(token);
        setTimeout(() => {
            fetchPortfolioTokens(walletAddress);
        }, 500);
        setOptionsMenu(false);
    }


    const tb = token && token.pair.data && token.pair.data.baseToken !== undefined && balancesAlchemy[token.pair.data.baseToken.address] !== undefined ? parseInt(balancesAlchemy[token.pair.data.baseToken.address].balance) / (10**token.decimals) : 0;

    const percentSupplyOwned = Math.round((tb/Number(token.totalSupply))*100000).toFixed(7)/1000;

    // const ethValue = token == undefined ? 0 : token.data.quoteToken.symbol == "WETH" ? tb*token.data.priceNative : (tb*token.data.priceNative) / ethPrice;

    const ethValue = !token.pair.data ? 0 : token.pair.data.quoteToken.symbol == "WETH" ? tb*token.pair.data.priceNative*.9337 : (tb*token.pair.data.priceUsd*.9337) / (coins['ethereum'] !== undefined ?  coins['ethereum'].current_price : ethPrice);


    const tokenca = token && token.pair.data && token.pair.data.baseToken !== undefined ? token.pair.data.baseToken.address : null;

    const pair = token.pair.data && token.pair.data !== undefined ? token.pair.data : null;

    const isEth = token.pair.data && token.pair.data.baseToken.symbol == "WETH";


    return (

        <div className="portfolio-token-item data-panel">

            <div style={{position:'absolute',top:4,right:4, zIndex: optionsMenu ? 111 : 1}}>
                <IconActionButton
                addClass="transparentbg relative"
                type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }
                active={optionsMenu}
                passClick={toggleOptionsMenu}/>
            </div>

            { optionsMenu && (
                <div ref={optionsref} className="options-menu">

                    <div style={{marginTop:1,padding:'12px 9px'}} className="options-header">
                        <i className="icon-button-plus"/>
                        add to
                    </div>

                    { Object.keys(tokenLists).map(( key, i ) => {

						let inc = false;


						let ts = tokenLists[key].tokens;

						for(var k=0;k<ts.length;k++){
							console.log(ts[k])
							// console.log(ts[k],tokenPair)
							if(ts[k]){
								if(ts[k].address == token.address){
									inc = true;
								}
							}

						}


						return (
							<div
								//style={{ backgroundColor: collections[key].color }}
								className={classNames("option", inc && "disabled")}
								onClick={() => addToList(tokenLists[key])}
								key={i}
							>
								<div style={{
									backgroundColor: tokenLists[key].color,
									borderColor: tokenLists[key].color,
									marginRight: 7
								}} className="color-box"/>
								<p>{tokenLists[key].name}</p>

								<div className="plus-sign">
									<i className="icon-button-plus"/>
								</div>
							</div>
						)

					}
					)}



                    <div
                        //style={{ backgroundColor: collections[key].color }}
                        className={classNames("option")}
                        onClick={removeToken}

                    >
                        {/*<div className="svg-icon">
                            <WalletIcon/>
                        </div>*/}
                        <p className="red">remove</p>

                        <div className="plus-sign">
                            <i className="icon-button-minus"/>
                        </div>
                    </div>
                </div>
            )}

            <div onClick={handleClick} className="flex-row">

                <div className="flex-column">
                    { token && token !== undefined && token.pair.data !== undefined && (
                        <div style={{padding:'11px 0px 0px 11px'}} className="flex-row">

                            <TokenSymbolIcon
                              token={token}
                              ca={token.address}
                              symbol={token.symbol}
                              name={token.name}
                              // addClass="large"
                              />
                        </div>

                    )}
                </div>

                <div style={{width:320,marginLeft:33}} className="flex-column">


                    <div style={{justifyContent: 'space-between'}} className="data-row tabs right bookend">

                        {/*<RocketIcon/>*/}
                        <div className="data-item">
                            <div className="data-title">USD</div>
                            <div className={classNames("price data-value", )}>{parse(condenseZeros(formatCurrency(pair.priceUsd, "USD")))}</div>
                        </div>

                        <div className="data-item">
                            <div className="data-title">fdv</div>
                            { pair.fdv !== undefined && (
                                <div className="data-value"><span className="small">$</span>{parse("" + formatCurrencyScale(pair.fdv))}</div>
                            )}
                        </div>


                        <div className="data-item">
                            <div className="data-title">liq</div>
                                { pair.liquidity && pair.liquidity !== undefined && (
                                    <>
                                        {pair.liquidity.usd > 500 ? (
                                            <div className="data-value">
                                                <span className="small">$</span>{parse(formatCurrencyScale(pair.liquidity.usd))}
                                            </div>
                                        ): (
                                            <div className="caution-tag" style={{marginTop: 4}}>
                                                no liq : ${Math.round(pair.liquidity.usd)}
                                            </div>
                                        )}
                                    </>
                                )}


                        </div>

                        <div className="data-item">
                            <div style={{minWidth:44}} className="data-title">24h vol</div>
                            <div className="data-value"><span className="small">$</span>{parse(formatCurrencyScale(pair.volume.h24))}</div>
                        </div>

                    </div>

                    {token.pair.data !== undefined && token.pair.data.priceChange !== undefined && (
                        <TokenDataTabs
                            addClass="bookend"
                            addStyle={{minWidth:288}}
                            title="price"
                            values={[pair.priceChange.m5,
                                pair.priceChange.h1,
                                pair.priceChange.h6,
                                pair.priceChange.h24]}
                            valuesFormat={['percent','percent','percent','percent']}
                            labels={['5m','1h','6h','24h']}
                        />
                    )}





                </div>




                </div>




            <div onClick={handleClick} style={{alignItems:'flex-end'}} className="flex-row justify space-between">


                { balancesAlchemy[token.pair.data.baseToken.address] !== undefined && (
                    <div style={{
                        // borderBottomRightRadius: 5,
                        // borderBottomLeftRadius: 5,
                        width:'100%',
                        padding: '14px 9px 14px 14px',
                        maxHeight: 'initial'
                    }} className="token-balance-dex data-panel small">

                        <div style={{justifyContent:'flex-start'}} className="data-item row full">

                            <div style={{textAlign:'left'}} className="data-value currency">
                                <p className="token-balance">

                                    {tb > 100 ? (
                                        <>
                                            {parse(formatCurrencyScale(parseInt(Number(tb))))}
                                        </>
                                    ):(
                                        <>
                                            {tb.toFixed(2)}
                                        </>
                                    )}
                                    <span style={{marginLeft:7}} className="small">{isEth ? "ETH" : token.pair.data.baseToken.symbol}</span>

                                    {token.totalSupply && percentSupplyOwned > .01 && (
                                        <span style={{fontSize:'.73em',marginLeft:11}} className="small">{percentSupplyOwned}% &nbsp;</span>
                                    )}
                                    {/*{parse(' ' + formatCurrencyScale(parseInt(Number(tb))))} <span className="small">{token.data.baseToken.symbol}</span>*/}

                                </p>

                                <div style={{marginTop:4,justifyContent:"flex-start"}} className="flex-row justify">
                                    <p style={{fontSize:'1rem'}} className="usd-balance">
                                        {isEth ? (
                                            <>
                                                Ξ{tb.toFixed(2)}
                                            </>
                                        ):(
                                            <>
                                                {parse(condenseZeros(formatCurrency(ethValue > 1 ? (ethValue).toFixed(3) : (ethValue).toFixed(4), "ETH")))}
                                            </>
                                        )}

                                    </p>
                                    <p style={{fontSize:11,opacity:.4,marginLeft:17}} >::</p>
                                    <p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance"> {parse(formatCurrency((ethValue*ethPrice).toFixed(2), "USD"))}</p>
                                    {/*<p style={{marginLeft:17,fontSize:'1rem'}} className="usd-balance small"> {parse(formatCurrency((tb*token.data.priceUsd).toFixed(2), "USD"))}</p>*/}


                                </div>
                                {/*<div style={{marginTop:4,justifyContent:"flex-end"}} className="flex-row justify">
                                    <p style={{marginRight:11}} className="usd-balance small"> {parse(condenseZeros(formatCurrency((tb*token.data.priceUsd), "USD")))}</p>
                                    <p className="usd-balance small">{parse(condenseZeros(formatCurrency((ethValue).toFixed(4), "ETH")))}</p>

                                </div>*/}
                            </div>
                        </div>

                    </div>
                )}

            </div>



        </div>
    )
}
