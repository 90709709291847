import { APPS } from "./subdomains";


export const getApp = () => {

	const subdomain = getSubdomain(window.location.hostname);
	const main = APPS.find(app => app.main);

	if(!main) throw new Error("Must have main app.");

	if(subdomain === "") return main.app;

	const app = APPS.find(app => subdomain === app.subdomain);

	// console.log(app)

	if(!app) return main.app;

	return app.app;
}

const getSubdomain = (location) => {
	let subs = location.split(".");

	let sliceTill = -2;

	const isLocalhost = subs.slice(-1)[0] === "localhost";
	if(isLocalhost){
		sliceTill = -1;
	}
	return subs.slice(0,sliceTill).join("");
	// return subs[0];
}
