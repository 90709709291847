import React, { useEffect, useRef} from "react";

import TimeAgo from 'react-timeago';
// international
// import frenchStrings from 'react-timeago/lib/language-strings/fr'

import timeFormat from '../../utils/timeago_format';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

import { TwitterTag } from "./TwitterTag";

import classNames from "classnames";

import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";

import { removeHttp, decodeHtml, getTimeDisplay } from "../../utils/tools";
import { condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";

import { ReactComponent as RetweetIcon } from "../../icons/svgs/retweet.svg";

var parse = require('html-react-parser');


export function TwitterPost({ post, passClick, passTagClick }) {

    let { tagRoll,
        handleTagRoll,
        fetchTwitterUserByUsername,
        fetchConversation
    } = useSocial();

    let { dexResults,
        searchDex,
        tokenToolbarVisible,
        handleTokenToolbarVisible,
        handleScreenClickDims
    } = useDex();

    const videoRef = useRef(null);

    const attemptPlay = () => {
        videoRef &&
          videoRef.current &&
          videoRef.current.play().catch(error => {
            console.error("Error attempting to play", error);
          });
    };

    useEffect(() => {
        attemptPlay();
    }, []);

    // const disabled = token.name !== tokenInput.name && token.name !== tokenOutput.name ? false : true;

    const formatter = buildFormatter(timeFormat);


    const handleClick = () => {
        passClick(post);
    }

    const onRollOver = (t) => {
        handleTagRoll(t);
    }
    const onRollOut = (t) => {
        handleTagRoll(null);
    }

    const getConversation = () => {
        fetchConversation(post.conversation_id);
    }



    const ren = /\n/g;

    const trimmedText = decodeHtml(removeHttp(post.text.replace(ren,' ')));


    let mutedParam = 'muted';
    let autoplayParam = 'autoplay';

    return (

        <div
        //onClick={handleClick}
        className={classNames('social-item-post')}>
            <div className="social-item-post-header">
                {/*<p className="time">{getTimeDisplay(post.created)}</p>*/}

            </div>

            {post.retweet.length > 0 && (
                <div style={{marginBottom:22}} className="retweet-tag">

                    <div className="svg-icon">
                        {/*retweet*/}
                        <RetweetIcon/>
                    </div>

                    <TwitterTag
                        post={post}
                        tag={post.retweet}
                        tagRoll={tagRoll}
                        passClick={passTagClick}
                        passOnMouseEnter={onRollOver}
                        passOnMouseLeave={onRollOut}
                        nohide={true}
                    />

                    {/*<div onClick={(e) => passTagClick(e,post.retweet)} onMouseEnter={() => onRollOver(post.retweet)} onMouseLeave={onRollOut} className={classNames("social-item-tag",'user', post.retweet == tagRoll && 'active')}>
                        <p>{post.retweet}</p>
                    </div>*/}

                </div>

            )}

            <div className="social-item-tags">



                { Object.keys(post.tags).map((key, i) => (
                    <>
                        {post.tags[key].map((t, k) => {

                            // let keyt = `${Math.floor(Math.random()*50000)}${k}${i}`

                            let keyt=k;
                            // console.log(keyt)
                            return (

                                <TwitterTag
                                    key={`${keyt}`}
                                    post={post}
                                    tag={t}
                                    tagRoll={tagRoll}
                                    passClick={passTagClick}
                                    passOnMouseEnter={onRollOver}
                                    passOnMouseLeave={onRollOut}
                                />
                            )

                        }




                        )}

                    </>

                ))}

                {/*{post.tags.map((t, i) => {
                    if(!post.retweet || (post.retweet && i > 0)){
                        return (
                            <div key={i} onClick={(e) => passTagClick(e,t)} onMouseEnter={() => onRollOver(t)} onMouseLeave={onRollOut} className={classNames("social-item-tag",t.charAt(0) == '$' && 'cash', t.charAt(0) == '@' && 'user', t.charAt(0) == '#' && 'hash', t == tagRoll && 'active')}>
                                <p>{t}</p>
                            </div>
                        )
                    }
                })}
                {post.buzzwords.map((t, i) => (
                    <div key={i} onMouseEnter={() => onRollOver(t)} onMouseLeave={onRollOut} className={classNames("social-item-buzzword",t.charAt(0) == '$' && 'cash', t == tagRoll && 'active')}>
                        <p>{t}</p>
                    </div>
                ))}*/}


            </div>




            <p className="content">{trimmedText}</p>




            <div className="social-item-media-container">
                { post.media.map((m, i) => (
                    <div key={i} className="social-item-media">
                        { m.type == 'photo' && (
                            <img src={m.url} />
                        )}
                        { m.type == 'animated_gif' && (
                            <video
                                ref={videoRef}
                                muted={true}
                                autoPlay={true}
                                loop={true}
                                style={{maxWidth:`${m.width}px`}}
                                playsInline={true}
                                src={m.variants[0].content_type.indexOf('video/')> -1 ? m.variants[0].url : m.variants[1].content_type.indexOf('video/') > -1 ? m.variants[1].url : '' }
                            />
                        )}
                        { m.type == 'video' && (
                            <video controls src={m.variants[0].content_type.indexOf('video/')> -1 ? m.variants[0].url : m.variants[1].content_type.indexOf('video/') > -1 ? m.variants[1].url : '' } />
                        )}
                    </div>
                ))}
            </div>

            <div style={{alignItems:'center'}} className="data-row">

                { Number(post.public_metrics.reply_count) > 0 ? (
                    <button style={{margin:0,marginRight:10}} onClick={getConversation} >
                        <div className="icon-button-message"/>


                        { post.public_metrics.reply_count < 1000 ? (
                            <div className="data-value">
                                {post.public_metrics.reply_count}
                            </div>
                        ):(
                            <div className="data-value">
                                {parse(' ' + formatCurrencyScale(parseInt(Number(post.public_metrics.reply_count))))}
                            </div>
                        )}


                    </button>
                ):null}

                { post.public_metrics.impression_count > 0 && (
                    <div className="data-item row center">
                        <div style={{opacity:.77}} className="icon-button-eye"/>
                        { post.public_metrics.impression_count < 1000 ? (
                            <div className="data-value">
                                {post.public_metrics.impression_count}
                            </div>
                        ):(
                            <div className="data-value">
                                {parse(' ' + formatCurrencyScale(parseInt(Number(post.public_metrics.impression_count))))}
                            </div>
                        )}

                    </div>
                )}

                <div className="data-item row center">
                    <div className="svg-icon">
                        <RetweetIcon/>
                    </div>

                    { post.public_metrics.retweet_count < 1000 ? (
                        <div className="data-value">
                            {post.public_metrics.retweet_count}
                        </div>
                    ):(
                        <div className="data-value">
                            {parse(' ' + formatCurrencyScale(parseInt(Number(post.public_metrics.retweet_count))))}
                        </div>
                    )}

                </div>

                <div className="data-item row center">
                        <div style={{opacity:.77}} className="icon-button-heart"/>


                        { post.public_metrics.like_count < 1000 ? (
                            <div className="data-value">
                                {post.public_metrics.like_count}
                            </div>
                        ):(
                            <div className="data-value">
                                {parse(' ' + formatCurrencyScale(parseInt(Number(post.public_metrics.like_count))))}
                            </div>
                        )}


                    </div>
                </div>

        </div>
    )
}
