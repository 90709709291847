import React, { useEffect, useState, useMemo, useRef } from "react";

import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useDex } from "../../hooks/useDex";
import { useSocial } from "../../hooks/useSocial";
import { useNavigator } from "../../hooks/useNavigator";

import classNames from "classnames";
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatCurrency, condenseZeros, formatPercent } from "../../utils/currency";
import { addCommas, blankAddress, deadAddress, getTimeDisplay, getTimeDiff, buildToken } from "../../utils/tools";
import { TokenListItemSymbol } from "./TokenListItemSymbol";
import { TokenDataTabs } from "./TokenDataTabs";
import { IconActionButton } from "../IconActionButton";
import { BuyDexOption } from "./BuyDexOption";

import toast from 'react-simple-toasts';
import { userFromJSON } from "opensea-js/lib/utils/utils";
var parse = require('html-react-parser');

export function TokenListItem({ l=null, passClick=null, editList=null, hideOptions=false }) {

    const navigator = useNavigator();

    const now = new Date();

    const optionsref = useRef(null);

    const [ optionsMenu, setOptionsMenu ] = useState(false);

    const [priceChange, setPriceChange] = useState({
        m5: 0,
        h1: 0,
        h6: 0,
        h24: 0
    });

    const {
        walletAddress,
        adminWallet,
        user
    } = useWallet();

    const {
        toggleFollow,
        follows
    } = useSocial();

    const {
        duplicateTokenList,
        deleteTokenList
    } = useDex();

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );

    useEffect(() => {
		const checkIfClickedOutside = e => {
            e.preventDefault();
            e.stopPropagation();
            if ( optionsMenu && optionsref.current && !optionsref.current.contains(e.target) && !e.target.classList.contains('icon-ellipsis-horizontal') ) {
                setOptionsMenu(false);
            }
        }

		document.addEventListener("mouseup", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("mouseup", checkIfClickedOutside)
		}

	}, [optionsMenu]);

    useMemo(() => {

        let pc = {
            m5: 0,
            h1: 0,
            h6: 0,
            h24: 0
        }
        
        for(var i=0;i<l.tokens.length;i++){
            if(l.tokens[i].pair !== undefined){
                pc.m5 += l.tokens[i].pair.data.priceChange.m5;
                pc.h1 += l.tokens[i].pair.data.priceChange.h1;
                pc.h6 += l.tokens[i].pair.data.priceChange.h6;
                pc.h24 += l.tokens[i].pair.data.priceChange.h24;
            }
           
        }

        pc.m5 = pc.m5/l.tokens.length;
        pc.h1 = pc.h1/l.tokens.length;
        pc.h6 = pc.h6/l.tokens.length;
        pc.h24 = pc.h24/l.tokens.length;

        setPriceChange(pc);
        
       

	}, [ l ]);

    const optionsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }

    const followUser = () => {

		let d = {
            uid: user._id,
            ftype: "user",
            fid: l.owner._id
        }

        if(userFollowed){
            d.atype = "remove";
        }else{
            d.atype = "add";
        }

        toggleFollow(d);
	}

    const followList = () => {
		
        let d = {
            uid: user._id,
            ftype: "tokenList",
            fid: l._id
        }

        if(listFollowed){
            d.atype = "remove";
        }else{
            d.atype = "add";
        }

        toggleFollow(d);
	}

    const duplicateList = async () => {
		
		await duplicateTokenList({
            lid: l._id,
			wa: walletAddress
		});
        setOptionsMenu(false);
        toast(parse('<span className="mute">list</span> duplicated'));
	}

    const onEditList = async() => {
        
        await passClick(l);
        editList(l, true);
       
		// editTokenList({
        //     _id: list._id,
		// 	featuredToken: token
		// });
	}

    const deleteList = async () => {
         
        if(window.confirm(`Delete list : ${l.name}?`)){
            deleteTokenList({lid: l._id, wa: walletAddress, name: l.name});
        }
    }

    const copyLink = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toast(parse('<span className="mute">link</span> copied'));
    }

    const openUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        navigator.push(`/${l.owner.username}`)
       
    }

    const tagClick = (e) => {
        console.log(e)
        let it = e.target.innerText.split("\n")[1]
        e.preventDefault();
        e.stopPropagation();
        // toast(parse('<span className="mute">tag</span> click'));
        navigator.push(`/tag/${it}`)
    }

    const listFollowed = follows && l ? follows.lists.find(item => item.list && item.list._id === l._id) : false;
    const userFollowed = follows && l ? follows.users.find(item => item.user._id === l.owner._id) : false;

    const imgSrc = l.owner.avatarFile ? `${process.env.REACT_APP_MEDIA_URL}${l.owner.avatarFile}?t=${Math.floor(new Date().getTime() / (1000 * 60 * 60))}` : require('../../images/default_profile_avatar.jpg');

    return (
        <div onClick={() => passClick(l)} className="list-item grid-item interactive data-panel">
            <div className="item-header">
                
                <div style={{paddingTop:0}} class="data-row full transparent flex-row align center">
                    
                    <div onClick={openUser} style={{paddingRight:3, marginRight:7,alignItems:'center'}} className="data-panel interactive">

                         {/* { walletAddress !== l.walletAddress ? ( */}
                            <>
                                <div style={{marginRight:5}} className="user-avatar"><img src={imgSrc} /></div>
                                <div style={{marginRight:11,maxWidth:111}} className="data-title highlight word-break">{l.owner.name.length > 3 ? l.owner.name : l.owner.username}</div>
                            </>
                        {/* ):null} */}
                    </div>

                    <div className="icon-button-list"/>
                    <div style={{marginRight: 0, marginTop: 2, borderColor: l.color, background: l.color}} className="color-box"></div>
                    <p style={{width:"auto",margin:0,marginLeft:7}}>{l.name}</p>
                   
                    <div style={{marginLeft:7}} className="count-circle">{l.tokens.length}</div>
                    <div style={{marginTop:0,marginLeft:0,transform:'scale(.84)'}} className="coin-links small">

                        <CopyToClipboard
                                text={`${process.env.REACT_APP_ARTEMIS_URL}/list/${l.slug}`}
                        >   
                            <button data-tip={`copy list URL`} className="blank" onClick={copyLink}>
                                <div onClick={null} data-tip={`copy link`} className="coin-link">
                                    <div style={{fontSize:14}} className="icon-button-copy"/>
                                </div>
                            </button>
                            
                        </CopyToClipboard>

                    </div>
                </div>
            </div>

            { optionsMenu ? (
                <div onClick={toggleOptionsMenu}
                style={{

                    // pointerEvents: walletAddress ? "fill" : "none",
                    width:'calc( 100% - 8px )',
                    height:'100%',
                    position:'absolute',
                    zIndex:1
                }}/>
            ):(
                <></>
            )}


            { !hideOptions ? (
                <div style={{
                    // pointerEvents: walletAddress ? "all" : "none",
                    position:'absolute',
                    top:4,
                    right:4,
                    zIndex: optionsMenu ? 222 : 1}} >
                    <IconActionButton
                    addClass="transparentbg relative"
                    type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }
                    active={optionsMenu}
                    passClick={toggleOptionsMenu}/>
                </div>
            ):null}

            { optionsMenu && (

                <>
                    { !walletAddress ? (
                        <div onClick={optionsClick} style={{right:0, top: 0}} ref={optionsref} className="options-menu">

                            <BuyDexOption/>
                        </div>
                        
                    ):(
                        <>
                            <div onClick={optionsClick} style={{right:0, top: 0,zIndex:199}} ref={optionsref} className="options-menu">


                                <div style={{marginTop:1,padding:'12px 9px'}} className="options-header">
                                    <i className="icon-button-list"/>
                                    
                                </div>


                                { l ? (
                                    <>


                                        { l.walletAddress !== walletAddress ? ( 
                                            <div
                                                //style={{ backgroundColor: collections[key].color }}
                                                className={classNames("option")}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    followUser();
                                                }}
                                            
                                            >
                                            
                                                { userFollowed ? (
                                                    <>
                                                        <i style={{fontSize:15}} className="icon-button-user-minus"/>
                                                        <p>unfollow user</p>
                                                        <div className="plus-sign">
                                                            <i className="icon-button-minus red"/>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i style={{fontSize:15}} className="icon-button-user-plus"/>
                                                        <p>follow user</p>
                                                        <div className="plus-sign">
                                                            <i className="icon-button-plus green"/>
                                                        </div>
                                                    </>
                                                )}
                                                

                                                
                                            </div>
                                        ):null}
                                            

                                        { l.walletAddress !== walletAddress ? (    
                                            <div
                                                //style={{ backgroundColor: collections[key].color }}
                                                className={classNames("option")}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    followList();
                                                }}
                                            
                                            >
                                            
                                                { listFollowed ? (
                                                    <>
                                                        <i style={{fontSize:15}} className="icon-button-list"/>
                                                        <p>unfollow list</p>
                                                        <div className="plus-sign">
                                                            <i className="icon-button-minus red"/>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i style={{fontSize:15}} className="icon-button-list"/>
                                                        <p>follow list</p>
                                                        <div className="plus-sign">
                                                            <i className="icon-button-plus green"/>
                                                        </div>
                                                    </>
                                                )}
                                                

                                            
                                            </div>
                                        ):null}
                                        
                                        { l.walletAddress == walletAddress ? (
                                            <div
                                                //style={{ backgroundColor: collections[key].color }}
                                                className={classNames("option")}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    onEditList();
                                                }}
                                            
                                            >
                                            

                                                <i style={{fontSize:18}} className="icon-pencil"/>
                                                <p style={{marginLeft:5}}>edit list</p>

                                                <div className="plus-sign">
                                                    <i className="icon-button-pencil"/>
                                                </div>
                                            </div>
                                        ):null}
                                        


                                        <div
                                            //style={{ backgroundColor: collections[key].color }}
                                            className={classNames("option")}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                duplicateList();
                                            }}
                                        
                                        >
                                            <div style={{
                                                backgroundColor: l.color,
                                                borderColor: l.color,
                                                marginRight: 7
                                            }} className="color-box"/>

                                            <i style={{fontSize:13}} className="icon-button-copy"/>
                                            <p>duplicate</p>

                                            <div className="plus-sign">
                                                <i className="icon-button-copy"/>
                                            </div>
                                        </div>

                                        { l.walletAddress == walletAddress ? (
                                            <div
                                                //style={{ backgroundColor: collections[key].color }}
                                                className={classNames("option")}
                                                onClick={deleteList}

                                            >
                                                {/*<div className="svg-icon">
                                                    <WalletIcon/>
                                                </div>*/}
                                                <p className="red">delete</p>

                                                <div className="plus-sign">
                                                    <i className="icon-button-minus red"/>
                                                </div>
                                            </div>
                                        ):null}
                                    
                                    
                                    </>
                                    
                                ):null}

                                </div>
                        </>
                    )}
                </>
                
            )}

            <div style={{padding:'9px 0px 9px 17px',marginBottom:0,flexWrap: "wrap", maxWidth: "84%" }} className="flex-row">
                {l.tokens.map((t,i) => (
                    <TokenListItemSymbol addClass="overlap" i={i} token={t}/>
                ))}

                
            </div>

            <div style={{marginBottom:22 }} className="flex-row">
                
                <TokenDataTabs
                    addStyle={{minWidth:255, paddingTop:0}}
                    title="price"
                    bookend={true}
                    values={[priceChange.m5,
                        priceChange.h1,
                        priceChange.h6,
                        priceChange.h24]}
                    valuesFormat={['percent','percent','percent','percent']}
                    labels={['5m','1h','6h','24h']}
                />

                
            </div>

            

            

            <div style={{position:"absolute",display:"flex",zIndex:3,alignItems:"flex-end",bottom:5,left:3}}>

                <div style={{opacity:.7,marginRight:2,marginLeft:2}} className="icon-button-edit"/>
               
                <div style={{lineHeight:'11px',transform:'scale(.88)',opacity:.77}} className="data-value">{getTimeDisplay(l.updated)}</div>
                <div style={{opacity:.7,marginRight:2,marginLeft:7}} className="icon-button-clock"/>
               
                <div style={{lineHeight:'11px',transform:'scale(.88)',opacity:.77}} className="data-value">{getTimeDisplay(l.created)}</div>
                
                
            </div>


            <div style={{position:"absolute",display:"flex",zIndex:3,alignItems:"center",top:11,right:37}}>
                

                { l.admin ? (
                    <div style={{opacity:.7}} className="icon-button-settings"/>
                ):null}

                { l.private ? (
                    <div style={{opacity:.7}} className="icon-button-lock"/>
                ):null}

                { l.live ? (
                    <div style={{marginRight: 7, marginTop: 2, borderColor: "#01be8c", background: "#01be8c"}} className="color-box"></div>
                ):null}

                { l.community ? (
                    <div style={{opacity:.7}} className="icon-button-globe"/>
                ):null}
                
            </div>


            { l.tags?.length || l.followers || listFollowed || userFollowed ? (
                <div style={{position:"absolute",display: "flex", flexDirection:"column", bottom:5,right:1,zIndex:111}} className="flex-row">
                    
                    <div style={{
                        
                        display:"flex",
                        flexDirection: "row",
                        zIndex:3,
                        alignItems:"center",
                        justifyContent:'flex-end',
                        transform: 'scale(.88)',
                        // opacity: .7,
                        transformOrigin: "right",
                        marginRight:7
                    }}>

                        { listFollowed ? (
                            
                            <div style={{opacity:1,margin: 0}} className="icon-button-list green"/>
                        
                        ):null}

                        { userFollowed ? (
                            
                                
                            <div style={{opacity:1,margin: 0,marginLeft:7}} className="icon-button-user green"/>
                        
                        ):null}

                    </div>


                    { l.followers ? (

                        <div style={{
                        
                            display:"flex",
                            flexDirection: "row",
                            zIndex:3,
                            alignItems:"center",
                            justifyContent:'flex-end',
                            transform: 'scale(.88)',
                            // opacity: .7,
                            transformOrigin: "right",
                            marginRight:7,
                            marginTop: 7
                        }}>
                            
                                
                            <div style={{opacity:.7,margin: 0,marginRight:7}} className="icon-button-user"/>
                            <div className="data-value">{l.followers}</div>

                        </div>

                    ):null}
                    
                    
                    { l.tags && l.tags.length ? (
                        <div style={{marginTop:7}} className="flex-row">
                            { l.tags
                            
                            .map((tag,i) => (
                                
                                <div onClick={tagClick} key={i} style={{ background: l.color }} className="social-item-tag interactive hash"><span className="mute">#</span>{tag}</div>
                                
                            ))}
                        </div>
                    ):null}
                    

                    

                </div>
            ):null}

           


            

            {/* <p>{priceChange.h24}</p> */}
        </div>
    );
}


