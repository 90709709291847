import React, { useEffect } from "react";

import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";

export function NFTTraits() {

    let { nft } = useWallet();
    let { sidebarRight } = useCore();

    const handleClick = () => {

    }

    return (



        <div className="nft-traits">

            <div className="panel-title">Traits</div>

            { sidebarRight && nft && nft.traits !== undefined && (

                <div className="flex-row wrap">

                    { nft.traits.map((trait, i) => (
                        <div className="nft-trait">
                            <div className="data-item">
                                <div className="data-title">
                                    {trait.trait_type}
                                </div>
                                <div className="data-value">
                                    {trait.value}
                                </div>

                                <div className="data-value">
                                    {(100*(trait.trait_count / nft.collection.stats.count)).toFixed(4)}%
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            )}


        </div>
    )
}
