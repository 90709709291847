import React, { useState, useEffect, useContext, createContext } from "react";
import { debounce, find } from "lodash";
import { client } from "../../services/api";
import { client_cg } from "../../services/api_coingecko";
import { client_twitter } from "../../services/api_twitter";
import { client_debank } from "../../services/api_debank";
import { chains, colorSwatches, coinColors, coinColorsDefault } from "../../utils/data";
import { getRandomColor, normalizePrices, processCapData, rgbToHex } from "../../utils/tools";

import { useCore } from "../useCore";
import moment from 'moment';

import ColorThief from 'colorthief';


const marketContext = createContext();

var compColors = require('complementary-colors');



function useMarketProvider(token) {

    const [loading, setLoading] = useState([]);
    const [candlesLoading, setCandlesLoading] = useState([]);
    const [exchanges, setExchanges] = useState([]);

    const [coinCurrent, setCoinCurrent ] = useState({id: null});
    const [coinCompare, setCoinCompare ] = useState({ id: null });
    const [coinsTrending, setCoinsTrending] = useState({});
    const [priceLoadedTrending, setPriceLoadedTrending] = useState(false);
    const [coins, setCoins] = useState({});
    const [coinsTest, setCoinsTest] = useState([]);
    const [coinSymbols, setCoinSymbols] = useState({});

    const [coinTabs, setCoinTabs] = useState({});
    const [coinTabsChartVisible, setCoinTabsChartVisible] = useState({});

    const [marketShare, setMarketShare] = useState([]);
    const [defiMarketCap, setDefiMarketCap] = useState(0);
    const [defiData, setDefiData] = useState({});

    const [chartFilterTime, setChartFilterTime ] = useState('1');

    const [marketDefi, setMarketDefi ] = useState({});

    const [ coinOrder, setCoinOrder ] = useState('market_cap_desc'); // volume, cap
    const [ coinCategory, setCoinCategory ] = useState('layer-1');


    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);

    const { page } = useCore();



    useEffect(() => {
        // console.log(priceLoadedTrending)
        if(!priceLoadedTrending){

            let coinsids = '';
            let i=0;
            for(var key in coinsTrending){

                if(i>0){
                    coinsids+=",";
                }
                i++;
                coinsids += coinsTrending[key].id;
                // console.log(coinsTrending[key].id);
            }

            if(i>0){
                fetchCoinPrices(coinsids);
                setPriceLoadedTrending(true);
            }

        }


    }, [ coinsTrending ]);

    useEffect(() => {

        // if(coins['bitcoin'] !== undefined && defiData.market_cap_percentage !== undefined && !marketShare.length){
        //     let ms = [];
        //     let i = 0;
        //     let restMarket = 100;
        //     for(var key in defiData.market_cap_percentage){
        //         ms.push({
        //             title: key,
        //             value: defiData.market_cap_percentage[key],
        //             color: coinColors[key] !== undefined ? coinColors[key] + '99' : colorSwatches[i] + '99',
        //         });
        //         restMarket -= defiData.market_cap_percentage[key];
        //         i++;
        //     }
        //     let defiPercent = defiMarketCap / ((coins['bitcoin'].market_cap/defiData.market_cap_percentage['btc']));
        //     // let defiPercent = 100;
        //     console.log('defi percent',defiPercent, coins);
        //
        //     restMarket -= defiPercent;
        //     ms.push({
        //         title: 'rest',
        //         value: restMarket,
        //         color: colorSwatches[11] + '99',
        //
        //     });
        //     ms.push({
        //         title: 'Defi',
        //         value: defiPercent,
        //         color: colorSwatches[10] + '99',
        //     })
        //     console.log('market share',ms)
        //     setMarketShare(ms);
        //
        //
        // }


    }, [ coins ]);




    useEffect(() => {
        if(coinCurrent.id){
            if(coinCurrent.candles[chartFilterTime] == undefined){
                fetchCoinCandles(coinCurrent.id);
            }

            // fetchCoinSnapshot(coinCurrent.id);
        }
    }, [ coinCurrent.id ]);


    useEffect(() => {
        // fetchExchanges().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });


        // fetchExchange('uniswap').catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });

        // fetchAssetPlatforms().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });
        //
        // fetchCoinCategories().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });


        // 13000 results
        // fetchCoinList().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });



        let initCoinParams = {
            category: coinCategory,
            ids: "apecoin,cult",

            order: coinOrder,
            // type: 'layer-1',
        }
        //
        // fetchCoinsMarkets(initCoinParams).catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });
        //




        {/*fetchDefiData().catch((error) => {
            setError(error);
            setHasError(true);
        });




        fetchMarketData().catch((error) => {
            setError(error);
            setHasError(true);
        });*/}




        // fetchCoinChart('bitcoin').catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });

        // fetchContractChart('ethereum','0x0913dDAE242839f8995c0375493f9a1A3Bddc977').catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });
        //


        //mri
        // fetchContractData('ethereum','0x0913dDAE242839f8995c0375493f9a1A3Bddc977').catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });



        // fetchSupportedCurrencies().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });
        //



        //
        // fetchTrendingCoins().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });
        //
        // fetchCoinSnapshot('bitcoin').catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });






        // fetchMarketIndexes().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });
        //
        // fetchMarketDerivatives().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });



        // searching tweets working, will put into loading sequence of token
        // fetchTweets('HAPE').catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });

        // getCurrentUserTweets().catch((error) => {
        //     setError(error);
        //     setHasError(true);
        // });


    }, []);



    async function getCurrentUserTweets() {
        setLoading(true);
        console.log('go >>>>>>>>>>>>>>')
        const tweetsResponse = await client.getCurrentTweets();

        const _tweets = tweetsResponse.data || [];
        console.log(_tweets);

        // receiveExchanges(_exchanges);

        setLoading(false);
    }

    async function fetchTweets(query) {
        setLoading(true);

        const tweetsResponse = await client.searchTweetsRecent(query);

        const _tweets = tweetsResponse.data || [];
        console.log(_tweets);

        // receiveExchanges(_exchanges);

        setLoading(false);
    }

    async function fetchSupportedCurrencies() {
        setLoading(true);
        const response = await client_cg.getSupportedCurrencies();
        const _currencies = response.data || [];
        console.log(_currencies);
        setLoading(false);
    }

    async function fetchAssetPlatforms() {
        setLoading(true);
        const response = await client_cg.getAssetPlatforms();
        const _platforms = response.data || [];
        console.log(_platforms);
        setLoading(false);
    }

    async function fetchCoinList() {
        setLoading(true);
        const response = await client_cg.getCoinList();
        const _platforms = response.data || [];
        console.log(_platforms);
        setLoading(false);
    }

    async function fetchCoinCategories() {
        setLoading(true);
        const response = await client_cg.getCoinCategories();
        const _categories = response.data || [];
        console.log(_categories);
        setLoading(false);
    }

    async function fetchCoinsMarkets(data) {
        setLoading(true);
        const response = await client_cg.getCoinsMarkets(data);


        const _market = response.data || [];
        // if(data.type=="init"){
        //     data.category = 'trending'
        // }
        let symbols = {...coinSymbols};

        // console.log("get coins markets",response);
        let coinstemp = {...coins};

        _market.forEach((c) => {
            let color = coinColors[c.symbol] !== undefined ? coinColors[c.symbol] : coinColorsDefault.primary;

            if(coins[c.id] !== undefined){
                console.log('has key !!!!!!!!!!!!!!!!!!!!');
                let cs = [...coins[c.id].cats];
                if(!cs.includes(data.category)){
                    cs.push(data.category);
                }
                coinstemp[c.id] = { ...coins[c.id], ...c, color, cats: [...cs] };
            }else{
                coinstemp[c.id] = { ...c, candles: [], color, cats: [ data.category ] };
                symbols[c.symbol] = c.id;
            }

        });
        setCoins(coinstemp);
        setCoinSymbols(symbols);

        console.log('coins', coinstemp);

        console.log('SYMBOLS !!!!!!', symbols, coinSymbols);




        // console.log(category,_market);
        setLoading(false);
    }

    async function fetchMarketData() {
        setLoading(true);
        const response = await client_cg.getMarketData();
        const _defidata = response.data || [];
        console.log('market data',_defidata);

        let d = _defidata.data;
        setDefiData(d);


        //
        //
        // { title: 'One', value: 10, color: '#E38627' },
        // { title: 'Two', value: 15, color: '#C13C37' },
        // { title: 'Three', value: 20, color: '#6A2135' },


        setLoading(false);
    }

    async function fetchDefiData() {
        setLoading(true);
        const response = await client_cg.getDefiData();
        const _defidata = response.data || [];

        setDefiMarketCap(Number(_defidata.data.defi_market_cap));
        console.log('defi data',Number(_defidata.data.defi_market_cap));

        setLoading(false);
    }

    async function fetchMarketIndexes() {
        setLoading(true);
        const response = await client_cg.getMarketIndexes();
        const _defidata = response.data || [];
        console.log("indexes",_defidata);
        setLoading(false);
    }

    async function fetchMarketDerivatives() {
        setLoading(true);
        const response = await client_cg.getMarketDerivatives();
        const _defidata = response.data || [];
        console.log("derivatives",_defidata);
        setLoading(false);
    }

    async function fetchTrendingCoins() {
        setLoading(true);
        const response = await client_cg.getTrendingCoins();
        const _defidata = response.data || [];

        let coinstemp = {};
        _defidata.coins.forEach((c) => {
            coinstemp[c.item.id] = {...c.item};
        });

        console.log(coinstemp)
        // setCoinsTest(coinstemp)
        // // for(var i=0;i<_defidata.coins.length;i++){
        // //
        // //     coinstemp[_defidata.coins[i].item.id] = {..._defidata.coins[i].item};
        // // }


        setCoinsTrending(coinstemp);

        setLoading(false);

    }

    async function fetchCoinPrices(idarray) {
        setLoading(true);
        const response = await client_cg.getCoinPrices(idarray);

        console.log('trending',coinsTrending)

        const _coinprices = response.data || {};
        console.log("coin prices",_coinprices);
        let coinscopy = {...coinsTrending};

        for(var key in _coinprices){
            console.log(key);
            coinscopy[key] = {...coinscopy[key], ..._coinprices[key]};
        }
        console.log(coinscopy)
        setCoinsTrending(coinscopy);

        setLoading(false);
    }

    async function fetchExchanges() {
        setLoading(true);

        const exchangesResponse = await client_cg.getExchanges();

        const _exchanges = exchangesResponse.data || [];
        console.log(_exchanges);

        // receiveExchanges(_exchanges);

        setLoading(false);
    }

    async function fetchExchange(id) {
        setLoading(true);

        const exchangeResponse = await client_cg.getExchange(id);

        const _exchange = exchangeResponse.data || [];
        console.log(_exchange);

        // receiveExchanges(_exchanges);

        setLoading(false);
    }



    async function fetchCoinCandles(id) {
        setCandlesLoading(true);
        let data = {
            id: id,
            days: chartFilterTime
        }
        const response = await client_cg.getCoinCandles(data);
        const _data = response.data || [];
        let candlestemp = [];
        let linevalues = [];

        console.log('candles loaded!!!', _data)
        for(var i=0;i<_data.length;i++){

            candlestemp.push({
                // time: moment(_data[i][0]).format('YYYY-MM-DD'),
                time: _data[i][0]/1000,
                open: _data[i][1],
                high: _data[i][2],
                low: _data[i][3],
                close: i==(_data.length-1) ? coins[id].current_price || coins[id].usd : _data[i][4]
            })
            linevalues.push({
                time: _data[i][0]/1000,
                value: i==(_data.length-1) ? coins[id].current_price || coins[id].usd : _data[i][4]
            })

        }
        console.log('coin getting candles',coins[id])
        console.log(candlestemp,linevalues)
        let candlestempcap = processCapData(candlestemp, coins[id].current_price, coins[id].market_cap);
        let capData = processCapData(linevalues, coins[id].current_price, coins[id].market_cap);
        let normalData = normalizePrices(linevalues, 0, coins[id].ath);
        let current = {
            ...coins[id],
            candles: { [data.days]: candlestemp},
            candlesCap: { [data.days]: candlestempcap},
            // candlesNormal: { [data.days]: candlestempnormal}
            lineData: { [data.days]: linevalues},
            capData: { [data.days]: capData},
            normalData: { [data.days]: normalData }
        };
        // let current = {...coinCurrent, candles: candlestemp, lineData: linevalues, normalData };
        // console.log('candles current',current);
        // setCoinCurrent(current);

        let coinstemp = {...coins, [id]: { ...current } };

        setCoins(coinstemp);

        addCoinTab(page, current.id);



        // if(tabs[c.id] == undefined){
        //     tabs[c.id] = {...c};
        //     setCoinTabs(tabs);
        //     console.log('coin tabs', coinTabs);
        // }



        setCandlesLoading(false);
    }

    async function fetchCoinChart(id, time="max") {
        setLoading(true);
        console.log(id,time);
        let data = {
            id: id,
            days: time,
        }
        const chartResponse = await client_cg.getCoinChart(data);
        const _data = chartResponse.data || [];
        console.log('chart incoming',_data);
        let linevalues = [];
        let volumes = [];
        for(var i=0;i<_data.prices.length;i++){
            linevalues.push({
                time: _data.prices[i][0]/1000,
                value: _data.prices[i][1]
            })
            volumes.push({
                time: _data.total_volumes[i][0]/1000,
                value: _data.total_volumes[i][1]
            })
        }
        let normalvalues = normalizePrices(linevalues, coins[id].ath);
        let lineData = {...coins[id].lineData, [time]: linevalues };
        let normalData = {...coins[id].normalData, [time]: normalvalues };
        let volumeData = {...coins[id].volumeData, [time]: volumes };
        let coinstemp = {...coins, [id]: { ...coins[id], lineData, normalData, volumeData } };
        console.log(coinstemp);

        setCoins(coinstemp);

        setLoading(false);
    }

    function addCoinTab( tabkey, id){

        let inArray = false;
        let tabs = {...coinTabs};

        if(tabs[tabkey] == undefined){
            tabs[tabkey] = {};
        }
        for(var key in tabs[tabkey]){
            if(tabs[tabkey][key] == id){
                inArray = true;
            }
        }

        if(!inArray){
            let temptabs = { ...tabs, [tabkey]: {...tabs[tabkey], [id]: { chartvisible: true } } };
            setCoinTabs(temptabs);
            console.log('in market add coin to tab',temptabs);
        }


        // let inArray = false;
        // let tabs = {...coinTabs};
        // let tabsv = {...coinTabsChartVisible};
        //
        // if(tabs[tabkey] == undefined){
        //     tabs[tabkey] = [];
        //     tabsv[tabkey] = [];
        // }
        // for(var i=0;i<tabs[tabkey].length;i++){
        //     if(tabs[tabkey][i] == id){
        //         inArray = true;
        //     }
        // }
        //
        // if(!inArray){
        //     let temptabs = { ...tabs, [tabkey]: [...tabs[tabkey], id ] };
        //     let temptabsv = { ...tabs, [tabkey]: [...tabs[tabkey], true ] };
        //
        //
        //     setCoinTabs(temptabs);
        //
        //
        // }

    }

    async function fetchCoinSnapshot(id) {
        setLoading(true);
        const response = await client_cg.getCoinSnapshot(id);

        const _data = response.data || [];

        let coinstemp = {...coins};

        if(coins[id] !== undefined){

            coinstemp[id] = { ...coins[id], meta: { ..._data } };
        }else{
            coinstemp[id] = { ..._data };
        }

        setCoins(coinstemp);


        setLoading(false);
    }

    async function fetchContractChart(id, contract) {
        setLoading(true);

        const chartResponse = await client_cg.getContractChart(id, contract);

        const _chart = chartResponse.data || [];

        // receiveExchanges(_exchanges);

        setLoading(false);
    }


    async function fetchContractData(id, contract) {
        setLoading(true);

        const response = await client_cg.getContractData(id, contract);

        const _data = response.data || [];

        // receiveExchanges(_exchanges);

        setLoading(false);
    }



    const handleCoinCurrent = (id) => {


        setCoinCompare(coinCurrent);
        setCoinCurrent(coins[id]);
        // getColorFromImage(update);
    }

    const updateCoinTabs = (tabcollection, update) => {
        let tabs = { ...coinTabs };
        tabs[tabcollection] = { ...coinTabs[tabcollection], ...update};
        setCoinTabs(tabs);
    }

    const removeCoinTab = (p,cid) => {

        // let tabs = { ...coinTabs };
        let subtabs = {};
        for(var key in coinTabs[p]){
            if(key !== cid){
                subtabs[key] = {...coinTabs[p][key]};
            }
        }
        let tabs = { ...coinTabs, [p]: {...subtabs}};
        setCoinTabs(tabs);
    }

    // const getColorFromImage = (coin) => {
    //     console.log('get image',coin);
    //     const colorThief = new ColorThief();
    //     let img = document.querySelector('img.coin-icon.'+coin.id);
    //     let p = null;
    //     console.log(img);
    //     if (img.complete) {
    //       p = colorThief.getPalette(img);
    //       console.log('image',coin.id,p)
    //     } else {
    //       img.addEventListener('load', function() {
    //         p = colorThief.getPalette(img);
    //         console.log('image',coin.id,p)
    //       });
    //     }
    // }

    const handleCoinOrder = (update) => {
        setCoinOrder(update);
    }

    const handleCoinCategory = (cat) => {
        setCoinCategory(cat);
    }

    const handleMarketDefi = (update) => {
        setMarketDefi(update);
    }

    function handleChartFilterTime(data){
        setChartFilterTime(data);
    }


    return {
        loading,
        coinCurrent,
        coinCompare,
        coinTabs,
        updateCoinTabs,
        removeCoinTab,
        coins,
        coinSymbols,
        handleCoinCurrent,
        coinsTrending,
        handleCoinOrder,
        handleMarketDefi,
        coinOrder,
        handleCoinOrder,
        coinCategory,
        handleCoinCategory,
        fetchCoinsMarkets,
        fetchCoinChart,
        fetchCoinCandles,
        marketShare,
        defiMarketCap,
        chartFilterTime,
        handleChartFilterTime,
        candlesLoading
    };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideMarket({ token, children }) {
    const market = useMarketProvider(token);
    return (
        <marketContext.Provider value={market}>{children}</marketContext.Provider>
    );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useMarket = () => {
    return useContext(marketContext);
};
