import React, { useState, useEffect } from "react";
import classNames from "classnames";

export function LoaderSpinner({ bgsize, loadersize }) {
    const [ invert, setInvert] = useState(0);
    //
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         let i = invert + 1;
    //         if(i>1){
    //             i=0;
    //         }
    //       setInvert(i)
    //   }, 2000);
    //     return () => clearInterval(interval);
    // }, [ invert ]);

    return (
        <div className="loader-spinner" style={{ width: bgsize ? bgsize : '', height: bgsize ? bgsize : ''}}>

			<div className={classNames("zpprblock", invert == 1 ? "inverth" : invert == 2 ? "invertv" : '')}
            style={{ width: loadersize ? loadersize : '', height: loadersize ? loadersize : ''}}>
                <div className="cell cellone"/>
                <div className="cell celltwo"/>
                <div className="cell cellthree"/>
                <div className="cell cellfour"/>
                {/*<div className="cell cellone">
                    <div className="letter">
                        a
                    </div>
                </div>*/}

			</div>


        </div>
    );
}
