// a library to wrap and simplify api calls
import apisauce from "apisauce";

// our "constructor"
export const create = (baseURL = process.env.REACT_APP_COINGECKO_API_URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            "Cache-Control": "no-cache",
            'X-RapidAPI-Host': process.env.REACT_APP_COINGECKO_API_HOST,
            'X-RapidAPI-Key': process.env.REACT_APP_COINGECKO_KEY
        },
        // 10 second timeout...
        timeout: 10000,
    });

    const getSupportedCurrencies = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/simple/supported_vs_currencies`
        );
    };

    const getAssetPlatforms = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/asset_platforms`
        );
    };

    const getCoinCategories = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/categories/list`
        );
    };

    const getCoinList = () => {
        let data = {
            include_platform: true
        }
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/list`,
            data
        );
    };

    const getCoinsMarkets = (passdata) => {
        let data = {
            ids: passdata.ids !== undefined ? passdata.ids : '',
            include_platform: true,
            vs_currency:'usd',
            category: passdata.category !== undefined && passdata.category !== "all" ? passdata.category : null,
            order: passdata.order !== undefined ? passdata.order : 'market_cap_desc',// market_cap_desc, gecko_desc, gecko_asc, market_cap_asc, market_cap_desc, volume_asc, volume_desc, id_asc, id_desc
            price_change_percentage: '1h,24h,7d',
        }

        // console.log('coin market get', data);
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/markets`,
            data
        );
    };

    const getMarketData = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/global`
        );
    };

    const getExchangeRates = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/exchange_rates`
        );
    };

    const getMarketIndexes = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/indexes`
        );
    };

    const getMarketDerivatives = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/derivatives`
        );
    };

    const getDefiData = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/global/decentralized_finance_defi`
        );
    };

    const getTrendingCoins = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/search/trending`
        );
    };


    const getExchanges = () => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/exchanges/list`
        );
    };

    const getExchange = (id) => {
        console.log(`${process.env.REACT_APP_COINGECKO_API_URL}/exchanges/${id}`)
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/exchanges/${id}`
        );
    };

    const getCoinChart = (data) => {
        let d = {
            vs_currency:'usd',
            days:data.days !== undefined ? data.days : 'max',
            interval:'hourly'
        }
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/${data.id}/market_chart`,
            d
        );
    };

    const getCoinCandles = (data) => {
        let d = {
            id: data.id,
            vs_currency:'usd',
            days:data.days,  // 1/7/14/30/90/180/365/max
            // include_volume: true,
        }
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/${data.id}/ohlc`,
            d
        );
    };




    const getCoinPrices = (ids) => {
        let data = {
            ids: ids,
            vs_currencies:'usd',
            include_market_cap: true,
            include_24hr_vol: true,
            include_24hr_change: true,
            include_last_updated_at: true,
            include_asset_platform: true,
            days:'30',
            interval:'daily'
        }
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/simple/price`,
            data
        );
    };

    const getCoinSnapshot = (id) => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/${id}`
        );
    }

    const getContractData = (id, contractid) => {
        let data = {
            vs_currency:'usd',
            days:'30',
            interval:'hourly'
        }
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/${id}/contract/${contractid}`,
            data
        );
    };


    const getContractChart = (id, contractid) => {
        let data = {
            vs_currency:'usd',
            days:'30',
            interval:'hourly'
        }
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/${id}/contract/${contractid}/market_chart/`,
            data
        );
    };


    const getTokenByContract = (ca) => {
        return api.get(
            `${process.env.REACT_APP_COINGECKO_API_URL}/coins/ethereum/contract/${ca}`
        );
    };


    // return functions for interface
    return {
        getSupportedCurrencies,
        getAssetPlatforms,
        getCoinCategories,
        getCoinsMarkets,
        getMarketData,
        getDefiData,
        getExchangeRates,
        getMarketIndexes,
        getMarketDerivatives,
        getTrendingCoins,
        getCoinList,
        getExchange,
        getExchanges,
        getCoinChart,
        getCoinSnapshot,
        getCoinPrices,
        getCoinCandles,
        getContractData,
        getContractChart,
        getTokenByContract,
    };
};

// Creates a singleton
export const client_cg = create();


//
//
//
// const options = {
//   method: 'GET',
//   url: 'https://coingecko.p.rapidapi.com/simple/price',
//   params: {ids: '<REQUIRED>', vs_currencies: '<REQUIRED>'},
//   headers: {
//     'X-RapidAPI-Host': 'coingecko.p.rapidapi.com',
//     'X-RapidAPI-Key': '9734a11ae1msh4ba2d2b64c367cep14689djsn47d3abd12758'
//   }
// };
//
// axios.request(options).then(function (response) {
// 	console.log(response.data);
// }).catch(function (error) {
// 	console.error(error);
// });







///////
