import React, { useEffect, useState } from "react";


import { useAuth } from "../../hooks/useAuth";
import { useMarket } from "../../hooks/useMarket";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import { useSocial } from "../../hooks/useSocial";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";

import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";
import { TwitterDashboard } from "../../components/Social/TwitterDashboard";
import { MarketChart } from "../../components/MarketChart";
import { ChartEmbed } from "../../components/MarketChart/ChartEmbed";


export function Social() {

    const navigator = useNavigator();

    const { screens } = useNavigator();


    let { windowDimensions } = useCore();
    let { tokenPair } = useDex();
    let { walletAddress } = useWallet();

    let {
        twitterSearchedUser,
        searchTwitterUsers,
        twitterUsers,
        twitterPosts,
        fetchTwitterUsersByList,
        fetchTwitterUserByUsername,
    } = useSocial();

    const [ bottomSize, setBottomSize ] = useState("50%");
    const [ listSlug, setListSlug ] = useState(null);
    const [ userSlug, setUserSlug ] = useState(null);

    const toggleBtmHeight = (newSize) => {
        setBottomSize(newSize + "px");
    }


    useEffect(() => {

        console.log(screens);
        if(screens.length > 3){
            if(screens[2] == "search" && screens[3].length && !twitterSearchedUser){
                searchTwitterUsers(screens[3]);
            }

            if(screens[2] == "list" && screens[3].length && screens[3] !== listSlug){
                setListSlug(screens[3]);
                fetchTwitterUsersByList(screens[3]);
            }

            if(screens[2] == "user" && screens[3].length && screens[3] !== userSlug){
                setUserSlug(screens[3]);
                fetchTwitterUserByUsername(screens[3],false);
            }

        }

    }, [ screens ]);







    // window.screen.width
    // window.screen.height


    return (
        <Layout>




            <TwitterDashboard visible={true} height={bottomSize}/>

        </Layout>
    );
}
