// a library to wrap and simplify api calls
import apisauce from "apisauce";

// our "constructor"
export const create = (baseURL = process.env.REACT_APP_ETHERSCAN_API_URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'AccessKey': process.env.REACT_APP_ETHERSCAN_API_KEY,
        },
        // 10 second timeout...
        timeout: 10000,
    });



    const getTokenSupply = (ca) => {
        let q = `?module=stats&action=tokensupply&contractaddress=0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`;
        return api.get(
            `${process.env.REACT_APP_ETHERSCAN_API_URL}${q}`
        );
    };


    return {
        getTokenSupply,
    };
};

// Creates a singleton
export const client_etherscan = create();
