import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { CollectionItem } from "../../components/Collection/CollectionItem";
import { AssetToolbar } from "../../components/Wallet/AssetToolbar";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useCollection } from "../../hooks/useCollection";
import { useWallet } from "../../hooks/useWallet";
import { useNavigator } from "../../hooks/useNavigator";

import { QRCodeSVG } from 'qrcode.react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export function Collections() {
    // let navigate = useNavigate();
    let { loading, isAuthenticated, fetchTwitterAuth } = useAuth();
    let { walletAddress, handleNft, nft } = useWallet();
    let { handleModalVisible, handleCollectionModal } = useCore();
    let { collections, fetchCollections } = useCollection();

    const navigator = useNavigator();
    let { contractAddress, handleContractAddress, screens } = useNavigator();



    const newCollection = () => {
        handleCollectionModal(true);
        handleModalVisible(true);
    }

    const onCollectionClick = (c) => {
        console.log(c);
        navigator.push(`/collections/${c.token}`);
        if(c.assets.length){
            handleNft(c.assets[0]);
        }

    }


    const collection = collections.filter(obj => {
		if( screens.length > 2){
			return obj.token == screens[2];
		}else{
			return {};
		}
	});

    const onClickThumb = (i, item) => {
        // handleNft(collection[0].assets[i]);
    }

    const onSlideChange = (i, item) => {

        console.log(collection[0].assets[i])
        handleNft(collection[0].assets[i]);
    }


    return (
        <Layout>
            <Helmet><title>{screens.length > 2 && collection[0] !== undefined ? collection[0].name + " :: Collection" : "Collections :: Artemis"}</title>
            </Helmet>

            {/*<button onClick={newCollection} className="active">New Collection</button>*/}


            <Breadcrumbs/>
            <div className="collections-container">



                { screens.length>2 && nft && nft.asset_contract !== undefined && (
                    <>
                        <div className="nft-stage-toolbar bottom center coin-meta">


                            <div className="coin-meta">
                                <div className="coin-name">{nft.name ? nft.name : `${nft.project.name} #${nft.token_id}`} <span className="mute"> &nbsp;&nbsp;::&nbsp;&nbsp; </span></div>
                                {/*<div className="coin-symbol">test</div>*/}
                            </div>



                            <AssetToolbar collection={collection[0]} nft={nft} ca={nft.asset_contract.address}/>


                        </div>
                        <div className="qr-code-panel">
                            <QRCodeSVG value={`${process.env.REACT_APP_ARTEMIS_URL_IP}/assets/${nft.asset_contract.address}/${nft.token_id}`}
                            size="512"
                            bgColor="#FFFFFF"
                            fgColor="#1d1d1d" />
                        </div>
                    </>
                )}

                { screens[screens.length-1] == "collections" && (
                    <>
                        <div style={{width:'100%',height:88}}/>
                        { Object.keys(collections).map((key, i) => (
                            <CollectionItem key={i} collection={collections[key]} passClick={onCollectionClick}/>
                        ))}
                        <div style={{width:'100%',height:88}}/>
                    </>
                )}

                {/*{ screens.length > 2 && collection[0] !== undefined && (
                    <div className="collection-items">
                        {collection[0].assets.map((a, i) => (
                            <div key={i} className="nft-item">
                                {a.ca}
                            </div>
                        ))}

                    </div>

                )}*/}

                { screens.length > 2 && collection[0] !== undefined && (
                    <Carousel
                        axis="horizontal"
                        // centerMode={true}
                        // centerSlidePercentage={80}
                        // autoPlay={true}
                        // interval={'3300'}
                        infiniteLoop={true}
                        // stopOnHover={false}
                        dynamicHeight={true}
                        useKeyboardArrows={true}
                        thumbWidth={44}
                        onClickThumb={onClickThumb}
                        onChange={onSlideChange}
                    >
                        {collection[0].assets.map((a, i) => (
                            <div key={i}>
                                <img src={a.image_url} />
                                {/*<p className="legend">{a.asset_contract.name} #{a.token_id}</p>*/}
                            </div>
                        ))}

                    </Carousel>
                )}

            </div>

            {/*<div style={{margin: '100px'}}>
                <button onClick={loginTwitter}>login twitter</button>
            </div>*/}

            {/*<TwitterLogin
              authCallback={authHandler}
              consumerKey={process.env.REACT_APP_TWITTER_API_KEY}
              consumerSecret={process.env.REACT_APP_TWITTER_API_KEY_SECRET}
            />*/}
        </Layout>
    );
}
