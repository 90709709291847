import React, { useRef, useEffect, useState, useCallback } from 'react';

import { useMarket } from "../../hooks/useMarket";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";
import { useCore } from "../../hooks/useCore";

import { IconActionButton } from "../IconActionButton";

import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";
import { blockieSeed } from "../../utils/data";
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js'

import classNames from "classnames";


var parse = require('html-react-parser');


export function TickerBlock({ stylePass, closeTicker }) {

    const [tpa, SetTpa] = useState(null);

    const [tMouseOver, setTMouseOver] = useState(false);
    const [fMouseOver, setFMouseOver] = useState(false);
    const [cMouseOver, setCMouseOver] = useState(false);
    const [tickerWidth, setTickerWidth] = useState(0);

    const { screens } = useNavigator();
    const navigator = useNavigator();

    const { coins } = useMarket();
    const { sidebarRight, sidebarLeft } = useCore();
    const { tokens,
        tickerPairs,
        fetchTickerPairs,
        handleTokenPair,
        fetchTokenPair,
        token,
        showTicker,
        handleShowTicker
    } = useDex();

    const [pageIsVisible, setPageIsVisible] = useState(true);
    const [tickerVisible, setTickerVisible] = useState(true);
    const [tickerIntervalStarted, setTickerIntervalStarted] = useState(false);

    const handleVisibilityChange = (isVisible) => {
        setPageIsVisible(isVisible)
    }


    useEffect(() => {

        fetchTickerPairs();

        if(!tickerIntervalStarted){
            const tickerinterval = setInterval(fetchTickerPairs, 30000);
            return () => clearInterval(tickerinterval);
            setTickerIntervalStarted(true);
        }

    }, []);

    useEffect(() => {

        if(token && token.pair && token.pair.address !== undefined && token.pair.address !== tpa ){
            let ae = Array.from(document.getElementsByClassName('ticker-item active'));
            if(ae.length){
                ae.forEach(element => {
                    element.classList.remove('active');
                });
                let at = document.getElementById(token.pair.address);
                if(at){
                    at.classList.add('active');
                }
            }
            handleTpa(token.pair.address);
          
        }

    }, [token]);

    useEffect(() => {

        if(screens[1] == undefined || !screens[1].length){
            handleResize();
        }
    }, [ screens ]);

    useEffect(() => {

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, [  ]);

    const handleTpa = (a) => {
        SetTpa(a);
    }

    const handleResize = () => {
      // Get the width of the element


        setTickerVisible(false);
        setTimeout(() => {
            setTickerVisible(true);
        }, 1);

        // setTickerWidth(width);

    };

    useEffect(() => {
        handleResize();
    }, [sidebarRight, sidebarLeft]);

    const toggleTicker = () => {
        // setShowTicker(!showTicker)
        handleShowTicker(!showTicker);
    }

    const onTMouseOver = (v) => {
        setTMouseOver(v)
    }

    const onFMouseOver = (v) => {
        setFMouseOver(v)
    }

    const onCMouseOver = (v) => {
        setCMouseOver(v)
    }
    const tClick = (e) => {
        let target = e.target;

        while(target && target.className !== "ticker-item"){
            target = target.parentElement;
        }

        if(target){
            let id = target.getAttribute('id');
            let chn = target.getAttribute('chain');
            let ae = Array.from(document.getElementsByClassName('ticker-item active'));
            if(ae.length){
                ae.forEach(element => {
                    element.classList.remove('active');
                });
            }
       
            target.classList.add('active');
            // fetchTokenPair(id);
            navigator.push(`/${chn}/${id}`);
        }
    }


    const cClick = (e) => {
        let target = e.target;
        if(target){
            while(target.className !== "ticker-item"){
                target = target.parentElement;
            }

            let id = target.getAttribute('id');
            window.open(
                `https://coingecko.com/en/coins/${id}`,
                "coingecko"
            )
        }

        // fetchTokenPair(id);
        // navigator.push(`/ethereum/${id}`);
    }
    // const tPairs = Object.keys(tickerPairs).map(key => (
    //     // console.log(c,coins[key])
    //     <div className="ticker-item">
    //         <div className="ticker-row">
    //             <img src="${tickerPairs[key].cg.image.small}" className="ticker-icon"/>
    //
    //             <span className="ticker-symbol">{tickerPairs[key].symbol.toUpperCase()}</span>
    //             <span className="small mute"> 1h </span>
    //             {formatPercent(tickerPairs[key].data.priceChange.h1)}
    //
    //         </div>
    //         <div className="ticker-row">
    //             <span className="ticker-price">{condenseZeros(formatCurrency(tickerPairs[key].data.priceUsd, "USD"))}</span>
    //             <span className="small mute"> 24h </span>
    //             {formatPercent(tickerPairs[key].data.priceChange.h24)}
    //         </div>
    //     </div>
    //
    // ))
    // ;

    const tPairs = Object.keys(tickerPairs).length ? Object.keys(tickerPairs)
        .filter(k => (tickerPairs[k].data?.liquidity?.usd / tickerPairs[k].data?.fdv) > .01 && tickerPairs[k].token )
        .filter(k => tickerPairs[k].data?.fdv > 10000000 )
        // .sort((a,b) => {
    //
    //     if(tickerPairs[a].symbol == "wbtc"){
    //         return 1;
    //
    //     }else if(tickerPairs[a].symbol == "weth"){
    //
    //         return 1;
    //
    //     }else{
    //         if(tickerPairs[a].data !== undefined){
    //             return tickerPairs[a].data.priceChange.m5 > tickerPairs[b].data.priceChange.m5 ? -1 : 1
    //         }
    //     }
    //
    // })
    .map((k,i) => (
        // console.log(c,coins[key])
        `<div className="ticker-item${token && tickerPairs[k].data?.pairAddress==token.pair?.address ? " active" : ""}" chain="${tickerPairs[k].chainId}" id="${tickerPairs[k].data?.pairAddress}">
            <div className="ticker-item-chain">
                <img className="chain-icon" src="${require(`../../icons/logo_chain_${tickerPairs[k].chainId}.png`)}"/>
            </div>
            <div className="ticker-row">
                
                ${ tickerPairs[k].token && tickerPairs[k].token.cg && !tickerPairs[k].token.cg.image.small.includes("missing") ?
                    `<img src="${tickerPairs[k].token.cg.image.small}" className="ticker-icon"/>` :
                    tickerPairs[k].token && tickerPairs[k].token.iconImage ?
                    `<img src="${process.env.REACT_APP_MEDIA_URL}${tickerPairs[k].token.iconImage}" className="ticker-icon"/>` :
                    `<img src="${makeBlockiesUrl(blockieSeed + tickerPairs[k].ta)}" className="ticker-icon round"/>`}
                
                
                <span className="ticker-symbol">${tickerPairs[k].data.baseToken.symbol}</span>
                <span className="ticker-price">${condenseZeros(formatCurrency(tickerPairs[k].data.priceUsd, "USD"))}</span>
            </div>
            <div className="ticker-row">
                <span className="small mute"> fdv </span>
                <span className="small mute">$</span>${formatCurrencyScale(tickerPairs[k].data.fdv)}
                <span className="small mute"> 5m </span>
                ${formatPercent(tickerPairs[k].data.priceChange.m5)}
            </div>
            <div className="ticker-row">
                <span className="small mute"> 1h </span>
                ${formatPercent(tickerPairs[k].data.priceChange.h1)}
                <span className="small mute"> 24h </span>
                ${formatPercent(tickerPairs[k].data.priceChange.h24)}
            </div>
        </div>`

    )) : []
    ;

    const fPairs = Object.keys(tickerPairs).length ? Object.keys(tickerPairs)
        .filter(k => (!tickerPairs[k].token || !tickerPairs[k].token.cg) )
        .filter(k => tickerPairs[k].data?.fdv < 10000000 )
        .filter(k => (tickerPairs[k].data?.liquidity?.usd / tickerPairs[k].data?.fdv) > .01 && tickerPairs[k].token )
    // .sort((a,b) => {
    //
    //     if(tickerPairs[a].symbol == "wbtc"){
    //         return 1;
    //
    //     }else if(tickerPairs[a].symbol == "weth"){
    //
    //         return 1;
    //
    //     }else{
    //         if(tickerPairs[a].data !== undefined){
    //             return tickerPairs[a].data.priceChange.h24 > tickerPairs[b].data.priceChange.h24 ? -1 : 1
    //         }
    //     }
    //
    // })




    .map((k,i) => (
        // console.log(c,coins[key])
        `<div className="ticker-item${token && tickerPairs[k].data?.pairAddress == token.pair?.address ? " active" : ""}" chain="${tickerPairs[k].chainId}" id="${tickerPairs[k].data?.pairAddress}">
            
            <div className="ticker-item-chain">
                <img className="chain-icon" src="${require(`../../icons/logo_chain_${tickerPairs[k].chainId}.png`)}"/>
            </div>
        
            <div className="ticker-row">
                ${ tickerPairs[k].token && tickerPairs[k].token.cg && !tickerPairs[k].token.cg.image.small.includes("missing") ?
                    `<img src="${tickerPairs[k].token.cg.image.small}" className="ticker-icon"/>` :
                    tickerPairs[k].token && tickerPairs[k].token.iconImage ?
                    `<img src="${process.env.REACT_APP_MEDIA_URL}${tickerPairs[k].token.iconImage}" className="ticker-icon"/>` :
                    `<img src="${makeBlockiesUrl(blockieSeed + tickerPairs[k].ta)}" className="ticker-icon round"/>`}


                <span className="ticker-symbol">${tickerPairs[k].data.baseToken.symbol}</span>
                <span className="ticker-price">${condenseZeros(formatCurrency(tickerPairs[k].data.priceUsd, "USD"))}</span>

            </div>
            <div className="ticker-row">

                <span className="small mute"> fdv </span>
                <span className="small mute">$</span>${formatCurrencyScale(tickerPairs[k].data.fdv)}
                <span className="small mute"> 5m </span>
                ${tickerPairs[k].data.priceChange !== undefined ? formatPercent(tickerPairs[k].data.priceChange.m5) : formatPercent(0)}
            </div>
            <div className="ticker-row">

                <span className="small mute"> 1h </span>
                ${tickerPairs[k].data.priceChange !== undefined ? formatPercent(tickerPairs[k].data.priceChange.h1) : formatPercent(0)}

                <span className="small mute"> 24h </span>
                ${tickerPairs[k].data.priceChange !== undefined ? formatPercent(tickerPairs[k].data.priceChange.h24) : formatPercent(0)}

            </div>

        </div>`

    )) : []
    ;

    const capCoins = Object.keys(coins)
    .filter(key => coins[key].market_cap_rank < 200
        && coins[key].symbol.indexOf('usd') < 0
        && coins[key].symbol !== 'busd'
        && coins[key].symbol !== 'dai'
        && coins[key].symbol !== 'steth'
        && coins[key].symbol !== 'usdt'
        && coins[key].symbol !== 'usdc')
    .sort((a,b) => {
        if(coins[b].symbol == "btc"){
            return 1;

        }else if(coins[b].symbol == "eth"){

            return 1;

        }else{
            return coins[a].price_change_percentage_24h_in_currency < coins[b].price_change_percentage_24h_in_currency ? 1 : -1
        }

    })
    .map(key => (
        // console.log(c,coins[key])
        // add no-click to ticker-item classes to turn off click
        `<div className="ticker-item" id="${coins[key].id}">
            <div className="ticker-row">
                <img src="${coins[key].image}" className="ticker-icon"/>
                <span className="ticker-symbol uppercase">${coins[key].symbol}</span>
                <span className="ticker-price">${condenseZeros(formatCurrency(coins[key].current_price, "USD"))}</span>
            </div>
            <div className="ticker-row">
                <span className="small mute"> ${coins[key].fully_diluted_valuation || coins[key].market_cap < 1 ? 'fdv' : 'mc'} </span>
                <span className="small mute">$</span>${coins[key].fully_diluted_valuation ? formatCurrencyScale(coins[key].fully_diluted_valuation) : coins[key].market_cap > 0 ? formatCurrencyScale(coins[key].market_cap) : formatCurrencyScale(coins[key].total_supply*coins[key].current_price) }
                <span className="small mute"> 1h </span>
                ${formatPercent(coins[key].price_change_percentage_1h_in_currency)}
            </div>
            <div className="ticker-row">
                <span className="small mute"> 24h </span>
                ${formatPercent(coins[key].price_change_percentage_24h_in_currency)}

                <span className="small mute"> 7d </span>
                ${ coins[key].price_change_percentage_7d_in_currency ? formatPercent(coins[key].price_change_percentage_7d_in_currency) : '--'}

            </div>
        </div>`

    ))
    ;


    let preRoll=`<div className="ticker-item"></div>`;

    return (
        <div id="tickercontainer" style={ stylePass ? stylePass : {}} className={classNames("ticker-container", showTicker && "show")}>
            <div className="ticker-overlay"/>

            <IconActionButton addClass="top-right" type="close" passClick={() => toggleTicker()}/>

            {/*<div style={{marginTop:0}} onClick={cClick}>
                <Ticker offset="run-in" speed={cMouseOver ? 4 : 11}>
                  {() => ( <div className="ticker-text" onMouseOver={() => onCMouseOver(true)} onMouseOut={() => onCMouseOver(false)} style={{whiteSpace:'nowrap'}}><span className="small mute codesmall">&nbsp;&nbsp;&nbsp;&nbsp;+ + +&nbsp;&nbsp;&nbsp;&nbsp;</span> {parse(capCoins.length ? capCoins.join(' <span className="small mute"> &nbsp; </span> ') : ' <span className="small mute">. . . welcome to 0xdex . . .</span> ')}</div> )
                  }
                </Ticker>
            </div>*/}

            {
                showTicker && tickerVisible ? (
                    <PageVisibility onChange={handleVisibilityChange}>
                        <>
                            <div style={{marginTop:0}} onClick={tClick}>

                                {pageIsVisible && (
                                    <Ticker offset="run-in" speed={tMouseOver ? 2 : 13} >
                                        {() => ( <div className="ticker-text" onMouseOver={() => onTMouseOver(true)} onMouseOut={() => onTMouseOver(false)} style={{whiteSpace:'nowrap'}}><span className="small mute codesmall">&nbsp;&nbsp;&nbsp;&nbsp;+ + +&nbsp;&nbsp;&nbsp;&nbsp;</span> {parse(tPairs.length ? tPairs.join(' <span className="small mute"> &nbsp; </span> ') : ' <span className="small mute">. . . welcome to 0xdex . . .</span> ')}</div> )
                                        }
                                    </Ticker>
                                )}

                            </div>
                            <div style={{marginTop:-15}} onClick={tClick}>

                                {pageIsVisible && (
                                    <Ticker offset="run-in" speed={fMouseOver ? 2 : 11} >
                                        {() => ( <div className="ticker-text" onMouseOver={() => onFMouseOver(true)} onMouseOut={() => onFMouseOver(false)} style={{whiteSpace:'nowrap'}}><span className="small mute codesmall">&nbsp;&nbsp;&nbsp;&nbsp;+ + +&nbsp;&nbsp;&nbsp;&nbsp;</span> {parse(fPairs.length ? fPairs.join(' <span className="small mute"> &nbsp; </span> ') : ' <span className="small mute">. . . . . .</span> ')}</div> )
                                        }
                                    </Ticker>
                                )}


                            </div>
                        </>
                    </PageVisibility>
                ):null
            }


        </div>
    );
}
