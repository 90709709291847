import React, { useEffect, useState, useRef } from "react";
import { useWeb3React } from '@web3-react/core';

import { useCore } from "../../hooks/useCore";
import { useSocial } from "../../hooks/useSocial";
import { useMarket } from "../../hooks/useMarket";
import { useDex } from "../../hooks/useDex";
import { useWallet } from "../../hooks/useWallet";

import classNames from "classnames";

import * as tools from "../../utils/tools";

import { IconActionButton } from "../IconActionButton";
import { TokenDataTabs } from "./TokenDataTabs";
import { LoaderSpinner } from "../Layout/LoaderSpinner";

import Blockies from 'react-blockies';
import { QRCodeSVG } from 'qrcode.react';

import Draggable from "react-draggable";
import { Resizable, ResizableBox } from 'react-resizable';

import { chains, toHex, chainData } from "../../utils/data";

import sushilogo from "../../icons/logo_sushi.png";

import {ReactComponent as UniswapLogoWide} from "../../icons/svgs/logo_uniswap_wide.svg";
import {ReactComponent as UniswapLogo} from '../../icons/svgs/logo_uniswap.svg';
import {ReactComponent as ApeswapLogo} from '../../icons/svgs/logo_apeswap.svg';
import {ReactComponent as BiswapLogo} from '../../icons/svgs/logo_biswap.svg';



export function DexSwapDraggable({ tokenPair }) {

	// const { activate, deactivate, active, chainId, account } = useWeb3React();

	var { chainId } = useWallet();

	let { swaps,
		swapVisible,
		handleSwapVisible,
		tbx,
		tby,
		apeSettings,
		loading
	} = useDex();

	let { coins, coinSymbols } = useMarket();

	const [swapExchange, setSwapExchange] = useState("uniswap");
	const [showQr, setShowQr] = useState();
	const [loadingIframe, setLoadingIframe] = useState(false);

	const tb = useRef(null);


	useEffect(() => {
		setLoadingIframe(true);
	}, [ swaps[swapExchange].url ]);




	const closeToolbar = () => {
		handleSwapVisible(false);
	}

	const handleStart = (e) => {

	}

	const handleDrag = (e) => {

	}

	const handleStop = (e) => {

	}

	const changeSwapExchange = (s) => {
		setSwapExchange(s);
	}

	const toggleQr = () => {
		setShowQr(!showQr);
	}

	const popOutSwap = () => {
		// window.open(`${process.env.REACT_APP_ARTEMIS_URL_IP}/swap/${swapExchange}/test`,"_blank");
		let surl = `${swaps[swapExchange].url}?inputCurrency=eth&outputCurrency=${tokenPair.data.baseToken.address}&slippage=${apeSettings.slippage*100}&exactAmount=${apeSettings.amount}&exactField=input`;
		window.open(surl,"_blank");

	}

	const handleOnLoad = () => {
		console.log('loaded');
		setLoadingIframe(false);
	}

	const token = tokenPair && tokenPair.data !== undefined ? {...tokenPair.data} : null;

    const tokenImageUrl = (token && coins[(coinSymbols[token.baseToken.symbol.toLowerCase()])] !== undefined)
        ? coins[(coinSymbols[token.baseToken.symbol.toLowerCase()])].image : token && token.image && token.image !== undefined ? token.image : '';
        // : require(`../../icons/logo_chain_${chain.image}.png`);

	// const chainIndex = chains.findIndex(x => x.id == chainId);

	const swapUrl = `${swaps[swapExchange].url}?inputCurrency=eth&outputCurrency=${tokenPair.data.baseToken.address}&slippage=${apeSettings.slippage*100}&exactAmount=${apeSettings.amount}&exactField=input`;

    return (
		<Draggable
		// handle=""
		defaultPosition={{x:568, y: 44}}
		//position={loading.dex ? {x: tbx-111, y: tby } : null}
		bounds="parent"
		// grid={[25, 25]}
		// scale={1}
		>

			<ResizableBox
			// draggableOpts=
				handleSize={[20,20]}
				minConstraints={[100, 100]} maxConstraints={[800, 800]}>


				<div className="dex-swap" style={{display: swapVisible ? 'flex' : 'none'}}>
					<div className="dex-swap-header token-toolbar-header">
						<div className="swap-header-chain-icon">
						{/*
							<img
							className="chain-icon"
							src={require(`../../icons/logo_chain_${chains[chainIndex].image}.png`)}
							style={{ filter: chains[chainIndex].test!==undefined && chains[chainIndex].length ? 'saturation(.3);' : ''}}/>
							*/}
							{chainId}
						</div>
						{ token && (

							<div style={{padding: '3px 7px'}} class="data-row flex-row align justify center">


								{ swapExchange == "uniswap" ? (
									<div className="svg-icon uniswap-logo">

										<UniswapLogoWide/>


									</div>
								): swapExchange == "sushiswap" ? (


									<div style={{marginLeft:-17,lineHeight:'33px'}} className="data-value">sushi swap</div>



								): null}










							</div>



						)}

						<IconActionButton buttonposition="center-right" type="close" passClick={() => closeToolbar()}/>

					</div>


					{tokenPair && tokenPair.data !== undefined && (

						<iframe onLoad={handleOnLoad} src={`${swaps[swapExchange].url}?inputCurrency=eth&outputCurrency=${tokenPair.data.baseToken.address}&slippage=${apeSettings.slippage*100}&exactAmount=${apeSettings.amount}&exactField=input`}/>

					)}


					<div className="dex-swap-footer token-toolbar-header">

						<div className="coin-links">

							<div onClick={() => changeSwapExchange("uniswap")} data-tip="uniswap" className={classNames("coin-link", swapExchange == "uniswap" && "active")}>
								<div className="svg-icon uniswap">
									<UniswapLogo/>
								</div>
							</div>
							<div onClick={() => changeSwapExchange("sushiswap")} data-tip="sushi swap" className={classNames("coin-link", swapExchange == "sushiswap" && "active")}>
								<div className="svg-icon sushi">
									<img src={sushilogo}/>
								</div>
							</div>
							<div onClick={() => changeSwapExchange("apeswap")} data-tip="ape swap" className={classNames("coin-link", swapExchange == "apeswap" && "active")}>
								<div className="svg-icon uniswap">
									<ApeswapLogo/>
								</div>
							</div>
							<div onClick={() => changeSwapExchange("biswap")} data-tip="bi swap" className={classNames("coin-link", swapExchange == "biswap" && "active")}>
								<div className="svg-icon uniswap">
									<BiswapLogo/>
								</div>
							</div>
						</div>

						<div className="coin-links">
							<div onClick={toggleQr} data-tip="QR code" style={{height:30}} className={classNames("coin-link normal", showQr && "active")}>
								<div className="svg-icon normal">
									<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
									<g><path d="M377.1,377.1H10V10h367.1V377.1z M78.6,308.5h229.8V78.6H78.6V308.5L78.6,308.5z"/><path d="M132.4,132.4h122.4v122.4H132.4V132.4z"/><path d="M377.1,990H10V621.8h367.1V990z M78.6,921.4h229.8v-231H78.6V921.4L78.6,921.4z"/><path d="M132.4,745.3h122.4v121.2H132.4V745.3z"/><path d="M990,377.1H621.8V10H990V377.1z M690.4,308.5h231V78.6h-231V308.5z"/><path d="M745.3,132.4h121.2v122.4H745.3V132.4z"/><path d="M10,377.1h122.4v123.5H10V377.1z"/><path d="M254.7,500.6h122.4v121.2H254.7V500.6L254.7,500.6z"/><path d="M500.6,10h121.2v122.4H500.6V10z"/><path d="M377.1,254.7h123.5v122.4H377.1V254.7z"/><path d="M500.6,866.5h121.2V990H500.6V866.5z"/><path d="M745.3,866.5H990V990H745.3V866.5z"/><path d="M500.6,621.8h244.7v123.5H500.6V621.8z"/><path d="M745.3,621.8h121.2v244.7H745.3V621.8z"/><path d="M855.1,621.8H990v123.5H855.1V621.8z"/><path d="M500.6,377.1h121.2v123.5H500.6V377.1z"/><path d="M621.8,500.6h123.5v121.2H621.8V500.6L621.8,500.6z"/><path d="M745.3,377.1h121.2v123.5H745.3V377.1z"/><path d="M866.5,500.6H990v121.2H866.5V500.6L866.5,500.6z"/></g>
									</svg>

								</div>
							</div>
							<div onClick={popOutSwap} data-tip="QR code" style={{height:30}} className={classNames("coin-link normal", showQr && "active")}>
								<div className="svg-icon normal">

									<div className="icon-pop-out"/>

								</div>
							</div>
						</div>




					</div>

					{ showQr && (
						<div onClick={toggleQr} className="qr-code-panel">
							<QRCodeSVG
							// value={`${process.env.REACT_APP_ARTEMIS_URL_IP}/swap/${swapExchange}/${token.baseToken.address}`}
							value={swapUrl}
							size="512"
							bgColor="#FFFFFF"
							fgColor="#1d1d1d" />
						</div>
					)}


				</div>

				{ loadingIframe && (
					<LoaderSpinner/>
				)}


			</ResizableBox>

		</Draggable>

    );
}



{/*<Draggable
axis="x"
handle=".drag-handle"
defaultPosition={{x: 0, y: 0}}
position={null}
grid={[25, 25]}
scale={1}
onStart={handleStart}
onDrag={handleDrag}
onStop={handleStop}>*/}

 {/*</Draggable>*/}
