import React, { useEffect, useState } from "react";
import classNames from "classnames";

export function Modal(props) {
    const { init, close, classes, children } = props;

    const [renderComponent, setRenderComponent] = useState(false);
    const [showComponent, setShowComponent] = useState(false);

    useEffect(() => {
        if (init) {
            setRenderComponent(true);

            const handleShowComponent = setTimeout(() => {
                setShowComponent(true);
            }, 40);

            return () => clearTimeout(handleShowComponent);
        }
    }, [init, renderComponent, showComponent]);

    const handleUnrenderComponent = () => {
        if (!init) {
            setRenderComponent(false);
            setShowComponent(false);
        }
    };

    const hideComponent = () => {
        setTimeout(() => {
            close()
        }, 10)
    }

    return renderComponent ? (
        <>
            <div
                className={classNames(
                    "modal",
                    classes && classes,
                    showComponent && "show",
                    !init && "hide"
                )}
                onTransitionEnd={handleUnrenderComponent}
            >
                <div className="modal-content">
                    <div onClick={close} className="icon-btn large close-modal transition">
                        <i className="icon-close"></i>
                    </div>

                    {children}
                </div>
            </div>

            <div
                className="modal-overlay"
                onClick={hideComponent}
            ></div>
        </>
    ) : null;
}
