import React, { useEffect, useState } from "react";

// import { useWeb3React } from '@web3-react/core';
import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";

import ReactTooltip from 'react-tooltip';

import { chains } from "../../utils/data";

export function ChainSelector() {
	// const { activate, deactivate, active, chainId, account } = useWeb3React();
	const { chainPanel, handleChainPanel, chain } = useCore();
	const { chainId } = useWallet();
	const [ hovering, setHovering ] = useState(false);

	useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );


	const handleChainClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		handleChainPanel(!chainPanel);
	}

	// const chainindex = chains.findIndex(x => x.id == chainId);

    return (
        <div onClick={handleChainClick}
		data-tip={`${chains[chainId]?.name}`}
		className="chain-selector">
			{/*<img className="chain-icon" src={require(`../../icons/logo_chain_${chains[chainindex].image}.png`)}/>*/}
			{ chains[chainId] !== undefined && (
				<img className="chain-icon" src={require(`../../icons/logo_chain_${chains[chainId].image}.png`)}/>
			)}

			<i className="icon-chevron-down" style={{marginTop:'2px'}}/>
        </div>
    );
}
