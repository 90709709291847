import React, { useEffect, useState } from "react";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale, formatNumberScale } from "../../utils/currency";
import { addCommas, blankAddress, deadAddress, getTimeDisplay, getTimeDiff, buildToken } from "../../utils/tools";

import { TokenSymbolIcon } from "./TokenSymbolIcon";

import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";
import classNames from "classnames";

import {ReactComponent as TrendingIcon} from '../../icons/svgs/trending.svg';
import { ReactComponent as Seedling } from "../../icons/svgs/seedling.svg";

var parse = require('html-react-parser');

export function DexPairCard({t, addStyle=null, dataTime="h24", dataTimeLabel="24h", activeColor=false, tokenStyle="default"}) {
   
    const now = new Date();

    const pair = t.pair;

    const [dTimeLabel, setDTimeLabel] = useState("24h");
    const [dTime, setDTime] = useState("h24");

    const {
		handleScreenClickDims,
        loading,
        fetchTokenPair,
        handleTokenPair,
        caToken,
        token,
        
        ethTrendingKey,
        tokenLists,
        handleAddToTokenList,
        removeTokenFromList,
        fetchPortfolioTokens,
        ethFactor,
        
        handleDexView,
        listView,
	} = useDex();

    const {
        modalVisible,
        sidebarRight,
        handleSidebarRight,
        ethTrending,
        listPage
    } = useCore();

    const navigator = useNavigator();


    useEffect(() => {
        if(dataTime !== "list" && dataTime !== "launch"){
            setDTimeLabel(dataTimeLabel);
            setDTime(dataTime);
        }
        
    }, [ dataTime ]);

    const handleClick = async () => {
        // let ex = e.clientX;
            // let ey = e.clientY + 33;
            // console.log(ex,ey)
            // handleScreenClickDims(ex,ey);
                    // console.log(t);
    
        let v = listView;
        if(listView == "list"){
            v += `&list=${listPage}`
        }

        setTimeout(() => {
            navigator.push(`/${pair.data.chainId}/${pair.address}?view=${v}`);
        },200)

        handleSidebarRight(true);

        // if(passClick){
        //     passClick();
        // }
        

        // fetchTokenPair(tpair.data.pairAddress);
    }

    

    return (
        <>

            
            <div onClick={handleClick} style={{ margin: tokenStyle=="featured" ? 0 : "", borderColor: activeColor ? activeColor : ''}}
                className={classNames("feature-card-token data-panel interactive solid",
                (t && token && token.address?.toLowerCase() == t.address?.toLowerCase()) && "active",
                t.trending !== undefined ? "trending" : "")  
            }>
                {t.trending !== undefined ? (
                    <div style={{}} className="feature-card-token-header">
                        <div style={{justifyContent:'flex-end',padding:'0 2px'}} className="data-row">
                            { t.trending.map((t, i) => (
                                <div key={i} style={{marginLeft:4}} className="data-tab-image">
                                    <img src={require(`../../icons/trending/logo_${t}.png`)}/>
                                </div>
                            ))}
                            
                        </div>

                    </div>
                    
                ):null}

                {activeColor ? (
                    <div style={{position:"absolute", right: 5, top: 5}}>
                        <div style={{color: activeColor,margin:0}} className="icon-button-star"></div>
                    </div>
                ):null}
                
                
                <TokenSymbolIcon
                token={t}
                ca={pair.ta}
                symbol={pair.symbol}
                name={pair.name}
                addClass="large"
                showCa={true}
                />
                
                <div style={{position:"absolute",display:"flex",zIndex:3,alignItems:"flex-end",bottom:4,left:7}}>
                    
                    <img data-tip={`${pair.data.chainId}`} className="chain-icon" src={require(`../../icons/logo_chain_${pair.data.chainId}.png`)}/>

                    { getTimeDiff(now,pair.data.pairCreatedAt) < 24 * 7 ? (
                        <div style={{width:15,height:15,marginRight:1,marginLeft:2}} className="svg-icon">
                            <Seedling/>
                        </div>
                    ):(
                        <div style={{opacity:.7,marginRight:2,marginLeft:5}} className="icon-button-clock"/>
                    )}
                    <div style={{lineHeight:'11px',transform:'scale(.88)',opacity:.77}} className="data-value">{getTimeDisplay(pair.data.pairCreatedAt)}</div>
                </div>

                <div style={{position:"absolute",bottom: t.trending !== undefined ? 39 : 33,left:13,zIndex:33}} className="data-item">
                    <div className="data-column align-left">
                        <div className="data-label no-pad">
                            USD
                        </div>
                        <div style={{transform:'scale(1.2)',lineHeight:'10px',marginBottom:3,marginLeft:5}} className="price data-value">
                            {parse(condenseZeros(formatCurrency(pair.data.priceUsd, "USD")))}
                        </div>
                    </div>
                    
                </div>
                
                <div style={{position:"absolute",bottom:19,right:7,zIndex:33}} className="data-item">
                    {/* <div className="data-label no-pad">
                        USD
                    </div>
                    <div style={{lineHeight:'10px',marginBottom:11}} className="data-value no-prefix">
                        {parse(condenseZeros(formatCurrency(t.pair.data.priceUsd, "USD")))}
                    </div> */}
                    {/* <div className="flex-row"> */}
                        
                        <div className="data-column align-right">
                            <div className="data-label no-pad">
                                fdv
                            </div>
                            <div style={{lineHeight:'10px',marginBottom:7}} className="data-value">
                                <span className="small">$</span>{parse("" + formatCurrencyScale(pair.data.fdv))}
                            </div>
                        </div>
                    {/* </div> */}
                    
                    <div className="data-label no-pad align-right">
                        {dTimeLabel}
                    </div>
                    <div style={{lineHeight:'13px'}} className="data-value green percent medium align-right">
                    { parse(formatPercent(String(pair.data.priceChange[`${dTime}`]))) }
                    </div>
                </div>

                <div style={{marginTop:73}} className="feature-card-token-footer">
                    <div style={{justifyContent:'flex-end',padding:'0 2px'}} className="data-row">
                      
                        
                    </div>

                </div>
                
            </div>      
            
        
        </>
        
    );
}


