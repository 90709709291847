import React, { useEffect } from "react";

import { useAuth } from "../../hooks/useAuth";
import { useCore } from "../../hooks/useCore";
import { useWallet } from "../../hooks/useWallet";

import web3 from 'web3';

import { IconActionButton } from "../IconActionButton";

import metalogo from "../../icons/logo_metamask.png";
import cbwlogo from "../../icons/logo_cbwallet.png";
import wconnectlogo from "../../icons/logo_walletconnect.png";

import { formatNumberScale } from "../../utils/currency";

import { fishEmojis } from "../../utils/data";

var parse = require('html-react-parser');

export function WalletConnectComponent() {

    let { loading, isAuthenticated } = useAuth();
	let { walletModalVisible, handleWalletModalVisible, handleChain } = useCore();
	let {
		activateWallet,
		deactivateWallet,
		walletHistory,
		fetchBalances,
		handleSetWalletAddress,
		walletAddress,
		whiteListed,
        zeroXPercent,
        zeroXBalance,
	} = useWallet();


	// useEffect(() => {
	// 	console.log('window ethereum hook listener lets go', window.ethereum)
    // }, [ window.ethereum ]);
	//
	//





	function sleep (time) {
	  return new Promise((resolve) => setTimeout(resolve, time));
	}



	const closeModal = () => {
		handleWalletModalVisible(false);
	}


    const fishEmojiKey = zeroXPercent > 1 ? 4 : zeroXPercent > .5 ? 3 : zeroXPercent > .2 ? 2 : zeroXPercent > .1 ? 1 : 0;



    return (
        <div className="modal-overlay" onClick={closeModal}>
			<div className="wallet-modal" onClick={(e) => {e.preventDefault();e.stopPropagation();}}>

				<div className="wallet-content">

					<IconActionButton type="close" passClick={closeModal}/>

					{ zeroXPercent > 8 ? (
                        <div className="flex-column">
                            <div className="flex-row align center">
                                <div style={{fontSize:33,marginTop:-7,marginRight:11}} className="icon-check green"/>
                                <h1>Access allowed</h1>
                            </div>

                        </div>
                    ): !walletAddress ? (
						<>
							<h1>Connect your wallet.</h1>

							{zeroXPercent > .4 || whiteListed ? (
								
								<div className="flex-row align center">
									<div style={{fontSize:22,marginTop:0,marginRight:11}} className="icon-check green"/>
									<div className="data-value medium">Access allowed</div>
								</div>

							):null}

                            {zeroXPercent > .001 && zeroXBalance ? (
                                <div className="flex-row align center">
                                    <div style={{fontSize:22,marginTop:0,marginRight:11}} className="icon-check green"/>

                                        <div className="data-value medium">Wallet holds {parse(formatNumberScale(zeroXBalance))} <span className="small">$</span>0xdex. <span className="small mute">{fishEmojis[fishEmojiKey]}</span></div>

                                </div>
                            ):null}
                            {whiteListed ? (
                                <div className="flex-row align center">
                                    <div style={{fontSize:22,marginTop:0,marginRight:11}} className="icon-check green"/>
                                        <div className="data-value medium">Wallet is whitelisted.</div>
                                </div>
                            ):null}

							<button className="outline" onClick={ () => activateWallet("metamask") }>
								<img src={metalogo}/>Metamask
							</button>

							<button disabled={true} className="outline" onClick={() => { console.log("wallet connect") }}>
								<img src={wconnectlogo}/>WalletConnect
							</button>
							{/*
							<button onClick={() => { activate(WalletConnect) }}>
								<svg height="25" viewBox="0 0 40 25" width="40" xmlns="http://www.w3.org/2000/svg">
								  <path d="m8.19180572 4.83416816c6.52149658-6.38508884 17.09493158-6.38508884 23.61642788 0l.7848727.76845565c.3260748.31925442.3260748.83686816 0 1.15612272l-2.6848927 2.62873374c-.1630375.15962734-.4273733.15962734-.5904108 0l-1.0800779-1.05748639c-4.5495589-4.45439756-11.9258514-4.45439756-16.4754105 0l-1.1566741 1.13248068c-.1630376.15962721-.4273735.15962721-.5904108 0l-2.68489263-2.62873375c-.32607483-.31925456-.32607483-.83686829 0-1.15612272zm29.16903948 5.43649934 2.3895596 2.3395862c.3260732.319253.3260751.8368636.0000041 1.1561187l-10.7746894 10.5494845c-.3260726.3192568-.8547443.3192604-1.1808214.0000083-.0000013-.0000013-.0000029-.0000029-.0000042-.0000043l-7.6472191-7.4872762c-.0815187-.0798136-.2136867-.0798136-.2952053 0-.0000006.0000005-.000001.000001-.0000015.0000014l-7.6470562 7.4872708c-.3260715.3192576-.8547434.319263-1.1808215.0000116-.0000019-.0000018-.0000039-.0000037-.0000059-.0000058l-10.7749893-10.5496247c-.32607469-.3192544-.32607469-.8368682 0-1.1561226l2.38956395-2.3395823c.3260747-.31925446.85474652-.31925446 1.18082136 0l7.64733029 7.4873809c.0815188.0798136.2136866.0798136.2952054 0 .0000012-.0000012.0000023-.0000023.0000035-.0000032l7.6469471-7.4873777c.3260673-.31926181.8547392-.31927378 1.1808214-.0000267.0000046.0000045.0000091.000009.0000135.0000135l7.6473203 7.4873909c.0815186.0798135.2136866.0798135.2952053 0l7.6471967-7.4872433c.3260748-.31925458.8547465-.31925458 1.1808213 0z" fill="#3b99fc"></path>
								</svg>
								Wallet Connect
							</button>
							*/}
							<button disabled={true} className="outline" onClick={() => { activateWallet("coinbase") }}>
								<img src={cbwlogo}/>Coinbase Wallet
							</button>

						</>
					):(
						<>
							<button className="outline" onClick={() => { deactivateWallet(); }}>Disconnect wallet</button>
						</>
					)}

					{/*<div>Connection Status: {active}</div>
				    <div>Account: {account}</div>
				    <div>Network ID: {chainId}</div>*/}
				</div>
			</div>
        </div>
    );
}
