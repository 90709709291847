import React, { useState, useEffect } from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./CompoundSliderComponents";

import { InputTextField } from "./InputTextField";

import { formatCurrencyScale, formatNumberScale } from "../../utils/currency";
var parse = require('html-react-parser');

export function InputRangeSlider({
    title,
    value,
    field,
    min,
    max,
    step,
    tickLabels,
    onChange
    }) {


    const [ minValue, setMinValue] = useState(value[0]);
    const [ maxValue, setMaxValue] = useState(value[1]);
    const [ minValueText, setMinValueText] = useState(value[0]);
    const [ maxValueText, setMaxValueText] = useState(value[1]);

    const sliderStyle = {
      position: "relative",
      width: "100%"
    };


    const domain = [min, max];
    const defaultValues = value;

    function handleChange(v) {
        // onChange(v);
        // setMinValue(v[0]);
        // setMaxValue(v[1]);
    }


    function handleTextChange(v) {
        console.log(v);
        // onChange(v);
        // setMinValue(v[0]);
        // setMaxValue(v[1]);
    }

    function handleUpdate(v) {
        let v1 = processValue(v[0]);
        let v2 = processValue(v[1]);
        console.log(v)
        let vtemp = [v1, v2];


        let minv = formatNumberScale(v1,true);
        let maxv = formatNumberScale(v2,true);
        console.log(minv)
        onChange([minv,maxv]);
        setMinValueText(minv);
        setMaxValueText(maxv);

        setMinValue(v1);
        setMaxValue(v2);

    }


    const processValue = (v) => {
        let blocks = tickLabels.length-2;
        let blockSize = 100/blocks;
        let magnitude = Math.ceil(v/blockSize)+2;
        let prange = v%blockSize;
        if(magnitude > 1){
            if(prange<1){
                prange=blockSize;

            }else if(prange < 3){
                prange=blockSize;
                magnitude-=1;
            }
        }

        let value = ((prange)*blocks) * (10**(magnitude));
        if(v < 3){
            value = v*5000;
        }else if(v==120){
            value = 0;
        }
        console.log(v,magnitude,blocks,prange,value);


        return value;
    }


    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {

        }
    };

    return (
        <div style={{maxWidth:555}} className="flex-column">
            <div className="flex-row">
                <div style={{marginBottom:11}} className="flex-row">

                    <div style={{padding: '3px 8px'}} className="data-row">
                        <label style={{width:77}}>{title}</label>
                        <div className="data-item">
                            <div className="data-title">
                                min
                            </div>
                            <div className="data-value large">
                                {minValue > 0 ? (
                                    <>
                                        <span className="small">$</span>
                                        {parse(''+minValueText)}
                                    </>
                                ):(

                                    <>--</>
                                )}

                            </div>
                        </div>
                        <div style={{marginLeft: 33}} className="data-item">
                            <div className="data-title">
                                max
                            </div>
                            <div className="data-value large">
                                {maxValue > 0 ? (
                                    <>
                                        <span className="small">$</span>
                                        {parse(''+maxValueText)}
                                    </>
                                ):(

                                    <>--</>
                                )}

                            </div>
                        </div>

                    </div>

                    {/*<InputTextField
                        title={'min'}
                        onChange={handleTextChange}
                        placeholder='min'
                        value={minValue}
                        onKeyPress={_handleKeyDown}
                        autoComplete="off"

                        // focus={true}
                    />

                    <InputTextField
                        title={'max'}
                        onChange={handleTextChange}
                        placeholder='max'
                        value={maxValue}
                        onKeyPress={_handleKeyDown}
                        autoComplete="off"

                        // focus={true}
                    />*/}
                </div>
            </div>

            <div className="input-group switch-wrapper">




                <div className="slider fixed">
                    <Slider
                      mode={2}
                      step={step}
                      domain={domain}
                      rootStyle={sliderStyle}
                      onUpdate={handleUpdate}
                      onChange={handleChange}
                      values={defaultValues}
                    >
                      <Rail>
                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                      </Rail>
                      <Handles>
                        {({ handles, getHandleProps }) => (
                          <div className="slider-handles">
                            {handles.map((handle) => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domain}
                                getHandleProps={getHandleProps}
                              />
                            ))}
                          </div>
                        )}
                      </Handles>
                      <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                          <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                              <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                              />
                            ))}
                          </div>
                        )}
                      </Tracks>
                      <Ticks count={7}>
                        {({ ticks }) => (
                          <div className="slider-ticks">
                            {ticks.map((tick,i) => (
                              <Tick key={tick.id} tickLabel={tickLabels[i]} tick={tick} count={ticks.length} />
                            ))}
                          </div>
                        )}
                      </Ticks>
                    </Slider>

                    <span></span>
                    <span></span>
                </div>
            </div>


        </div>

    );
}
