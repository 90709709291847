

import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ProvideWallet } from "./hooks/useWallet";
import { ProvideAuth } from "./hooks/useAuth";
import { ProvideCore } from "./hooks/useCore";
import { ProvideSocial } from "./hooks/useSocial";
import { ProvideMarket } from "./hooks/useMarket";
import { ProvideDex } from "./hooks/useDex";
// import { ProvideExchange } from "./hooks/useExchange";
import { ProvideCollection } from "./hooks/useCollection";
import { CookiesProvider } from 'react-cookie';

// import io from 'socket.io-client';

import SocketProvider from './providers/SocketProvider';


import './index.css';
import App from './App';

import Div100vh from 'react-div-100vh';
const { io } = require("socket.io-client");

export default function AppBase() {


    return (
		<SocketProvider client={io} uri={process.env.REACT_APP_URL_SOCKET}>
			<ProvideWallet>
				<ProvideDex>
					<ProvideSocial>
						<ProvideMarket>

							<ProvideCollection>
								<HelmetProvider>
									<Div100vh>
                                        <App />
									</Div100vh>
								</HelmetProvider>
							</ProvideCollection>

						</ProvideMarket>
					</ProvideSocial>
				</ProvideDex>
			</ProvideWallet>
		</SocketProvider>
    );
}
