import React, { useState, useEffect, useMemo } from "react";

import { useMarket } from "../../hooks/useMarket";
import { blockieSeed } from "../../utils/data";

import { CopyToClipboard } from "react-copy-to-clipboard";

import classNames from "classnames";
import Blockies from 'react-blockies';
import makeBlockiesUrl from 'blockies-react-svg/dist/es/makeBlockiesUrl.js'

import ethLogo from "../../icons/logo_chain_ethereum.png";
import { zeroxdexAddress } from "../../utils/tools";
import { max } from "lodash";

import toast from 'react-simple-toasts';
var parse = require('html-react-parser');

export function TokenSymbolIcon({ token, ca, symbol, name, active, showCa=null, addClass=null, maxWidth=null, maxWidthIcon=null, minWidth=null,includeQuote=null }) {

    let { coins, coinSymbols } = useMarket();

    const [imageError, setImageError ] = useState(false);
    const [imageProcessed, setImageProcessed] = useState(false);
    const [triedOnce, setTriedOnce] = useState(false);

    // const tokenImageUrl = (token && coins[(coinSymbols[symbol.toLowerCase()])] !== undefined)
    //     ? coins[(coinSymbols[symbol.toLowerCase()])].image : token && token.image && token.image !== undefined ? token.image : '';

    const isEth = token?.pair?.data?.baseToken?.symbol == "WETH";

    const ta = token?.pair?.data?.baseToken?.address ? token?.pair?.data?.baseToken?.address : ca;

    const tokenImageUrl = token && (token.address == zeroxdexAddress || ca == zeroxdexAddress) ? require('../../icons/logo_0xdex_100.jpg')
    : token && token.cg && token.cg.image !== undefined && token.cg.image.small !== "missing_small.png" ? token.cg.image.small
    : token && token.iconImage ? `${process.env.REACT_APP_MEDIA_URL}${token.iconImage}` : `${process.env.REACT_APP_MEDIA_URL}${ca}.png`;



    useMemo(() => {

       if(imageError && token.iconImage){
        setImageError(false);
        setImageProcessed(false)
       }
        
    }, [ token?.iconImage ]);


    const onImageError = (e) => {
        setImageError(true);
        setImageProcessed(true);
        if(!triedOnce){
            setTimeout(() => {
                setImageProcessed(false);
                setImageError(false);
            }, 10)
            setTriedOnce(true);
        }
    }
    const onImageLoad = (e) => {
       
        setImageProcessed(true);
    }

    const copyCa = (e) => {
        e.preventDefault();
        e.stopPropagation();
        toast(parse('<span className="mute">token address</span> copied'));
    }

    const tca = ca ? `${ca.slice(0, 5)}<span style={{fontSize:5}}>...</span>${ca.slice(-5)}` : "...";

    return (
        <div style={{padding: maxWidthIcon ? 0 : '3px 7px',maxWidth: maxWidth ? maxWidth : 'initial', minWidth: minWidth ? minWidth : 'initial'}} className={classNames("token-symbol-icon data-row flex-row align center", active && "active", addClass && addClass)}>

            <div style={{ marginTop: showCa ? -4 : 0, display: 'flex', minWidth: maxWidthIcon ? (maxWidthIcon + 10) : 43}} className={classNames("data-icon round", tokenImageUrl && "coin-icon-wrapper")}>
               
                <>

                    { isEth ? (
                        <img className="coin-icon" src={ethLogo}/>
                    ):(
                        <>
                            { tokenImageUrl && !imageError ? (

                                <img style={{opacity: imageProcessed ? 1 : 0, maxWidth: maxWidthIcon ? (maxWidthIcon + 5) : ''}} className="coin-icon" onLoad={onImageLoad} onError={onImageError} src={tokenImageUrl}/>

                            ):(
                                <img style={{borderRadius: '50%',margin: 3,maxWidth: maxWidthIcon ? maxWidthIcon : '33px'}} src={makeBlockiesUrl(blockieSeed + ta)} className="coin-icon"/>
                            )}
                        </>
                    )}


                    {/*):(
                        <Blockies
                            seed={`love&hope37${ca}`}
                            size={9}
                            scale={12}
                            // color="#dfe"
                            // bgColor="#ffe"
                            // spotColor="#abc"
                            className="identicon"
                        />
                    )}*/}

                </>
              
                
                
            </div>
            <div className="data-column" style={{marginLeft:0}}>
                {/* <p>{token.iconImage} - {imageProcessed ? "t" : "f"} - {imageError ? "t" : "f"}</p> */}
                <div style={{maxWidth: maxWidth ? (maxWidth-55) : '173px'}} className="coin-name">{isEth ? "Ethereum" : name}</div>
                <div style={{marginTop:2}} className="coin-symbol">{isEth ? "ETH" : symbol}{includeQuote ? ` / ${token.pair?.data?.quoteToken?.symbol}` : ""}</div>
                { showCa ? (
                    <div style={{padding:0}} className="coin-links">
                        <CopyToClipboard
                                text={ca}
                              
                        >   
                            <button data-tip={`copy token address`} className="blank" onClick={copyCa}>
                                <div style={{margin:0, marginLeft:-2, padding:'2px 2px', justifyContent: "flex-start", minWidth: 95}} className="coin-link">
                                    <p style={{fontSize:10,margin:0,fontWeight:'400'}} className="coin-name currency no-blur">{parse(tca)}</p>
                                    <div style={{fontSize:11,marginLeft:7}} className="icon-button-copy"></div>
                                </div>
                            </button>
                            
                        </CopyToClipboard>
                        
                    </div>
                    
                ):null}
                
            </div>

        </div>
    );
}
