import { Connection, PublicKey } from '@solana/web3.js';

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");

const {
    BigNumber,
    FixedFormat,
    FixedNumber,
    formatFixed,
    parseFixed,
    BigNumberish
} = require("@ethersproject/bignumber");


const web3base = createAlchemyWeb3(
    `https://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`,
);


export const stableTokensEth = [
    "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48:ethereum", // usdc
    "0xdAC17F958D2ee523a2206206994597C13D831ec7:ethereum", // usdt
    "0x6B175474E89094C44Da98b954EedeAC495271d0F:ethereum", // dai
]

export const stableTokensBase = [
    "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA:base", // usdbc
    "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913:base", // usdc
    "0xF9E36ba92f4f5E60FC0A19CCD201c285d8CCe62D:base",  // usdt
    "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb:base", // dai
]

export const stableTokensSol = [
    "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v:solana", // usdc
    "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB:solana", // usdt
]

  

export async function fetchSOLBalance(wa) {

    
    let solBalance = 0;
    const boptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            method: 'getBalance',
            id: 1,
            jsonrpc: '2.0',
            params: [
                wa
            ]
            
        })
    };

    try {
        let response = await fetch(`https://solana-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`, boptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        let data = await response.json();
        console.log(wa)
        let v = data.result.value / 10**9;
        // let td = {
        //     balance: v.amount,
        //     address: ca,
        //     decimals: v.decimals
        // }
        console.log(v);
        return v;
        // ethbalanceBase = parseInt(BigNumber.from(data.result));
        // ethbalanceBase = ethbalanceBase / 10**18;
        // return ethbalanceBase;
        
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
}

export async function fetchBaseBalance(wa) {
    let ethbalanceBase = 0;
    const boptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            id: 1,
            jsonrpc: '2.0',
            params: [wa, 'latest'],
            method: 'eth_getBalance'
        })
    };

    try {
        let response = await fetch(`https://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`, boptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        let data = await response.json();
        ethbalanceBase = parseInt(BigNumber.from(data.result));
        ethbalanceBase = ethbalanceBase / 10**18;
        return ethbalanceBase;
        
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }
}

export async function fetchSolanaTokenBalance(wa, ca){
    

    let solBalance = 0;
    const boptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            method: 'getTokenAccountsByOwner',
            id: 1,
            jsonrpc: '2.0',
            params: [
                wa,
                {
                    mint: ca
                },
                {
                    encoding: "jsonParsed"
                }
                

            ]
            
        })
    };

    try {
        let response = await fetch(`https://solana-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`, boptions);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        let data = await response.json();
        console.log(wa,data)

        if(data.result.value.length){
            let b = data.result.value[0].account.data.parsed.info.tokenAmount;
            let td = {
                balance: b.amount,
                address: ca,
                decimals: b.decimals
            }
            console.log(td);
            return td;
        }else{
            return null;
        }
       


        // ethbalanceBase = parseInt(BigNumber.from(data.result));
        // ethbalanceBase = ethbalanceBase / 10**18;
        // return ethbalanceBase;
        
    } catch (error) {
        console.error('Error fetching data:', error);
        return 0;
    }


}



export async function fetchBaseTokenBalance(wa, ca) {

    let bdata = await web3base.alchemy.getTokenBalances(wa,[`${ca}`]);
    let bmeta = await web3base.alchemy.getTokenMetadata(ca);
    // console.log(bmeta);

    let b = {
        balance: bdata.tokenBalances[0].tokenBalance,
        address: ca,
        ...bmeta
    }
    
    return b;

}


