import React, { useEffect, useState } from "react";


import { useNavigator } from "../../hooks/useNavigator";
import { useSocial } from "../../hooks/useSocial";
// import { useDex } from "../../hooks/useDex";

import toast from 'react-simple-toasts';

import ReactTooltip from 'react-tooltip';

import classNames from "classnames";

import {ReactComponent as ClockLogo} from '../../icons/svgs/clock.svg';

import {ReactComponent as ColumnsIcon} from '../../icons/svgs/columns.svg';




export function ViewToolbar({ token, addClass, disabled=false }) {

    const navigator = useNavigator();
    let { contractAddress, handleContractAddress, screens } = useNavigator();
    let { view, handleView } = useSocial();

    // const { fullscreen, toggleFullscreen, modalVisible, handleModalVisible, qrModal, handleQrModal, handleQrurl, handleModalTitle, handleCollectionModal } = useCore();


    useEffect(() => {
        ReactTooltip.rebuild();
    }, [] );


    const handleClick = () => {
        // passClick(nftp.address);
    }

    const toggleView = (v) => {
        handleView(v);
    }


    return (

        <div className={classNames("coin-links", '')}>


            <div onClick={() => toggleView("users")} data-tip="user list view" className={classNames("coin-link", view == "users" && "active")}>
                <div className="svg-icon">
                    <div className="icon-button-users"></div>
                </div>
            </div>

            <div onClick={() => toggleView("panel")} data-tip="panel view" className={classNames("coin-link", view == "panel" && "active")}>
                <div className="svg-icon">
                    <ColumnsIcon/>
                </div>
            </div>


            <div onClick={() => toggleView("time")} data-tip="time view" className={classNames("coin-link", view == "time" && "active")}>
                <div className="svg-icon">
                    <ClockLogo/>
                </div>
            </div>

        </div>
    )
}
