import React, { useState, useEffect, useCallback, useRef} from "react";

import { TwitterPost } from "./TwitterPost";
import { IconActionButton } from "../IconActionButton";

import { TokenDataTabs } from "../Dex/TokenDataTabs";

import { useCollection } from "../../hooks/useCollection";
import { useSocial } from "../../hooks/useSocial";

import classNames from "classnames";


// import { useSocial} from "../../hooks/useSocial";

export function TwitterUserCard({ twu, passClick, type="default" }) {

    const [ optionsMenu, setOptionsMenu ] = useState(false);

    const optionsref = useRef(null);


    const { removeFromTwitterUserList } = useSocial();

    useEffect(() => {

        const checkIfClickedOutside = e => {
		  if ( optionsMenu && optionsref.current && !optionsref.current.contains(e.target) && !e.target.classList.contains('icon-ellipsis-horizontal') ) {
			setOptionsMenu(false);
		  }
		}

        document.addEventListener("mouseup", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("mouseup", checkIfClickedOutside)
		}
    }, [ optionsMenu ])

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }

    let { handleShowCollectionsOptions,
        showCollectionsOptions,
        handleTwitterUserListCollectionModal,
        twitterUserListCollectionModal,
    } = useCollection();


    function showAddList(e){

        handleTwitterUserListCollectionModal(true);

        // if(twitterUserLists.length < 1){
        //
        //     // handleModalVisible(true);
        // }else{
        //     if(showCollectionsOptions){
        //         // handleShowCollectionsOptions(false, "off","off");
        //     }else{
        //         // handleAssetToAdd(nft);
        //         //
                let ex = e.clientX;
                let ey = e.clientY + 33;
                handleShowCollectionsOptions(true, ex, ey );

        //     }
        // }


    }

    function removeFromList(){
        console.log(twu)
        removeFromTwitterUserList(twu);
        setOptionsMenu(false);
    }


    return (

        <div
        style={{zIndex: optionsMenu ? 222 : 'initial'}}
        className="social-card data-panel"
        >
            <div style={{justifyContent:'center'}} className="social-item-header">

                <div className="flex-column align center">
                    <img
                        className="profile-image large"
                        src={twu.profile_image_url}
                        // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                        />

                    <p style={{marginTop:5}}><span className="mute">@</span>{twu.username}</p>
                </div>
                <div style={{ position: 'absolute',top: 4,right: 4}} className="coin-links">
                    {type == 'search' && (
                        <div onClick={showAddList} data-tip="add to" className={classNames("coin-link", showCollectionsOptions && "null")}>
                            <div id="showAddCollection" className="icon-button-plus"/>
                        </div>
                    )}

                    {type == 'default' && (
                        <div style={{position:'absolute',top:4,right:4}}>
                            <IconActionButton
                            addClass="transparentbg relative"
                            type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }
                            active={optionsMenu}
                            passClick={toggleOptionsMenu}/>
                        </div>
                    )}

                    { optionsMenu && (
                        <div ref={optionsref} className="options-menu">
                            <div style={{padding:7}} className="flex-row align center">
                                <img
                                    className="profile-image"
                                    src={twu.profile_image_url}
                                    // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                                    />
                                <p className="ellipsis" style={{marginLeft:5,fontSize:'.7em',maxWidth:99}}><span className="mute">@</span>{twu.username}</p>
                            </div>
                            <div style={{marginTop:1}} className="options-header">
                                <i className="icon-button-plus"/>
                                add to
                            </div>
                            <div className="option">
                                one
                                <div className="plus-sign">
                                    <i className="icon-button-plus"/>
                                </div>
                            </div>
                            <div className="option">
                                one
                                <div className="plus-sign">
                                    <i className="icon-button-plus"/>
                                </div>
                            </div>
                            <div className="option">
                                one
                                <div className="plus-sign">
                                    <i className="icon-button-plus"/>
                                </div>
                            </div>
                            <div
                                //style={{ backgroundColor: collections[key].color }}
                                className={classNames("option")}
                                onClick={() => removeFromList()}

                            >
                                {/*<div className="svg-icon">
                                    <WalletIcon/>
                                </div>*/}
                                <p className="red">remove</p>

                                <div className="plus-sign">
                                    <i className="icon-button-minus"/>
                                </div>
                            </div>
                        </div>
                    )}



                </div>
                {/*<div style={{marginLeft: 10}}>

                    <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" } passClick={toggleOptionsMenu}/>
                </div>*/}
            </div>
            <div className="data-item">
                <div className="data-value center small">
                    {twu.name}
                </div>
            </div>

            <div className="social-card-footer">
                <TokenDataTabs
                    // addClass="active"
                    title="price"
                    values={[twu.public_metrics.followers_count,
                        twu.public_metrics.following_count,
                        twu.public_metrics.listed_count,
                    ]}
                    valuesFormat={['numbermin','numbermin','number']}
                    labels={['followers','following','lists']}
                />
            </div>

        </div>
    )
}
