import React, { useState, useEffect, useCallback, useRef} from "react";

import { IconActionButton } from "../IconActionButton";

import { TokenDataTabsInteractive } from "../Dex/TokenDataTabsInteractive";

import { useCollection } from "../../hooks/useCollection";
import { useSocial } from "../../hooks/useSocial";
import { useWallet } from "../../hooks/useWallet";
import { useCore } from "../../hooks/useCore";
import { useNavigator } from "../../hooks/useNavigator";

import classNames from "classnames";
import { adjustRGBAlpha, hexToRGBA } from "../../utils/tools";
import { TokenListItem } from "../Dex/TokenListItem";
import { UserCard } from "./UserCard";

export function UserPage({ u, passClick, type="default", hideOptions=false }) {

    const navigator = useNavigator();

    let {
        handleModalTitle,
		handleModalVisible,
		handleTokenListModal,
	
	} = useCore();
  
    const {
        walletAddress,
        adminWallet,
        user
    } = useWallet();

    const {
        toggleFollow,
        follows,
        userView,
        handleUserView,
        userSubView
    } = useSocial();

    const [ optionsMenu, setOptionsMenu ] = useState(false);
    const [ followers, setFollowers ] = useState(0);

    

    const optionsref = useRef(null);

    useEffect(() => {

        if(u){
            setFollowers(u.followers);
        }

    }, [ u ]);


    useEffect(() => {

        const checkIfClickedOutside = e => {
            e.preventDefault();
            e.stopPropagation();
            if ( optionsMenu && optionsref.current && !optionsref.current.contains(e.target) && !e.target.classList.contains('icon-ellipsis-horizontal') ) {
                setOptionsMenu(false);
            }
		}

        document.addEventListener("mouseup", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("mouseup", checkIfClickedOutside)
		}
    }, [ optionsMenu ])

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }



    const optionsClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const onUserViewClick = (v) => {
        // handleUserView(v);
        let tempv = v == "listsf" ? "lists/following" : v;
        navigator.push(`/${u.username}/${tempv}`);
    }
   
    function openUserPage(e){
        navigator.push(`/${u.username}`);
    }

    const followUser = () => {

		let d = {
            uid: user._id,
            ftype: "user",
            fid: u._id
        }

        if(userFollowed){
            d.atype = "remove";
            setFollowers(followers-1);
        }else{
            d.atype = "add";
            setFollowers(followers+1);
        }

        toggleFollow(d);
	}

    const openList = async (l) => {
		// console.log(e)
		// handleTokenList(null);
		await navigator.push(`/list/${l.slug}`);
	}

    const handleEditList = async(l=null,wait=null) => {
		if(wait){
			setTimeout(() => {
				handleModalVisible(true);
				handleModalTitle("Edit List");
				handleTokenListModal("edit");
			},2500)
		}else{
			handleModalVisible(true);
			handleModalTitle("Edit List");
			handleTokenListModal("edit");
		}
		
	}


    const userFollowed = follows.users.find(item => item.user._id === u._id);

    const imgSrc = u.avatarFile ? `${process.env.REACT_APP_MEDIA_URL}${u.avatarFile}?t=${Math.floor(new Date().getTime() / (1000 * 60 * 60))}` : require('../../images/default_profile_avatar.jpg');


    return (

        <div
        className="social-page"
        >
            <div style={{justifyContent:'center'}} className="social-page-header">

                <div className="flex-column align center">
                    <img
                        className="profile-image xxl"
                        src={imgSrc}
                        style={{border: `3px solid ${u.color}`}}
                        // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                        />

                    <p style={{marginTop:11,marginBottom:4}}><span style={{marginRight:2}} className="mute">@</span>{u.username}</p>
                
                    <div style={{maxWidth:333}} className="data-item">
                        <div className="data-value center bio">
                            {u.name ? u.name : "_"}
                        </div>
                    </div>

                    <div style={{maxWidth:333,marginTop:4,marginBottom:7}} className="data-item">
                        <div className="data-value center small bio">
                            {u.bio !== undefined ? u.bio : "_"}
                        </div>
                    </div>
                </div>
                
                {/*<div style={{marginLeft: 10}}>

                    <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" } passClick={toggleOptionsMenu}/>
                </div>*/}

                {/* <h2>{userView} / {userSubView}</h2> */}

                <div className="flex-column align center">
                    <TokenDataTabsInteractive
                        // addClass="active"
                        title="price"
                        values={[
                            String(u.tokenListCount),
                            String(followers),
                            String(u.following),
                            String(u.listsFollowing)
                        ]}
                        dataTips={['published lists','followers','following','lists following']}
                        buttonValues={['lists','followers','following','listsf']}
                        buttonValue={userView}
                        showValues={true}
                        passClick={onUserViewClick}
                        
                        addStyle={{minWidth:222,maxWidth: 222,marginTop:11,marginBottom:11,overflow:"hidden"}}
                        valuesFormat={['string','string','string','string']}
                        subImages={[
                            null,
                            null,
                            'icon-button-chevron-right',
                            'icon-button-chevron-right'
                        ]}
                        dataItemStyle={{padding:"8px 11px 5px"}}
                        subImageStyle={{fontSize:13,position:"absolute",right:-13,top:9,opacity:.6}}
                        labels={['icon-button-list','icon-button-users','icon-button-user','icon-button-list']}
                    />

                </div>

                { userFollowed ? (

                    <div style={{
                        position:"absolute",
                        display:"flex",
                        flexDirection: "row",
                        zIndex:3,
                        alignItems:"center",
                        bottom:7,
                        right:7,
                        transform: 'scale(.88)',
                        // opacity: .7,
                        transformOrigin: "right"
                    }}>
                        
                            
                        <div style={{opacity:1,margin: 0,marginLeft:7}} className="icon-button-user green"/>
                        

                    </div>

                ):null}

                
            </div>

            
            
            <div style={{background: "transparent !important"}} className="feature-panel">
                <div style={{padding:11}} className="flex-column">
                    <div className="feature-card-tokens">
                        
                        { userView == "lists" && u.tokenLists !== undefined ? (
                            <>
                                { u.tokenLists.map((l,i) => (
                                    <TokenListItem l={l} key={`list${i}`} passClick={openList} editList={handleEditList} />
                                ))}
                            </>
                        ):null}

                        { userView == "listsf" && u.tokenListsFollowing !== undefined ? (
                            <>
                                { u.tokenListsFollowing.map((l,i) => (
                                    <TokenListItem l={l} key={`list${i}`} passClick={openList} editList={handleEditList} />
                                ))}
                            </>
                        ):null}

                        { userView == "followers" && u.followersUsers !== undefined ? (
                            <>
                                { u.followersUsers.map((u,i) => (
                                    <UserCard key={`u${i}`} u={u}/>
                                ))}
                            </>
                        ):null}

                        { userView == "following" && u.followingUsers !== undefined ? (
                            <>
                                { u.followingUsers.map((u,i) => (
                                    <UserCard key={`u${i}`} u={u}/>
                                ))}
                            </>
                        ):null}

                        
                        
                        
                    </div>
                </div>
            </div>
            

            { optionsMenu ? (
                <div onClick={toggleOptionsMenu}
                style={{
                    left: 0,
                    top: 0,
                    pointerEvents: walletAddress ? "fill" : "none",
                    width:'calc( 100% - 0px )',
                    height:'100%',
                    position:'absolute',
                    zIndex:1
                }}/>
            ):(
                <></>
            )}


            { !hideOptions && (!user || (user && user._id !== u._id)) ? (
                <div style={{pointerEvents: walletAddress ? "all" : "none",position:'absolute',top:4,right:4, zIndex: optionsMenu ? 222 : 1}}>
                    <IconActionButton
                    addClass="transparentbg relative"
                    type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }
                    active={optionsMenu}
                    passClick={toggleOptionsMenu}/>
                </div>
            ):null}

            { optionsMenu && (
                <div onClick={optionsClick} style={{right:0, top: 0,zIndex:199, minWidth: 144}} ref={optionsref} className="options-menu">


                    <div style={{marginTop:1,padding:'12px 9px'}} className="options-header">
                        <i className="icon-button-user"/>
                        
                    </div>


                   
                        <>


                           
                            <div
                                //style={{ backgroundColor: collections[key].color }}
                                className={classNames("option")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    followUser();
                                }}
                            
                            >
                            
                                { userFollowed ? (
                                    <>
                                        <i style={{fontSize:15}} className="icon-button-user-minus"/>
                                        <p>unfollow user</p>
                                        <div className="plus-sign">
                                            <i className="icon-button-minus red"/>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <i style={{fontSize:15}} className="icon-button-user-plus"/>
                                        <p>follow user</p>
                                        <div className="plus-sign">
                                            <i className="icon-button-plus green"/>
                                        </div>
                                    </>
                                )}
                                

                                
                            </div>
                            
                        
                        </>
                        
                </div>
            )}



        



        </div>
    )
}
