import React, { FC } from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import WebsiteBase from "../WebsiteBase";
import AppBase from "../AppBase";

export const AppRouter: FC = () => {
	return (
		<Router>
			<AppBase/>
		</Router>
	);
}

export const WebsiteRouter: FC = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<WebsiteBase/>} />
			</Routes>
		</Router>
	);
}

// export const AdminRouter: FC = () => {
// 	return (
// 		<Routes>
// 			<Route path="/" element={<Admin/>} />
// 		</Routes>
// 	);
// }
