import React, { useEffect, useState, useRef } from "react";

import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";

import { tokenFiltersData, fdvLabels, volumeLabels } from "../../utils/data.js"

import { InputRangeSlider } from "../Input/InputRangeSlider";
import { InputToggle } from "../Input/InputToggle";
import { InputTextField } from "../Input/InputTextField";

export function FiltersPanel({ status }) {

    const navigator = useNavigator();
	const { screens, pathname, handleSearchParams, searchParams } = useNavigator();

    const [filters, setFilters] = useState({});

    const {
        editFilters,
        handleEditFilters,
        tokenFilters,
        handleTokenFilters,
        dexView,
        handleDexView
    } = useDex();

    const filtersPanel = useRef(null);

    useEffect(() => {
		const checkIfClickedOutside = e => {
		  if (editFilters && filtersPanel.current && !e.target.classList.contains('coin-link') && !e.target.parentNode.classList.contains('coin-link') && !filtersPanel.current.contains(e.target)) {
		    handleEditFilters(false)
		  }
		}

		document.addEventListener("click", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("click", checkIfClickedOutside)
		}
	}, []);

    useEffect(() => {
        console.log('set filters!', tokenFilters)
		setFilters({...tokenFilters});
	}, [ tokenFilters ]);

    useEffect(() => {
		const checkIfClickedOutside = e => {
		  if (editFilters && filtersPanel.current && !e.target.classList.contains('coin-link') && !e.target.parentNode.classList.contains('coin-link') && !filtersPanel.current.contains(e.target)) {
		    handleEditFilters(false)
		  }
		}

		document.addEventListener("click", checkIfClickedOutside)

		return () => {
		  // Cleanup the event listener
		  document.removeEventListener("click", checkIfClickedOutside)
		}
	}, [editFilters]);


    const handleFdvRangeUpdate = (v) => {
        console.log(v);
        let f = {};
        if(v[0] !== "0"){
            f['fdv_min'] = v[0];
        }
        if(v[1] !== "0"){
            f['fdv_max'] = v[1];
        }
        console.log(f)
        let ftemp = {
            ...filters,
            ...f
        };
        setFilters(ftemp);
        console.log(ftemp)
        // console.log(tupdate);
        // if(theme){
        //     autoUpdateTheme(tupdate);
        // }
    }

    const handleVolumeRangeUpdate = (v) => {
        console.log(v);
        let f = {};
        if(v[0] !== "0"){
            f['volume_min'] = v[0];
        }
        if(v[1] !== "0"){
            f['volume_max'] = v[1];
        }
        let ftemp = {
            ...filters,
            ...f
        };
        console.log(ftemp)
        setFilters(ftemp);
        // console.log(tupdate);
        // if(theme){
        //     autoUpdateTheme(tupdate);
        // }
    }


    const handleContractScoreMinChange = (v) => {

        let ftemp = {
            ...filters,
            cscoremin: Number(v)
        };
        console.log(ftemp);
        setFilters(ftemp);
        // console.log(tupdate);
        // if(theme){
        //     autoUpdateTheme(tupdate);
        // }
    }

    const handleAgeChange = (v) => {

        let ftemp = {
            ...filters,
            age: v
        };
        console.log(ftemp);
        setFilters(ftemp);
        // console.log(tupdate);
        // if(theme){
        //     autoUpdateTheme(tupdate);
        // }
    }

    const toggleField = (f,v) => {
        console.log(f)
        let ftemp = { ...filters, ...f };
        console.log(ftemp);
        setFilters(ftemp);
    }

    const queryFilters = () => {
        const currentParams = Object.fromEntries([...searchParams]);
        console.log(currentParams);
        handleSearchParams({
            ...currentParams,
            ...filters
        });

        handleTokenFilters(filters);
        handleDexView("list");
        setTimeout(() => {
            handleEditFilters(false)
        },400);
    }

    const saveFilters = () => {

    }

    return (
        <div className="filters-panel" ref={filtersPanel}>
            <div style={{width: '100%', alignItems: 'flex-start'}} className="flex-row justify space-between">
                <h6>Filters</h6>
                <div style={{alignItems:'flex-start',justifyContent:'flex-end'}} className="flex-row">
                    <button onClick={saveFilters} style={{margin:0}} className="outline">save</button>
                    <button onClick={queryFilters} style={{margin:'0 0 0 11px'}} className="outline">apply</button>
                </div>

            </div>

            <div style={{marginBottom:11,marginTop:33,maxWidth:444}} className="flex-row no-margin">
                <div style={{flex:1}} className="input-group">
                    <InputToggle
                        title="verified"
                        field="verified"
                        value={filters["verified"]}
                        onChange={toggleField}/>
                </div>
                <div style={{flex:1}} className="input-group">
                    <InputToggle
                        title="liq safe"
                        field="locked"
                        value={filters["locked"]}
                        onChange={toggleField}/>
                </div>
                <div style={{flex:1}} className="input-group">
                    <InputToggle
                        title="renounced"
                        field="renouncedAt"
                        value={filters["renouncedAt"]}
                        onChange={toggleField}/>
                </div>
            </div>
            <div className="flex-row no-margin">
                <InputTextField
                    title={'age'}
                    onChange={handleAgeChange}
                    placeholder='e.g. 2d'
                    value={filters['age']}
                    // onKeyPress={_handleKeyDown}
                    autoComplete="off"
                    // focus={true}
                />
                <div style={{width:22,height:'100%'}}/>
                <InputTextField
                    title={'contract score min'}
                    onChange={handleContractScoreMinChange}
                    placeholder='min'
                    value={filters['cscoremin']}
                    // onKeyPress={_handleKeyDown}
                    autoComplete="off"
                    // focus={true}
                />
            </div>

            <div className="input-switch-group">

                <InputRangeSlider
                    onChange={handleFdvRangeUpdate}
                    title="fdv"
                    min={0}
                    max={120}
                    step={1}
                    count={8}
                    tickLabels={fdvLabels}
                    // value={min: map.minZoom, max: map.maxZoom}
                    value={[11, 77]}
                />

            </div>


            <div className="input-switch-group">

                <InputRangeSlider
                    onChange={handleVolumeRangeUpdate}
                    title="volume"
                    min={0}
                    max={120}
                    step={1}
                    tickLabels={fdvLabels}
                    // value={min: map.minZoom, max: map.maxZoom}
                    value={[0, 100]}
                />

            </div>
        </div>
    );
}
