import React, { useState, useEffect, useRef } from 'react';

import { useCore } from "../../hooks/useCore";
import { getRandomColor } from "../../utils/tools";
import classNames from "classnames";

export function GridCanvas(props) {
  const canvasRef = React.useRef(null);

  const { width, height, mouseX, mouseY, addClass } = props;

  const { windowDimensions } = useCore();

  React.useEffect(() => {
    updateCanvas();
  }, []); // Only run the effect once

  const [squares, setSquares] = useState([]);
  const isPlaying = true;
  const squareCount = 22;


  useEffect(() => {
      updateCanvas();
  }, [ windowDimensions.width, windowDimensions.height]);

  useEffect(() => {
      handleSquares();
  }, [ squares ]);


  useEffect(() => {
    // Add a new square every 10 seconds
    const intervalId = setInterval(() => {
      setSquares((prevSquares) => {
        // Generate a random position for the new square
        const x = Math.floor(Math.random() * width / 50) * 50;
        const y = Math.floor(Math.random() * height / 50) * 50;

        // Add the new square to the array
        return [...prevSquares, { x, y }];
      });
  }, 1200);

    return () => clearInterval(intervalId);
  }, []);


    function handleSquares() {

        const ctx = canvasRef.current.getContext('2d');

        // if(squares.length > 10){
        //     ctx.clearRect(squares[0].x, squares[0].y, 50, 50);
        // }
        if(squares.length > squareCount){
            ctx.clearRect((squares[squares.length-(squareCount-1)].x + 1), (squares[squares.length-(squareCount-1)].y+1), 48, 48);
        }

        if(squares[squares.length-1] !== undefined){
            ctx.fillStyle = getRandomColor(Math.random()/3+.22);
            ctx.fillRect((squares[squares.length-1].x + 1), (squares[squares.length-1].y + 1), 48, 48);
        }



    }


    function updateCanvas() {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        ctx.clearRect(0, 0, width, height);
        // for (let i = 0; i < squares.length; i++) {
        //     const square = squares[i];
        //     ctx.fillStyle = getRandomColor();
        //     ctx.fillRect(square.x, square.y, 50, 50);
        // }
        // Draw faint lines to create a grid
        ctx.strokeStyle = 'rgba(255,255,255, 0.044)';
        ctx.lineWidth = 1;
        for (let x = 0; x < width; x += 50) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            ctx.stroke();
        }
        for (let y = 0; y < height; y += 50) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(width, y);
            ctx.stroke();
        }

        ctx.fillStyle = 'rgba(77, 77, 77, .22)';
        ctx.strokeStyle = 'rgba(77, 77, 77, 0.17)';
        ctx.lineWidth = 1;
        for (let x = 0; x < width; x += 50) {
          for (let y = 0; y < height; y += 50) {
            ctx.beginPath();
            ctx.moveTo(x-2, y);
            ctx.lineTo(x+2, y);
            ctx.moveTo(x, y-2);
            ctx.lineTo(x, y+2);
            ctx.stroke();


            // ctx.beginPath();
            // ctx.arc(x, y, 1, 0, 2 * Math.PI);
            // ctx.fill();
          }
        }


    }



  return (
    <div style={{marginTop:(-mouseY*100),marginLeft:(-mouseX*100)}} className={classNames("grid-canvas", addClass ? addClass : "")}>
        <canvas
          // style={{minHeight:'100%',minWidth:'100%'}}
          width={width}
          height={height}
          ref={canvasRef}
          onMouseMove={e => handleMouseMove(e)}
        />
        {squares.map((square, index) => (
            <Square key={index} x={square.x} y={square.y} />
        ))}
    </div>
  );

  function handleMouseMove(e) {
    // Update the position of the grid based on the mouse position
  }
}



function Square({ x, y }) {
  const canvasSquareRef = useRef(null);

  useEffect(() => {
    const ctx = canvasSquareRef.current.getContext('2d');
    ctx.fillStyle = getRandomColor(Math.random()/3);
    ctx.fillRect((x+1), (y+1), 48, 48);
    setTimeout(() => {
      ctx.clearRect((x+1), (y+1), 48, 48);
    }, 10000);
  }, [x, y]);

  return <canvas ref={canvasSquareRef} />;
}
