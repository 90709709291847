import React, { useState, useEffect, useRef } from "react";
import { ChromePicker } from "react-color";
import ColorPicker from 'react-pick-color';
import { validateColor } from "../../utils/validations";

export function InputColorPick({ title, value, onChange, addClass, mode="hex", disableAlpha=false }) {
    const pickerRef = useRef();
    const [showPicker, setShowPicker] = useState(false);

    const [color, setColor] = useState(value || "#000");
    const [error, setError] = useState(false);

    function handleHidePicker(e) {
        if(pickerRef.current){
            console.log(pickerRef.current, e.target)
            console.log('in target', pickerRef.current.contains(e.target))

            // if (e && pickerRef && pickerRef.current.contains(e.target)) return;
            setShowPicker(false);
            document.removeEventListener("click", handleHidePicker);


        }else{
            setShowPicker(false);
        }
    }

    useEffect(() => {
        setColor(value);
        // console.log(value)
    }, [value]);

    useEffect(() => {
        // console.log(showPicker)
    }, [showPicker]);

    function handleShowPicker(e) {
        // console.log(e);
        // e.stopPropagation();
        // if (showPicker) return handleHidePicker();

        setShowPicker(true);
        setTimeout(() => {
            document.addEventListener("click", handleHidePicker);
        },50)

    }

    function handleUpdateColorFromPicker(pick, e) {
        // console.log(e)
        setError(false);
        // setColor(pick.hex);
        // onChange(pick.hex);
        if(mode=="hex"){
            setColor(pick.hex);
            onChange(pick.hex);
        }else{
            let c = `rgba(${pick.rgb.r},${pick.rgb.g},${pick.rgb.b},${pick.rgb.a})`
            setColor(c);
            onChange(c);
        }


    }

    function handleUpdateColor(e) {
        setColor(e.target.value);
        let errorcheck = validateColor(e.target.value, mode=="hex" ? false : true)
        if(!errorcheck){
            onChange(e.target.value);
            setError(false)
        }else{
            setError(errorcheck)
        }

    }

    return (
        <div className="input-group">
            <label>{title}</label>

            <div className="color-picker-wrapper">
                <div
                    className="swatch-preview"
                    id={title}
                    onClick={!showPicker ? handleShowPicker : undefined}
                    style={{ backgroundColor: color }}
                />

                <input
                    className="mono outline small"
                    value={color}
                    type="text"
                    maxLength={mode=="hex" ? "7" : "22"}
                    onChange={handleUpdateColor}
                />
            </div>

            {error && (
                <div className="error">{error}</div>
            )}

            {showPicker && (
                <div
                    ref={pickerRef}
                    className="color-picker"
                    id={title}
                >
                    <ChromePicker
                        color={color}
                        // onChangeComplete={handleUpdateColorFromPicker}
                        onChange={handleUpdateColorFromPicker}
                        // onChange={(c) => handleUpdateColorFromPicker(c.rgb)}
                        disableAlpha={disableAlpha}
                    />


                    {/*<ColorPicker color={color} onChange={color => setColor(color.hex)} />*/}
                </div>
            )}
        </div>
    );
}
