import React, { useState } from "react";

import classNames from "classnames";


export function TwitterTag({
    post=null,
    tag,
    tagRoll,
    passClick,
    passOnMouseEnter,
    passOnMouseLeave,
    nohide=false,
    count=null
    }) {

    const [ rollOver, setRollOver ] = useState(false);

    const onMouseEnter = (tag) => {
        setRollOver(true);
        console.log(tag)
        // passOnMouseEnter(tag);
    }
    const onMouseLeave = () => {
        setRollOver(false);
        passOnMouseLeave();
    }


    return (
        <div className={classNames("social-item-tag-container", rollOver && "roll")}
        // onMouseEnter={() => onMouseEnter(tag)}
        // onMouseLeave={onMouseLeave}>
        >
            <div
            onClick={(e) => passClick(e,tag)}
            // onMouseEnter={() => onMouseEnter(tag)}
            // onMouseLeave={onMouseLeave}
            className={classNames("social-item-tag",
                tag.charAt(0) == '$' && 'cash',
                tag.charAt(0) == '@' && 'user',
                tag.charAt(0) == '#' && 'hash',
                post && tag == post.retweet && !nohide && 'hide',
                tag == tagRoll && 'active')}
            >
                <p>{tag}</p>

                { count && (
                    <div className="count-circle dark" style={{ fontSize: count < 100 ? '10px' : '9px !important' }}>{count}</div>
                )}

                <p>{rollOver}</p>
            </div>

            { (tag.charAt(0) == '@' || tag.charAt(0) == '#') && (
                <div onClick={(e) => passClick(e,tag,"dex")}
                // style={{paddingLeft:5}}
                className={classNames("social-item-tag-options transition",  )}>
                    <div className="svg-icon">
                        <svg viewBox="0 0 1024 1024" style={{maxHeight:25}} version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M252 462h524c19.9 0 36-16.1 36-36s-16.1-36-36-36H339.4l59-59c14.1-14.1 14.1-36.9 0-50.9-14.1-14.1-36.9-14.1-50.9 0L230.3 397.2c-8.7 6.6-14.3 17-14.3 28.8 0 19.9 16.1 36 36 36zM802 564c-8.5-8.5-20.3-11.9-31.4-10H252c-19.9 0-36 16.1-36 36s16.1 36 36 36h437.1l-58.5 58.5c-14.1 14.1-14.1 36.9 0 50.9 7 7 16.2 10.5 25.5 10.5s18.4-3.5 25.5-10.5L802 615c14-14.1 14-36.9 0-51z"  />
                        </svg>
                    </div>
                    <div style={{width:10,height:10}} className="svg-icon">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4" >
                            <path d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
                                s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
                                 M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z"/>
                        </svg>
                    </div>
                </div>
            )}

        </div>
    )
}
