import React from "react";

export function StatusLight({ status }) {

    return (
        <div style={{ backgroundColor: status < .33 ? "red" : status < .66 ? "orange" : "green" }} className="status-circle">
            {/*<p>{status}</p>*/}
        </div>
    );
}
