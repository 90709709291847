import React from "react";
import { useMarket } from "../../hooks/useMarket";

import { MarketChartLineArea } from "../MarketChart/MarketChartLineArea";
import { CoinDataPriceChange } from "./CoinDataPriceChange";

import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";

var parse = require('html-react-parser');


export function CoinData() {

    let {
        coins,
        coinCurrent,
        handleCoinCurrent,
        coinsTrending,
        coinOrder,
        handleCoinOrder,
        coinCategory,
        handleCoinCategory,
        fetchCoinsMarkets
    } = useMarket();

    return (
        <div className="coin-data">


            {coinCurrent.image !== undefined && (
                <div className="data-row flex-row align center">
                    <div className="data-icon large round">
                        <img src={coinCurrent.image || coinCurrent.image.small}/>
                    </div>
                    <div style={{marginLeft: '7px'}} className="flex-column">
                        <h2>{coinCurrent.name}</h2>
                        <h3>{coinCurrent.symbol}</h3>
                    </div>
                    <>
                    </>



                </div>
            )}

            {coinCurrent.current_price !== undefined && (
                <div style={{paddingLeft:'20px',alignItems: 'flex-end',marginTop: '-40px'}} className="data-row flex-row justify space-between">
                    <div className="data-item">
                        <div className="data-title">USD</div>
                        <div style={{color: "#ddd"}} className="data-value large">{parse(condenseZeros(formatCurrency(coinCurrent.current_price, "USD")))}</div>
                    </div>
                    {coinCurrent.price_change_percentage_1h_in_currency !== undefined && (
                        <div style={{flex:.7}}>
                            <CoinDataPriceChange
                                values={[coinCurrent.price_change_percentage_1h_in_currency,
                                         coinCurrent.price_change_percentage_24h_in_currency,
                                         coinCurrent.price_change_percentage_7d_in_currency]}
                                labels={['1h','24h','7d']}
                            />
                        </div>

                    )}
                    <div className="data-column right">
                        { coinCurrent.market_cap > 0 && (
                            <div className="data-item data-small">
                                <div className="data-title">cap</div>
                                <div style={{color: "#ddd"}} className="data-value large"><span className="small">$</span>{parse(formatCurrencyScale(coinCurrent.market_cap))}</div>
                            </div>
                        )}
                        <div className="data-item data-small">
                            <div className="data-title">volume</div>
                            <div style={{color: "#ddd"}} className="data-value large"><span className="small">$</span>{parse(formatCurrencyScale(coinCurrent.total_volume))}</div>
                        </div>
                    </div>
                </div>
            )}
            {coinCurrent.description !== undefined && (
                <p>{coinCurrent.description}</p>
            )}






        </div>
    );
}
