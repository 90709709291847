import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

export function useSettingsParams() {
    let pname = useLocation().pathname;
    // console.log('in settings param!!!!!!!!!!!!!!******************',pname)
    if(pname.indexOf('/portfolio') > -1){
        return "portfolio" + pname.split("/portfolio")[1];
    }else{
        return "";
    }

}
