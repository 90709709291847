// a library to wrap and simplify api calls
import apisauce from "apisauce";

// our "constructor"
export const create = (baseURL = process.env.REACT_APP_HONEYPOT_URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //
    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // 'X-API-KEY': process.env.REACT_APP_OPENSEA_API_KEY,
        },
        // 10 second timeout...
        timeout: 10000,
    });


    const checkHoneypot = (data) => {
        return api.get(
            `${process.env.REACT_APP_HONEYPOT_URL}?chain=eth&token=${data}`
        );
    };


    return {
        checkHoneypot,
    };
};

// Creates a singleton
export const client_healthcheck = create();
