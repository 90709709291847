import React, { useState, useEffect } from "react";

export function InputSelect({
    title,
    value,
    field=null,
    options,
    optionsValues,
    onChange,
    onFocus=null,
    width,
    addClass,
    addClassInputGroup,
    addStyleInputGroup=null,
    noChangeValues=[],
    addIcon=null
}) {
    const [selected, setSelected] = useState(value || null);

    useEffect(() => {
        setSelected(value)
    }, [value] );

    useEffect(() => {
        setSelected(value)
    }, [] );

    function handleChange(e) {
        
        
        if(field){
            let v = {};
            v[field] = e.target.value;
            onChange(v);
        }else{
            onChange(e.target.value);
        }
        let preSelected = selected;
        setSelected(e.target.value);
       
        if(noChangeValues.includes(e.target.value)){
            setTimeout(() => {
                setSelected(preSelected);
            },500)
        }

    }

    return (
        <div style={addStyleInputGroup ? addStyleInputGroup : {}} className={`input-group${addClassInputGroup ? " " + addClassInputGroup : ""}`}>
            {title && <label>{title}</label>}
            <div className="select">
                
                { !addIcon ? (
                    <div style={{fontSize:14}} className={addIcon}/>
                ):null}

                <select
                    // disabled={this.props.adevices.length < 2}
                    onChange={handleChange}
                    className={`outline${addClass ? " " + addClass : ""}`}
                    value={selected}
                    onFocus={onFocus || null}
                >
                    {options.map((o, i) => (
                        <option
                            value={options[i].value || optionsValues[i]}
                            key={i}
                        >
                            {options[i].title || o}
                        </option>
                    ))}
                </select>
            </div>
            
            
        </div>
    );
}
