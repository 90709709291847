import React, { useState, useRef, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { client } from "../../services/api";

import { useWallet } from "../../hooks/useWallet";

import { ReactComponent as CameraPlus } from '../../icons/svgs/camera_plus.svg';

import { dataURLToBlob } from '../../utils/mediaTools';
import toast from 'react-simple-toasts';

var parse = require('html-react-parser');

const ProfilePhotoUpload = () => {

    const { user, handleUser, handleUpdateUser, walletAddress, profile, handleProfile, handleUpdateProfile } = useWallet();
    
    const [uploading, setUploading] = useState(true);
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [fileInputActive, setFileInputActive] = useState(false);

    const [ avatarUrl, setAvatarUrl] = useState(null);



    const editorRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {

       
        toggleAvatarUrl();

    }, [ ] );

    const toggleAvatarUrl = () => {
        let au = profile.avatarFile !== undefined && profile.avatarFile ? `${process.env.REACT_APP_MEDIA_URL}${profile.avatarFile}?t=${Math.floor(new Date().getTime() / (1000 * 60 * 60))}` : require('../../images/default_profile_avatar.jpg');
        setAvatarUrl(au);
    }

    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
            
        }
    };

    const handleImageChange = (e) => {

        const files = e.target.files;
        if (files.length > 0) {
            console.log("File selected: ", files[0]);
            setImage(files[0]);
            setFileInputActive(true);
        } else {
            setImage(null);
            setFileInputActive(false);
        }
        
        
    };

    const handleScaleChange = (e) => {
        setScale(parseFloat(e.target.value));
    };

    const handlePositionChange = (e) => {

    };


    const cancelFile = (e) => {
        setImage(null);
        setFileInputActive(false);
    };

    const handleSave = async () => {
        if (editorRef.current) {
            // This gives you the cropped image data
            const canvas = editorRef.current.getImageScaledToCanvas();
            const dataURL = canvas.toDataURL();

            const blob = dataURLToBlob(dataURL);

            let fn = `profile_${user.slug}.jpg`;

            const formData = new FormData();
            formData.append('file', blob, fn); // Appending a filename

            const uploadImage = await client.uploadImageToCloud(formData);

            console.log(uploadImage)

            
 
            if (uploadImage.ok && uploadImage.data?.success) {
                // console.log('File uploaded successfully:', result.publicUrl);
                
                await handleUpdateProfile({
                    _id: profile._id,
                    avatarFile: fn
                    
                });


                setAvatarUrl(`${process.env.REACT_APP_MEDIA_URL}${fn}?t=${Math.floor(new Date().getTime() / (1000 * 60 * 60))}`);

                setImage(null);
                setFileInputActive(false);

                

                toast(parse('New <span className="mute">profile photo</span> saved'));

            } else {
                // console.error('Upload failed:', result.message);
            }

        }
    };

 
    return (
        <div>

            { !fileInputActive ? (
                <div className="profile-photo">
                    <img src={avatarUrl}/>
                    <div onClick={triggerFileInput} className="camera-button">
                        <div className="svg-icon no-stroke">
                            <CameraPlus/>
                        </div>
                        
                    </div>
                </div>
            ):null}
            

            <div style={{display:"none"}} className="file-upload">
                <input
                    type="file"
                    id="icon-upload"
                    name="embedButtonIconUri"
                    className="file-upload-hidden"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                />

                {/* <label
                    className="button small"
                    htmlFor="icon-upload"
                >
                    Upload Image

                   
                </label> */}
            </div>
            
            {image && (
                <>
                    <div style={{maxWidth:208, alignItems:"center"}} className="flex-column">
                        <AvatarEditor
                            ref={editorRef}
                            image={image}
                            width={180}
                            height={180}
                            borderRadius={90}
                            border={44}
                            scale={scale}
                            rotate={0}
                            onPositionChange={handlePositionChange}
                        />
                    
                        <input
                            type="range"
                            min="1"
                            max="2"
                            step="0.01"
                            value={scale}
                            style={{marginTop:11,maxWidth:88}}
                            onChange={handleScaleChange}
                        />
                    
                        
                    </div>
                    <div className="flex-row">
                        <button onClick={handleSave}>Apply</button>
                        <button style={{marginLeft:11}} className="neutral" onClick={cancelFile}>Cancel</button>
                    </div>
                    
                
                </>
                
            )}
        </div>
    );
};

export default ProfilePhotoUpload;
