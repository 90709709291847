import React, { useState, useEffect, useCallback, useRef} from "react";

import { TwitterPost } from "./TwitterPost";
import { IconActionButton } from "../IconActionButton";

import { TokenDataTabs } from "../Dex/TokenDataTabs";
import { TwitterTag } from "./TwitterTag";

import classNames from "classnames";

import { getTimeDisplay } from "../../utils/tools";
import { useSocial} from "../../hooks/useSocial";

import { useNavigator } from "../../hooks/useNavigator";

export function TwitterUserPage({ twu, passTagClick }) {

    const navigator = useNavigator();

    const [ scrollTop, setScrollTop ] = useState(0);
    const [ optionsMenu, setOptionsMenu ] = useState(false);
    const [ panelHeight, setPanelHeight ] = useState(0);

    const [ showPostCount, setShowPostCount ] = useState(25);

    const ref = useRef(null);



    const handleClick = () => {
        // passClick(post);
    }




    // const onRollOver = (t) => {
    //     handleTagRoll(t);
    // }
    // const onRollOut = (t) => {
    //     handleTagRoll(null);
    // }


    // const onScroll = (e) => {
    //
    // }



    let orderedPosts = twu.posts == undefined ? [] : twu.posts.filter(p => p.tags)
    .sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
    });

    const toggleOptionsMenu = () => {
        setOptionsMenu(!optionsMenu);
    }

    const twuTags = {
        cash: [...twu.tags.cash],
        hash: [...twu.tags.hash]
    }

    return (

        <div
        ref={ref}
        //onClick={() => switchTwitterUser(twu)}
        //className={classNames('social-item', twid == twu.twid && 'active')}
        className="social-user-page"
        >

            <div style={{maxWidth:'50%'}} className="flex-column">
                <div style={{minWidth:244,marginBottom:11}} className="flex-row justify space-between">

                    <div className="flex-row">
                        <div className="social-user-image">

                            <img
                                className="profile-image"
                                src={twu.profile_image_url}
                                // style={{ filter: chain.test!==undefined && chain.length ? 'saturation(.3);' : ''}}
                                />
                        </div>
                        <div className="data-column">
                            <p style={{marginLeft:11}}><span class="mute">@</span>{twu.username}</p>
                            <h6 style={{marginLeft:11}}>{twu.name}</h6>
                        </div>
                    </div>

                    <div className="flex-row">
                        <div style={{marginLeft: 10}}>
                            <IconActionButton addClass="relative transparentbg" type={ !optionsMenu ? "ellipsis-vertical" : "ellipsis-horizontal" }  passClick={toggleOptionsMenu}/>
                        </div>
                    </div>

                    {/*<p className="time">{getTimeDisplay(twu.updated)}</p>*/}

                </div>

                <TokenDataTabs
                    addStyle={{padding:0}}
                    title="price"
                    values={[twu.public_metrics.followers_count,
                        twu.public_metrics.following_count,
                        twu.public_metrics.listed_count,
                    ]}
                    valuesFormat={['numbermin','numbermin','number']}
                    labels={['followers','following','lists']}
                />

                <p>{twu.description}</p>

                <div style={{marginTop:44}} className="flex-row">
                    { Object.keys(twuTags).map((key, i) => (
                        <>
                            {twu.tags[key].map((t, k) => {

                                // let keyt = `${Math.floor(Math.random()*50000)}${k}${i}`

                                let keyt=k;
                                // console.log(keyt)
                                return (

                                    <TwitterTag
                                        key={`${keyt}`}
                                        // post={post}
                                        tag={t}
                                        // tagRoll={tagRoll}
                                        passClick={passTagClick}
                                        // passOnMouseEnter={onRollOver}
                                        // passOnMouseLeave={onRollOut}
                                    />
                                )

                            }




                            )}

                        </>

                    ))}
                </div>

            </div>


            <div className="flex-column">

                <div className="social-item-posts">
                    { orderedPosts.map((p, i) => {
                        if(i<showPostCount){
                            return (
                                <TwitterPost key={`${i}`} post={p} passTagClick={passTagClick}/>
                            )
                        }

                    })}
                </div>

            </div>




            {/*{ twid == twu.twid && (
                <div className="active-green-circle"/>
            )}*/}
        </div>
    )
}
