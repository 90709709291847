// a library to wrap and simplify api calls
import apisauce from "apisauce";

// our "constructor"
export const create = (baseURL = process.env.REACT_APP_TWITTER_API_URL) => {
    // ------
    // STEP 1
    // ------
    //
    // Create and configure an apisauce-based api object.
    //

    const api = apisauce.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            "Cache-Control": "no-cache",
            "Authorization": `Bearer ${JSON.stringify(process.env.REACT_APP_TWITTER_API_BEARER_TOKEN)}`,
            "mode": 'no-cors',
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "X-Requested-With",
        },
        // 10 second timeout...
        timeout: 10000,
    });



    const searchTweetsAll = () => {
        return api.get(
            `${process.env.REACT_APP_TWITTER_API_URL}/tweets/search/all?query=$mri`
        );
    };


    // const searchTweetsRecent = () => {
    //     return api.get(
    //         `${process.env.REACT_APP_TWITTER_API_URL}/tweets/search/recent?query=$mri`
    //     );
    // };



    // const getMarketChart = (id) => {
    //     let data = {
    //         vs_currency:'usd',
    //         days:'30',
    //         interval:'hourly'
    //     }
    //     return api.get(
    //         `${process.env.REACT_APP_COINGECKO_API_URL}/coins/${id}/market_chart`,
    //         data
    //     );
    // };


    // return functions for interface
    return {

        searchTweetsAll,
        // searchTweetsRecent,
    };
};

// Creates a singleton
export const client_twitter = create();






///////
