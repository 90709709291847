import { WebsiteRouter, AppRouter } from "../routers";


export const APPS = [
	{
		subdomain: "www",
		app: WebsiteRouter,
		main: true
	},
	{
		subdomain: "app",
		app: AppRouter,
		main: false
	},
	// {
	// 	subdomain: "admin",
	// 	app: AdminRouter,
	// 	main: false
	// },
]
