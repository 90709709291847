import React, { useEffect, useState, useCallback } from "react";

import { useWallet } from "../../hooks/useWallet";
import { useDex } from "../../hooks/useDex";
import { useNavigator } from "../../hooks/useNavigator";

import { Helmet } from "react-helmet-async";

import { Layout } from "../../components/Layout";
import { PortfolioToken } from "../../components/Dex/PortfolioToken";

import classNames from "classnames";



import { formatCurrency, condenseZeros, formatPercent, formatCurrencyScale } from "../../utils/currency";


var parse = require('html-react-parser');

export function WatchLists() {

    let {
        walletAddress,
        fetchTokenBalances,
        chainTotalBalances,
        user,
        ethPrice,
        balancesAlchemy
    } = useWallet();

    let {
        fetchPortfolioTokens,
        watchLists,
        tokenPairs,
        tokenLists,
        listTokens,
        fetchListTokens
    } = useDex();

    const navigator = useNavigator();

    let { contractAddress, handleContractAddress, screens } = useNavigator();

    const [ listType, setListType ] = useState(null);
    const [ listSlug, setListSlug ] = useState('lets go!');
    const [ list, setList ] = useState(null);

    useEffect(() => {

        console.log(
            'screens !!!!!!!!!!>>>>>>>>>>>>>>>>>'
        )
        if(screens.length > 3){
            if(screens[2]=="list" || screens[2]=="recipe"){
                setListType(screens[2]);
                for(var i=0;i<tokenLists.length;i++){
                    if(tokenLists[i].slug == screens[3]){
                        setList(tokenLists[i]);
                        setListSlug(screens[3]);
                    }
                }

            }
        }

    }, [ tokenLists ]);

    useEffect( () => {

        console.log('new slug lets go');
        fetchListTokens(listSlug);

    }, [ listSlug ]);


    useEffect( () => {


    }, [ walletAddress ]);

    useEffect(() => {

        if(listTokens && listTokens.length){
            const interval = setInterval(getCurrentListDex, 60000);
            return () => clearInterval(interval);
        }

    }, [ listTokens ]);

    const getCurrentListDex = useCallback( async () => {
        fetchListTokens(listSlug);
    }, [listSlug]);


    const tokensFiltered = listTokens ? listTokens.filter(t => {
		return t.data !== undefined
	}) : {};


    return (
        <Layout>
            <Helmet>
                <title>
                {navigator.screen}
                    {/*{navigator.screen.indexOf("portfolio/assets") > -1 ? navigator.screen : '0xDex :: Watchlist'}*/}
                </title>
            </Helmet>
            <div className="data-panel portfolio-container">
                <div className="portfolio-header">
                    { list ? (
                        <h2>Watchlist : {list.name}</h2>
                    ):(
                        <h2>--</h2>
                    )}

                </div>
                <div className="portfolio-tokens">

                    { listTokens && (
                        <>

                            { listTokens.map((t,i) => (
                                <PortfolioToken token={t}/>
                            ))}
                        </>
                    )}

                </div>

            </div>


            { screens.map((s, i) => (
                <p>{s}-{i}</p>
            ))}



        </Layout>
    );
}
